import { reloadTable } from "app/pageSlice"
import dayjs from "dayjs"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { apiPutVerification } from "#api/Api_Colaborador"
import { ButtonDialog, DialogGeneral } from "#common/Dialog"

//mui
import { Typography } from "@mui/material"
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"

export default function DialogPersonalApproval(props) {
	const dispatch = useDispatch()
	const token = useSelector((state) => state.user.token)
	const [approval, setApproval] = useState(false)
	const [message, setMessage] = useState("")
	const fecha_alta = props?.colaborador.fecha_alta
	const [fecha, setFecha] = useState(
		fecha_alta ? dayjs(fecha_alta, "D-M-YYYY") : null
	)
	const { setOpen, reloadNow } = props
	const colaborador = props?.colaborador?.nombre_colaborador
	const id = props?.colaborador?.id
	const actualizar = () => {
		reloadNow()
		dispatch(reloadTable())
	}

	async function send() {
		const data = {
			id,
			verificado: approval ? true : null,
		}
		if (approval) data["fecha_alta"] = fecha?.format("YYYY-MM-DD")
		else data["message"] = message
		const { success } = await apiPutVerification(token, data)
		if (success) {
			actualizar()
			setOpen(false)
		}
	}

	const buttonsOption = [
		<ButtonDialog
			key={"cancelar"}
			label="Cancelar"
			onClick={() => setOpen(false)}
		/>,
		<ButtonDialog
			key={"enviar"}
			label="Enviar"
			onClick={() => send()}
		/>,
	]

	const handleChange = (newValue) => {
		setFecha(newValue)
	}
	let contenido = null

	if (approval)
		contenido = (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DesktopDatePicker
					label="Fecha de Ingreso"
					inputFormat="MM/DD/YYYY"
					value={fecha}
					onChange={handleChange}
					renderInput={(params) => <TextField {...params} />}
				/>
			</LocalizationProvider>
		)
	else
		contenido = (
			<TextField
				id="rechazo"
				label="Motivo del rechazo"
				variant="outlined"
				fullWidth
				value={message}
				onChange={(event) => {
					setMessage(event.target.value)
				}}
			/>
		)

	return (
		<DialogGeneral
			{...props}
			title={
				<Typography variant="h5">
					Aprobar Documentación
					<br />
					{colaborador}
				</Typography>
			}
			buttons={buttonsOption}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<FormControlLabel
					control={
						<Switch
							value={approval}
							onChange={(event) => {
								setApproval(event.target.checked)
							}}
						/>
					}
					label={approval ? "Aprobar" : "Rechazar"}
				/>
				{contenido}
				{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
					<DesktopDatePicker
						label="Fecha de antigüedad"
						inputFormat="MM/DD/YYYY"
						value={fecha}
						onChange={handleChange}
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider> */}
			</div>
		</DialogGeneral>
	)
}
