import { createSlice } from '@reduxjs/toolkit'

function getData() {
    const data = localStorage.getItem('userData');
    if (data == null)
      return { nombre: "", token: null, profiles: []} 
    return JSON.parse(data)
}


export const userSlice = createSlice({
    name: "user",
    initialState: getData() ,
    reducers:{
        login: (state, action)=>{
            let session = action.payload
            console.log(session)
            state.nombre= session.colaborador.nombre + ' ' + session.colaborador.apellido_paterno
            state.token= session.token
            state.profiles=  session.profiles
            state.colaborador = session.colaborador
            localStorage.setItem('userData', JSON.stringify(state));
            localStorage.setItem('version', process.env.REACT_APP_VERSION);
            localStorage.setItem('token', session.token);
            localStorage.setItem('moneda', session.colaborador.moneda);
            const currentDate = new Date();
            localStorage.setItem('fechaLogin', currentDate.toString());
        },
        changeProfiles: (state, action)=>{
            let data = action.payload
            state.profiles = data
        },
        logout:(state) => {
            state = {
                nombre:"",
                token: null,
                profiles: []
            }
            localStorage.removeItem('userData')
            localStorage.removeItem('fechaLogin')
            localStorage.removeItem('token')
        }
    }
})


export const { login, logout} = userSlice.actions

export default userSlice.reducer