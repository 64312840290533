import { useEffect, useState } from "react"
//Material
import Autocomplete from "@mui/material/Autocomplete"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"

export default function SelectColaborador({
	colaboradores,
	colaborador_select = null,
	onChang,
}) {
	const [type, setType] = useState({})
	const [colaborador, setColaborador] = useState(
		colaborador_select ? colaboradores[0] : null
	)
	const options_select = [
		{ label: "Incremento" },
		{ label: "Decremento" },
		{ label: "Cancelación" },
	]

	let movimiento = null

	useEffect(() => {
		if (colaborador?.fondo_id) setType("")
		else setType("Nuevo")
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colaborador?.fondo_id])

	useEffect(() => {
		console.log(colaborador)
		onChang({ ...colaborador, type })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colaborador, type])

	if (colaborador?.fondo_id)
		movimiento = (
			<FormControl fullWidth>
				<InputLabel id="movimiento">Movimiento</InputLabel>
				<Select
					id="movimiento"
					label={"movimiento"}
					onChange={(e) => setType(e.target.value)}
					disabled={!colaborador?.fondo_id}
				>
					{options_select.map((option, index) => (
						<MenuItem
							key={option.label}
							value={option.label}
						>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		)

	return (
		<Stack spacing={2}>
			<Autocomplete
				value={colaborador}
				options={colaboradores}
				disabled={colaborador_select}
				fullWidth
				getOptionLabel={(option) => option.nombre_completo}
				onChange={(e, v) => {
					setColaborador(v)
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Colaborador"
					/>
				)}
			/>
			{movimiento}
		</Stack>
	)
}
