import React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { ESTADO_CURSOS } from "../../constants_capacitaciones"
import { deleteApiCursos, putApiCursos } from "../../../../Services/Api_cursos"
import { useSelector } from "react-redux" //Token
import { InputGrid2 } from "../../../Common/Inputs2"

export default Confirmation
function Confirmation({
	newStatus,
	idCursoColaborador,
	actualizar,
	setActualizar = () => {},
	confirm = () => {},
	setConfirm = () => {},
}) {
	const token = useSelector((state) => state.user.token)
	const user = useSelector((state) => state.user)
	const [message, setMessage] = React.useState("")
	let mensaje = {
		[ESTADO_CURSOS["APROBADO"]]: "¿Estas seguro de aprobar este curso?",
		[ESTADO_CURSOS["CANCELADO"]]: "¿Estas seguro de cancelar este curso?",
		[ESTADO_CURSOS["RECHAZADO"]]: "¿Estas seguro de rechazar este curso?",
		"-1": "¿Estas seguro de Eliminar este curso?",
	}

	const handleClose = () => {
		setConfirm(false)
	}
	async function aceptar() {
		let response
		setConfirm(false)
		if (newStatus > 0)
			response = await putApiCursos(
				"colaborador",
				token,
				{
					status: newStatus,
					colaborador_validacion: user.colaborador.id,
					mensaje: message,
				},
				{
					id: idCursoColaborador,
				}
			)
		else
			response = await deleteApiCursos("colaborador", token, {
				id: idCursoColaborador,
			})
		if (response["success"]) {
			setActualizar(!actualizar)
		}
	}

	return (
		<React.Fragment>
			<Dialog
				open={confirm}
				onClose={handleClose}
				aria-labelledby="Confirmation-status-course"
				aria-describedby="Confirmación de aceptación de una opción  "
			>
				<DialogTitle>{`Confirmación`}</DialogTitle>
				<DialogContent>
					{newStatus !== ESTADO_CURSOS["RECHAZADO"] ? (
						<DialogContentText>{`${mensaje[newStatus]}`}</DialogContentText>
					) : (
						<Grid
							container
							paddingTop={0}
							spacing={1}
							justifyItems={"center"}
						>
							<Grid xs={8}>
								<DialogContentText>{`${mensaje[newStatus]}`}</DialogContentText>
							</Grid>
							<InputGrid2
								propsGrid={{ xs: 12 }}
								id="rechazoCurso"
								label="Motivo del rechazo"
								setOption={setMessage}
							/>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancelar</Button>
					<Button
						onClick={aceptar}
						autoFocus
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}
