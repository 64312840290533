import React from "react"
import { DialogGeneral } from "../Common/Dialog"
import { useFondoContext } from "./useContextFondo"

export default function FondoDialog() {
	const { openDialog, setOpenDialog } = useFondoContext()
	const { contenido } = openDialog
	console.log(contenido)

	return (
		<DialogGeneral
			{...openDialog}
			// title={openDialog?.title}
			// buttons={openDialog?.buttons}
			// open={openDialog?.open}
			setOpen={setOpenDialog}
		>
			{contenido}
		</DialogGeneral>
	)
}
