import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

import {
	OPTIONS_ESCOLARIDAD,
	OPTIONS_ESCOLARIDAD_GT,
} from "../../../assets/OptionsSelects"
import FormBase from "../../Common/Forms"
import { DialogForm } from "./../../Common/Dialog"
import {
	apiPostColaboradorEscolares,
	apiPutColaboradorEscolares,
} from "../../../Services/Api_Colaborador"
import { validateDateRange } from "../../Common/Funciones"
import IsGuatemala from "./IsGuatemala"

export default function FormDataEscolares({
	idColaborador,
	open,
	setOpen,
	edit,
	actualizar,
}) {
	const { have } = IsGuatemala()
	const token = useSelector((state) => state.user.token)
	const form = useForm({
		defaultValues: {},
	})
	const { handleSubmit, reset } = form
	const [button, setButton] = useState(false)
	const DATA = [
		{
			field: "input",
			id: "institucion",
			label: "Institución",
			registerParams: { required: true },
		},
		{
			field: "select",
			id: "grado",
			label: "Grado",
			dataID: "value",
			data: have ? OPTIONS_ESCOLARIDAD_GT : OPTIONS_ESCOLARIDAD,

			registerParams: { required: true },
		},
		{
			field: "date",
			id: "inicio",
			label: "Inicio",
			views: ["year"],
			parseFormat: "YYYY",
			propsG: { xs: 6 },
			registerParams: { required: true },
		},
		{
			field: "date",
			id: "fin",
			label: "Fin",
			views: ["year"],
			parseFormat: "YYYY",
			propsG: { xs: 6 },
			registerParams: { required: true },
		},
		{
			field: "file",
			id: "documento",
			label: "Certificado",
			propsG: { xs: 4 },
			// registerParams: { required: true },
		},
	]
	useEffect(() => {
		if (edit) {
			reset(edit)
		} else {
			reset({
				grado: "",
				inicio: null,
				fin: null,
				institucion: "",
				documento: null,
				colaborador: idColaborador,
			})
		}
	}, [edit, reset, open, idColaborador])

	const submit = async (data) => {
		setButton(true)

		if (!validateDateRange([data.inicio, data.fin]))
			toast.error("La Fecha de Inicio no puede ser mayor a la Final")
		else {
			const formData = new FormData()

			if (typeof data.inicio === "object") {
				data.inicio = data.inicio["$y"]
			}

			if (typeof data.fin === "object") {
				data.fin = data.fin["$y"]
			}

			formData.append("colaborador", idColaborador)
			for (const index in data) {
				const values = data[index]
				if (typeof data[index]?.documento === "string") {
					data[index].documento = null
				}
				if (!!values) formData.append(index, values)
			}
			let response
			if (edit) {
				response = await apiPutColaboradorEscolares(token, formData)
			} else
				response = await apiPostColaboradorEscolares(token, formData, {}, {})

			if (response.success) {
				actualizar()
				setOpen(false)
			}
		}
		setButton(false)
	}
	return (
		<DialogForm
			title={edit ? "Editar grado escolar" : "Nuevo grado escolar"}
			open={open}
			setOpen={setOpen}
			accept={handleSubmit(submit)}
			disabledButtons={button}
		>
			<FormBase
				fields={DATA}
				useForm={form}
			/>
		</DialogForm>
	)
}
