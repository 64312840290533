import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { getApiCursos } from "../../Services/Api_cursos"
import { Button } from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { styled } from "@mui/material/styles"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { apiPutAsset } from "../../Services/Api_inventario"
import { useSolicitudesContext } from "../Colaboradores/Solicitudes/SolicitudesContext"
import { ViewPDF } from "../Common/Dialog"
import VisibilityIcon from "@mui/icons-material/Visibility"

export default function AssetTableDetail({ data, columns, token, permission }) {
	const [open, setOpen] = useState(false)
	const { actualizarA } = useSolicitudesContext()
	const handleDownload = async (link) => {
		let response = await getApiCursos("link", token, () => {}, {
			url: link,
		})
		window.open(response.data, "_blank")
	}
	const handleFileUpload = async (event, nombre) => {
		const file = event.target.files[0]
		var formData = new FormData()
		if (nombre === "Políticas") formData.append("url_policy", file)
		if (nombre === "Carta asignación") formData.append("url_responsive", file)
		formData.append("id", data[0]["id"])
		let response = await apiPutAsset(
			"ASSET_COLABORADOR",
			token,
			formData,
			{},
			{ pending: "Subiendo carta responsiva" }
		)
		if (response["success"]) {
			actualizarA()
		}
	}
	const exitFile = (nombre, link) => {
		if (!!link) {
			return (
				<Button
					startIcon={<VisibilityIcon />}
					variant="contained"
					onClick={
						() => setOpen({ open: true, url: link, name: nombre })
						//handleDownload(link)
					}
				>
					{nombre}
				</Button>
			)
		} else {
			if (permission)
				return (
					<Button
						onChange={(event) => handleFileUpload(event, nombre)}
						component="label"
						variant="contained"
						startIcon={<CloudUploadIcon />}
					>
						Subir {nombre}
						<VisuallyHiddenInput
							type="file"
							accept=".pdf"
						/>
					</Button>
				)
			return "Archivo no disponible"
		}
	}

	return (
		<React.Fragment>
			<TableContainer>
				<Table
					sx={{ width: "100%", backgroundColor: "#C5C2C29C" }}
					size="small"
					aria-label="a dense table"
				>
					<TableHead>
						<TableRow key={""}>
							{columns.map((row) => (
								<TableCell
									component="th"
									scope="row"
									{...row.options}
								>
									{row.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							{columns.map((row) => {
								if (row.name === "fresh_service_id")
									return (
										<TableCell
											align="left"
											width={"auto"}
										>
											<Link
												target="_blank"
												href={`https://lubtrac.freshservice.com/cmdb/items/${
													data[0][row.name]
												}`}
											>
												<Typography
													variant="h7"
													sx={{
														textAlign: "center",
														justifyContent: "center",
														//paddingLeft: 5,
														color: "black",
														fontWeight: "bold",
													}}
												>
													#{data[0][row.name]}
												</Typography>
											</Link>
										</TableCell>
									)
								if (row.name === "url_responsive")
									return (
										<TableCell align="right">
											{exitFile("Carta asignación", data[0]["url_responsive"])}
										</TableCell>
									)
								if (row.name === "url_policy")
									return (
										<TableCell align="right">
											{exitFile("Políticas", data[0]["url_policy"])}
										</TableCell>
									)
								return <TableCell align="left">{data[0][row.name]}</TableCell>
							})}
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<ViewPDF
				iewPDF
				title={open.name}
				open={open.open}
				setOpen={setOpen}
				pdfUrl={open.url}
			/>
		</React.Fragment>
	)
}
const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
})
