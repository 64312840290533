import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { PRIMARYCOLOR } from "../../../Constants/Styles"
//API
import {
	apiGetDependent,
	apiGetPerfilPuesto,
	apiPostPromotion,
} from "../../../Services/Api_promotion"
//componentes
import { InputAutocompleteAsync } from "../../Common/Inputs2"
//material ui
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	FormControlLabel,
	FormGroup,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material"
import { toast } from "react-toastify"

function DataColaboradores(params) {
	const token = useSelector((state) => state.user.token)
	const [colaboradores, setColaboradores] = useState([])
	const [load, setLoad] = useState(false)

	useEffect(() => {
		const controller = new AbortController()
		setLoad(true)
		setColaboradores([])
		apiGetDependent({
			token,
			params,
			controller,
			setDB: setColaboradores,
		}).then((response) => {
			if (!!response?.data) setLoad(false)
		})
		return () => {
			controller.abort()
		}
	}, [params, token])
	return { colaboradores, load }
}

function DataPerfiles({ params }) {
	const token = useSelector((state) => state.user.token)
	const [perfiles, setPerfiles] = useState([])
	const [loadPerfiles, setLoadPerfiles] = useState(false)

	useEffect(() => {
		const controller = new AbortController()
		setLoadPerfiles(true)
		apiGetPerfilPuesto({ token, setDB: setPerfiles, params, controller }).then(
			(response) => {
				setLoadPerfiles(false)
			}
		)

		return () => {
			controller.abort()
		}
	}, [params, token])
	return { perfiles, loadPerfiles }
}

/**
 * Description placeholder
 *
 * @param {Object} param0
 * @param {int} param0.colaboradorId id del colaborador que se va a solicitar
 * @param {int} param0.reporta_a id del perfil de puesto que se va a solicitar
 * @param {function} param0.setOpen función para actualizar el estado de apertura del dialogo
 * @param {boolean} param0.open booleano para indicar si el dialogo se va a abrir o cerrar
 * @returns {JSX.Element} Elemento JSX que representa el componente ModalMovimientos
 */
const ModalMovimientos = ({ colaboradorId, reporta_a, setOpen, open }) => {
	const token = useSelector((state) => state.user.token)
	const [disabledButtons, setDisabledButtons] = useState(false)

	const [params, setParams] = useState({
		jefe_directo: colaboradorId,

		fields: ["puesto"],
	})
	const paramsPerfil = useMemo(() => ({ reporta_a }), [reporta_a])
	// llamado a la api para obtener los perfiles de puesto y colaboradores
	const { colaboradores, load } = DataColaboradores(params)
	const { perfiles, loadPerfiles } = DataPerfiles({ params: paramsPerfil })

	const [listMovimientos, setListMovimientos] = useState([])
	const [detail, setDetail] = useState([])
	const [colaboradoresEdit, setColaboradoresEdit] = useState([])
	const [select, setSelect] = useState({
		colaborador: null,
		perfilPuesto: null,
	})

	useEffect(() => {
		let noSelect = colaboradores.filter((colaborador) => {
			let isNotSelect = true
			listMovimientos.forEach((element) => {
				if (element.colaborador.id === colaborador.id) isNotSelect = false
			})
			return isNotSelect
		})
		setColaboradoresEdit(noSelect)
	}, [colaboradores, listMovimientos])

	let tableData = [
		{ label: "", id: "foto" },
		{ label: "No. Empleado", id: "no_empleado" },
		{ label: "Nombre", id: "nombre_completo" },
		{ label: "Departamento", id: "departamento" },
		{ label: "Puesto", id: "puesto" },
		{ label: "Nuevo Puesto", id: "nombre" },
	]
	const createMovement = () => {
		if (select.colaborador && select.perfilPuesto) {
			if (select.colaborador?.puesto === select.perfilPuesto?.nombre) {
				setSelect({ ...select, perfilPuesto: null })
				toast.error("El perfil de puesto ya lo tiene el colaborador")
			} else {
				setListMovimientos([...listMovimientos, { ...select }])
				setDetail([
					...detail,
					{
						colaborador_id: select.colaborador.id,
						perfil_puesto_id: select.perfilPuesto.id,
					},
				])
				setSelect({ colaborador: null, perfilPuesto: null })
			}
		} else toast.error("Selecciona un colaborador y un perfil")
	}

	const sendMovement = async () => {
		setDisabledButtons(true)
		let { success } = await apiPostPromotion(token, {
			colaborador_id: colaboradorId,
			detail,
		})
		if (success) {
			setOpen(false)
			setDetail([])
			setSelect({ colaborador: null, perfilPuesto: null })
			setListMovimientos([])
		}
		setDisabledButtons(false)
	}

	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false)
				setSelect({ colaborador: null, perfilPuesto: null })
				setListMovimientos([])
				setParams({
					jefe_directo: colaboradorId,
					fields: ["puesto"],
				})
			}}
			maxWidth={"lg"}
			sx={{ minHeight: 100 }}
		>
			<DialogContent sx={{ minHeight: 250 }}>
				<Grid
					container
					// alignItems="end"
					justifyContent={"center"}
					alignContent={"center"}
					spacing={3}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h6"
							component="div"
							sx={{ flexGrow: 1, textAlign: "center" }}
						>
							Solicitud de Movimientos de Personal
						</Typography>
					</Grid>
					<Grid
						item
						xs={4}
					>
						<InputAutocompleteAsync
							label={"Colaboradores"}
							options={colaboradoresEdit}
							optionsLabel="nombre_completo"
							loading={load}
							value={select.colaborador}
							setValue={(e) => setSelect({ ...select, colaborador: e })}
						/>
						<FormGroup>
							<FormControlLabel
								control={<Checkbox />}
								label="Todos Colaboradores"
								onChange={(event) => {
									if (event.target.checked) setParams({ fields: ["puesto"] })
									else
										setParams({
											jefe_directo: colaboradorId,
											fields: ["puesto"],
										})
								}}
							/>
						</FormGroup>
					</Grid>
					<Grid
						item
						xs={4}
					>
						<InputAutocompleteAsync
							label="Nuevo Perfil De Puesto"
							options={perfiles}
							optionsLabel="nombre"
							loading={loadPerfiles}
							value={select.perfilPuesto}
							setValue={(e) => setSelect({ ...select, perfilPuesto: e })}
						/>
					</Grid>
					<Grid
						xs={4}
						item
					>
						<Button
							disabled={disabledButtons}
							variant="contained"
							size="large"
							onClick={createMovement}
						>
							Crear movimiento
						</Button>
					</Grid>
					<TableContainer component={Paper}>
						<Table
							sx={{ minWidth: 650 }}
							aria-label="simple table"
						>
							<TableHead>
								<TableRow>
									{tableData.map((campo) => (
										<TableCell key={campo.id}>{campo.label}</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{listMovimientos.map((data) => {
									const { colaborador, perfilPuesto } = data

									return (
										<Movimiento
											tableData={tableData}
											key={colaborador.no_empleado}
											colaborador={colaborador}
											perfilPuesto={perfilPuesto}
										/>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<Grid
						item
						xs={12}
						sx={{ textAlign: "right" }}
					>
						<Button
							disabled={disabledButtons}
							variant="contained"
							size="large"
							onClick={() => setOpen(false)}
						>
							Cancelar
						</Button>
						<Button
							disabled={disabledButtons}
							variant="contained"
							size="large"
							sx={{ ml: 1 }}
							onClick={sendMovement}
						>
							Enviar Solicitud
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ModalMovimientos

const Movimiento = ({ colaborador, perfilPuesto, tableData, key }) => {
	const { id } = colaborador
	const params = useRef({
		id,
		condition: "fields_strict",
		fields: ["photo", "departamento"],
	})

	const { colaboradores } = DataColaboradores(params.current)

	return (
		<TableRow key={key}>
			{tableData.map((campo) => {
				if (campo.id === "foto")
					return (
						<TableCell
							component="th"
							scope="row"
						>
							<Box
								component="img"
								sx={{ height: 75 }}
								alt="No se cuenta con fotografía"
								src={colaboradores[0]?.photo}
								onError={(e) => {
									e.target.onerror = null
									e.target.src =
										"https://imagenespublicas.s3.us-east-2.amazonaws.com/usuario.webp"
								}}
							/>
						</TableCell>
					)
				if (campo.id === "nombre")
					return (
						<TableCell
							sx={{ fontSize: 16, color: PRIMARYCOLOR, fontWeight: "bold" }}
						>
							{perfilPuesto[campo.id]}
						</TableCell>
					)
				if (campo.id === "departamento")
					return (
						<TableCell
							sx={{ fontSize: 16, color: PRIMARYCOLOR, fontWeight: "bold" }}
						>
							{colaboradores[0]?.departamento}
						</TableCell>
					)
				return (
					<TableCell
						sx={{ fontSize: 16, color: PRIMARYCOLOR, fontWeight: "bold" }}
					>
						{colaborador[campo.id]}
					</TableCell>
				)
			})}
		</TableRow>
	)
}
