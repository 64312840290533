import { apiPostGeneral } from "./Api_Call"
const BASE = process.env.REACT_APP_SERVER_URL
const DOCUMENTS=`${BASE}rh/documento_candidato`
/**
 * Función para Enviar los datos de prestamos a la API
 * @date 22/8/2023 - 15:20:22
 *
 * @async
 * @param {*} token-Credenciales para la API
 * @param {*} datos-Datos a enviar a la API
 * @param {*} [params={}] - Ruta Alternativa
 * @param {string} [actualizar=""]
 * @param {() => void} [setActualizar=() => {}]
 * @returns {void) => any}
 */
    export function postVacantesDocuments(token, data, params, message) {
        return apiPostGeneral(DOCUMENTS, token, data, params, message)
    }