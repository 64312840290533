import { useState, useEffect } from 'react'
import { Grid, Dialog, DialogContent, DialogTitle, Button, Box, Accordion, AccordionSummary, AccordionDetails, Typography, TextField } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import readXlsxFile from 'read-excel-file'
import { DataItem } from '../Common/DataDisplay';
import { saveColaborador, getColaboradores, getPerfiles, saveColaboMasiva } from '../../Services/Api';
import { useSelector } from "react-redux";
import ColabTable from './ColabTable';
import Autocomplete from '@mui/material/Autocomplete';
import { GridInputText } from '../Common/Inputs';

var nameLabels = { 'sucursal':'sucursal_nombre', 'nombre_completo': 'nombre', 'cp.':'cp', 'correo_electronico personal': 'correo_personal','No. CREDITO INFONAVIT':'no_infonavit', 'fecha_vencimiento': 'vencimiento','% CREDITO': 'porcentaje_infonavit', 'imss': 'no_imss' ,'tipo_credito': 'tipo_credito_infonavit' }

const ModalUploadColaborador = ({open,setOpen}) => {
    const token = useSelector(state => state.user.token);
    const [colaboradores,setColaboradores] = useState([])
    const [labels,setLabels]               = useState([])
    const [expanded, setExpanded]          = useState('panelDescripcion'); 
    const [loading,setLoading]             = useState(false)
    const [listPerfiles,setListPerfiles]   = useState([])
    const [listColab,setListColab]         = useState([])
    const [jefe,setJefe]                   = useState(null)
    const [fotoColabo,setFotoColabo]       = useState(null)
    const [emailLub,setEmailLub]           = useState('')
    const [txtButton,setTxtButton]         = useState('Guardar Colaborador')

    const handleChangePanel = (panel) => (event, isExpanded) => { 
        setEmailLub('')
        setExpanded(isExpanded ? panel : false); 
    };

    const readColaborador = (file) => {
        readXlsxFile(file.target.files[0]).then((rows) => {
            //rows.shift()
            setLabels(rows.shift())
            rows.map(row => {
                var perfil = listPerfiles.filter(perfil => perfil.nombre.toUpperCase() === row[28]
                )
                row[29] = perfil.length > 0 ? perfil[0].label : 0
                row[30] = perfil.length > 0 ? perfil[0].id : 0
                return row
            })

            setColaboradores(rows)
        })
    }

    useEffect(()=>{
        if(!open) return
        getPerfiles(token).then((response)=>{
            response.map(perfil =>  perfil.label = perfil.nombre)
            setListPerfiles(response)
            getColaboradores(token).then(response=>{
                response.map(colabo => colabo.label = colabo.nombre+' '+colabo.apellido_paterno)
                setListColab(response)
            })
        }).catch(error => console.log(error))
    },[token,open])

    const formatDate = (date) =>{
        if(date === 'NO' || date === ''|| date == null)
            return 'N/A' 
        if (isNaN(Date.parse(date))){   
            var newDate = date.split('/')
            if (newDate.length === 3)
                return newDate[2]+'-'+newDate[1]+'-'+newDate[0] 
            else{
                newDate = date.split('-')
                return newDate[2] + '-' + newDate[1] + '-01'  
            }
        } else{
            newDate = new Date(date)
            return newDate.getFullYear() + '-' + ((newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)) + '-' + (newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate())
        }
    }

    const createRowsTable = (colaborador, numRows, numCells, initCell, title, arrayDateCells = [], requiredCell = 0) => {
        var arrayRows = []
        var init = initCell
        for(var xx = 0; xx < numRows; xx++){
            for(var yy = 0;yy < arrayDateCells.length;yy++)
                colaborador[init + arrayDateCells[yy]] = formatDate(colaborador[init + arrayDateCells[yy]])
            if( !(colaborador[init + requiredCell] == '' || colaborador[init + requiredCell] === ' ' || colaborador[init + requiredCell] == 'NO' )){
                arrayRows.push(colaborador.slice(init, (init + numCells)))
            }
            init = init + numCells 
        }
        colaborador[title] = arrayRows 
        return arrayRows
        
    }
    // Esta funcion se encarga de guardar los colaboradores en la base de datos
    const guardarColaborador = (colaborador) => {
        if (colaborador[30] === 0){
            alert("El campo de perfil no puede estar vacio")
            return 
        }
        if (jefe == null){
            alert("El campo de jefe no puede estar vacio")
            return 
        }
        var dataColab = {}
        
        for(var x= 0 ;x< 26; x++){
            var label = labels[x].toLowerCase().replace(' de ','_').replace(' ','_').replace('.','')
            if(nameLabels.hasOwnProperty(label))
                label = nameLabels[label]
            dataColab[label] = colaborador[x]
        }; 
        //var fechaNacimiento = new Date(colaborador[31])
        //var mesNacimiento = fechaNacimiento.getMonth()+1
        dataColab.fecha_nacimiento = '1969-01-01'//colaborador[31] //fechaNacimiento.getFullYear()+'-'+(mesNacimiento < 10 ? '0' + mesNacimiento:mesNacimiento)  + '-' + (fechaNacimiento.getDate()  < 10 ? '0' + fechaNacimiento.getDate()  : fechaNacimiento.getDate() ) 
        //var fechaVencimiento = new Date(colaborador[31])
        //var mesVencimiento = fechaVencimiento.getMonth() + 1
        //var vencimiento = fechaVencimiento.getFullYear()+'-'+(mesVencimiento < 10 ? '0' + mesVencimiento: mesVencimiento)  + '-' + (fechaVencimiento.getDate()  < 10 ? '0' + fechaVencimiento.getDate()  : fechaVencimiento.getDate() ) 
        dataColab.vencimiento = colaborador[32] === 'N/A' ? null : colaborador[32]
        //dataColab.vencimiento = vencimiento
        dataColab.no_infonavit = colaborador[25]
        dataColab.porcentaje_infonavit = colaborador[27]
        dataColab.tipo_credito_infonavit = colaborador[26]
        dataColab.perfil_puesto = colaborador[30]
        dataColab.jefe_directo = jefe.id
        dataColab.colaborador_id = 0
        dataColab.email = emailLub 
        var dataForm = jsonToFormData(dataColab)
        /*dataForm.append('foto',fotoColabo)
        dataForm.append('familiares', JSON.stringify(colaborador.familiares))
        dataForm.append('contactos', JSON.stringify(colaborador.contactos))
        dataForm.append('beneficiarios', JSON.stringify(colaborador.beneficiarios))
        dataForm.append('automovil', JSON.stringify(colaborador.vehiculos))
        dataForm.append('escolaridad', JSON.stringify(colaborador.escolaridad))
        dataForm.append('trayectoriaLubtrac', JSON.stringify(colaborador.trayectoriaLubtrac))
        dataForm.append('idiomas', JSON.stringify(colaborador.idiomas))
        dataForm.append('cursos', JSON.stringify(colaborador.cursos))
        dataForm.append('trayectoriaLaboral', JSON.stringify(colaborador.trayectoriaLaboral))
        dataForm.append('fecha_nacimiento',dataColab.fecha_nacimiento.toString())*/
        setTxtButton('Guardando...')
        setLoading(true)
        saveColaboMasiva(token, dataForm).then(response => { 
            alert('Colaborador Guadado exitosamente')
        }).catch(error => {
            console.log(error.response.data)
            if (error.response.status == 500)
                alert("Hubo un error en el servidor, favor de reportarlo al area de TI.")
            else if (error.response.status == 400)
                alert("Error en los datos capturados, favor de verificar la informacion: " + error.response.data.message)

        }).finally(() => {
            setLoading(false)
            setTxtButton('Guardar Colaborador')
        }
            )
    }
    // Esta
    function jsonToFormData(data) {
        const formData = new FormData();
        function appendToFormData(key, value) {
            if (Array.isArray(value)) 
                for (let i = 0; i < value.length; i++) 
                    formData.append(`${key}[]`, value[i]);
            else 
                formData.append(key, value);
            
        }

        function recursiveConversion(obj, parentKey = '') {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const currentValue = obj[key];
                    const finalKey = parentKey ? `${parentKey}.${key}` : key;

                    if (typeof currentValue === 'object' && !Array.isArray(currentValue)) {
                        recursiveConversion(currentValue, finalKey);
                    } else {
                        appendToFormData(finalKey, currentValue);
                    }
                }
                else
                    console.log("No encuentra la key "+key)
            }
        }

        recursiveConversion(data);

        return formData;
    }

    const closeModal = () => {
        setOpen(false)
        setColaboradores([])
        setJefe(null)
        setFotoColabo(null)
        setTxtButton('Guardar Colaborador')
    }

    return (
        <Dialog open={open} maxWidth={colaboradores.length == 0 ?'md':'xl'} fullWidth = { true} onClose = {() => closeModal()}>
            <DialogTitle sx={{ fontSize: 24, fontWeight: 'bold', color: "#555" }}>
                Archivo de colaboradores   
                <Button
                sx={{marginLeft:10}}
                    component="label" >
                    Subir Archivo
                    <input type="file" hidden onChange={readColaborador} accept=".xls,.xlsx" />
                </Button>
                </DialogTitle>
                <DialogContent>
                
                {colaboradores.map(colaborador => {
                    colaborador[31] = formatDate(colaborador[5])
                    colaborador[32] = formatDate(colaborador[12])
                    console.log(colaborador[32])
                    //colaborador[153] = formatDate(colaborador[153])
                    //colaborador[153] = formatDate(colaborador[153])
                    return <Accordion key={colaborador[9]} expanded={expanded === colaborador[9]} onChange={handleChangePanel(colaborador[9])}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />} 
                                    aria-controls="panel1a-content"
                                    id="panel1a-header" >
                                    <Box component="b" sx={{ fontSize: 20, color: "#1d3454" }}> {colaborador[1] + ' '+colaborador[2]+' '+colaborador[3]} </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Grid container >
                                    <Grid item md={12} sx={{ p: 1.5,backgroundColor: "#1d3454" }} > <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: "#FFF" }} >Datos Institucionales</Typography> </Grid>
                                    <DataItem label={'No Empleado'} value={colaborador[0]} size={2}/>
                                    <DataItem label={'Sucursal'} value={colaborador[4]} size={2}/>
                                    <Grid item md={4} sx={{padding:1}}>
                                            <Autocomplete
                                                disablePortal
                                                id="autoPerfil"
                                                options={listPerfiles}
                                                sx={{ width: 300 }}
                                                value={colaborador[29] === 0 ? null : colaborador[29] }
                                                onChange={(event, value) => {
                                                    colaborador[29] = value.label
                                                    colaborador[30] = value.id
                                                }}
                                                //onChange={(event, value) => setPerfil(value)}
                                                renderInput={(params) => <TextField {...params} label="Perfil de puesto" />}/>
                                    </Grid>
                                    <Grid item md={4} sx={{padding:1}}>
                                        <Autocomplete
                                                disablePortal
                                                id="autoCoplabo"
                                                options={listColab}
                                                sx={{ width: 300 }}
                                                onChange={(event, value) => setJefe(value)}
                                                renderInput={(params) => <TextField {...params} label="Jefe Directo" />}/>
                                    </Grid>
                                    <GridInputText label={"Correo corporativo"} value={emailLub} setValue={setEmailLub} upperCase={false}/>
                                     <Grid item md={2} >
                                        <Typography sx={{pt:5,ml:5}} variant="span" gutterBottom>{fotoColabo == null? '': fotoColabo.name}</Typography>
                                    </Grid>
                                    {/*<Grid item md={2}>
                                        <Button
                                            sx={{mt:1}}
                                            variant="contained"
                                            component="label" >
                                            foto colaborador
                                            <input type="file" hidden onChange={fileChange} accept="image/png,  image/jpeg"/>
                                        </Button>
                                    </Grid>*/}
                                    <Grid item md={12} sx={{ p: 1.5,backgroundColor: "#1d3454" }} > <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: "#FFF" }} >Datos Generales</Typography> </Grid>
                                    <DataItem label={'Apellido Paterno'} value={colaborador[1]} />
                                    <DataItem label={'Nombre'} value={colaborador[3]} size={2}/>
                                    <DataItem label={'Estado Civil'} value={colaborador[7]} size={2} />
                                    <DataItem label={'Sexo'} value={colaborador[8]} size={2} />
                                    <DataItem label={'Fecha Nacimiento'} value={colaborador[31]}  />
                                    <DataItem label={'Lugar Nacimiento'} value={colaborador[6]} />
                                    <DataItem label={'CURP'} value={colaborador[9]}  />
                                    <DataItem label={'RFC'} value={colaborador[10]} size={2} />
                                    <DataItem label={'Licencia'} value={colaborador[11]}  />
                                    <DataItem label={'Vencimiento'} value={colaborador[32]}  />
                                    <DataItem label={'Clave Elector'} value={colaborador[23]}  />
                                    <DataItem label={'Seccion'} value={colaborador[24]} size={2} />
                                    <Grid item md={12} sx={{ p: 1.5,backgroundColor: "#1d3454" }} > <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: "#FFF" }} >  Domicilio y Contacto</Typography> </Grid>
                                    <DataItem label={'Domicilio'} value={colaborador[13]}  />
                                    <DataItem label={'Colonia'} value={colaborador[14]}  />
                                    <DataItem label={'Poblacion'} value={colaborador[15]} size={2} />
                                    <DataItem label={'Estado'} value={colaborador[16]} size={2} />
                                    <DataItem label={'C.P.'} value={colaborador[17]} size={1} />
                                    <DataItem label={'Telefono'} value={colaborador[18]} />
                                    <DataItem label={'Correo'} value={colaborador[19]}/>
                                    <Grid item md={12} sx={{ p: 1.5,backgroundColor: "#1d3454" }} > <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: "#FFF" }} >  IMSS e Infonavit</Typography> </Grid>
                                    <DataItem label={'IMSS'} value={colaborador[20]} size={2} />
                                    <DataItem label={'Grupo Sanguineo'} value={colaborador[21]} size={2} />
                                    <DataItem label={'Alergias'} value={colaborador[22]} size={2} />
                                    <DataItem label={'No. Infonavit'} value={colaborador[25]} size={2} />
                                    <DataItem label={'Tipo Credito'} value={colaborador[26]} size={2} />
                                    <DataItem label={'% de Credito'} value={colaborador[27]} size={2} />
                                    {/*<ColabTable title={'Familiares'} headers={labels.slice(28, 36)} data={createRowsTable(colaborador, 5, 8, 28,'familiares',[1])}/> 
                                    <ColabTable title={'Contactos de emergencia'} headers={labels.slice(92, 96)} data={createRowsTable(colaborador, 2, 4, 92, 'contactos')} />
                                    <ColabTable title={'Beneficiarios'} headers={labels.slice(100, 103)} data={createRowsTable(colaborador, 4, 3, 100, 'beneficiarios')}  />
                                    <ColabTable title={'Vehiculo'} headers={labels.slice(112, 115)} data={createRowsTable(colaborador, 1, 3, 112,'vehiculos')} />
                                    <ColabTable title={'Escolaridad'} headers={labels.slice(115, 120)} data={createRowsTable(colaborador, 6, 5, 115,'escolaridad')} />
                                    <ColabTable title={'Idiomas'} headers={labels.slice(145, 151)} data={createRowsTable(colaborador, 1, 6, 145,'idiomas')} />
                                    <ColabTable title={'Cursos Externos'} headers={labels.slice(151, 157)} data={createRowsTable(colaborador, 4, 6, 151,'cursos',[2,3])} />
                                    <ColabTable title={'Trayectoria Laboral'} headers={labels.slice(175, 183)} data={createRowsTable(colaborador, 3, 8, 175,'trayectoriaLaboral')} />
                <ColabTable title={'Trayectoria Lubtrac'} headers={labels.slice(199, 203)} data={createRowsTable(colaborador, 3, 4, 199,'trayectoriaLubtrac')} />*/}
                                  
                                    <Grid item md={12} sx={{ textAlign: 'right' }} > <Button onClick={() => guardarColaborador(colaborador)}>{txtButton}</Button> </Grid>

                                </Grid>
                              
                                </AccordionDetails>
                            </Accordion>
                })}
                </DialogContent>
            </Dialog >
    )
}

export default ModalUploadColaborador