import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import SearchIcon from "@mui/icons-material/Search"
import { Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { postVacantesDocuments } from "../../../Services/Api_Vacantes"
import { ButtonFile } from "../../Common/Buttons"
import { ButtonDialog, DialogGeneral } from "../../Common/Dialog"
import { InputSelectGrid2 } from "../../Common/Inputs2"
import { ContextVacante } from "../DetalleVacante"
import { toast } from "react-toastify"

const ModalNewDoc = ({ open, setOpen, candidato }) => {
	const vacante = useContext(ContextVacante)
	const token = useSelector((state) => state.user.token)
	const [nombreDoc, setNombreDoc] = useState("")
	const [documento, setDocumento] = useState(null)
	const [disabled, setDisabled] = useState(null)
	const ARCHIVOS = [
		{ label: "CV" },
		{ label: "propuesta" },
		{ label: "psicometricos" },
		{ label: "prueba_manejo" },
	]
	const upLoadDocument = async () => {
		if (nombreDoc === "") {
			toast.error("Seleccione un documento")
			return null
		}
		if (documento === null) {
			toast.error("Seleccione un archivo")
			return null
		}
		setDisabled(true)
		let formData = new FormData()
		formData.append("vacante_id", vacante.id)
		formData.append("candidato_id", candidato.id)
		formData.append("nombre_documento", nombreDoc)
		formData.append("documento", documento)
		let { success } = await postVacantesDocuments(token, formData)
		if (success) {
			setOpen(false)
			setDocumento(null)
			setNombreDoc("")
		}
		setDisabled(false)
	}

	return (
		<DialogGeneral
			title="Nuevo documento"
			propsTitle={{ sx: { fontSize: 24, fontWeight: "bold", color: "#555" } }}
			open={open}
			setOpen={setOpen}
			maxWidth={"xs"}
			fullWidth={true}
			buttons={[
				<ButtonDialog
					key="button_cancelar"
					label="Cancelar"
					disabled={disabled}
					onClick={() => setOpen(false)}
				/>,
				<ButtonDialog
					key="button_subir"
					label="Subir"
					disabled={disabled}
					onClick={() => upLoadDocument()}
				/>,
			]}
		>
			<Grid
				justifyContent="center"
				// alignItems="center"
				container
				spacing={1}
			>
				<InputSelectGrid2
					label="Archivo"
					data={ARCHIVOS}
					dataID="label"
					setValue={setNombreDoc}
					GridProps={{ xs: 5 }}
				/>
				<Grid>
					<ButtonFile
						style={{ height: "100%", display: "flex" }}
						label="Buscar"
						accept=".pdf"
						startIcon={<SearchIcon />}
						operation={(doc) => {
							setDocumento(doc)
						}}
					/>
				</Grid>
				{documento == null ? (
					<></>
				) : (
					<Grid
						container
						md={12}
						justifyContent="center"
						alignItems="center"
						sx={{ flexDirection: "row" }}
					>
						<Grid>
							<PictureAsPdfIcon
								color="error"
								sx={{ mt: 0 }}
							/>
						</Grid>
						<Grid>
							<Typography
								variant="span"
								boxShadow={50}
								width="100%"
							>
								{documento.name}
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
		</DialogGeneral>
	)
}

export default ModalNewDoc
