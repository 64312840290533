import React from 'react'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material'
import { PRIMARYCOLOR } from '../../Constants/Styles'

const ColabTable = ({ headers, data, title, size= 12 }) => {
    if(data.length == 0)
        return <Grid container md={size}>
                    <Grid item md={12} sx={{ p: 1.5,backgroundColor: PRIMARYCOLOR,mb:-1 }} > <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: "#FFF" }} >  {title} (Sin Datos)</Typography> </Grid>
                    <Grid item md={12} sx={{ p: 1.5,mb:-1 }} > <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: "#FFF" }} >  </Typography> </Grid>
                   
                </Grid>
    return  <Grid container md={size}>
                <Grid item md={12} sx={{ p: 1.5,backgroundColor: PRIMARYCOLOR,mb:-1 }} > <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: "#FFF" }} >  {title}</Typography> </Grid>
                <Grid item xs={12}>                 
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {headers.map((header, index) => (
                                        <TableCell key={index} sx={{ backgroundColor: PRIMARYCOLOR, color: '#eee', fontWeight: 'bold' }}>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow key={index}  >
                                        {row.map((cell, cellIndex) => <TableCell key={cellIndex} >{cell}</TableCell>)}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
    
}

export default ColabTable