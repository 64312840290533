import { useState } from "react"

import { ButtonDialog, DialogGeneral } from "#common/Dialog"

import UseBajas from "Components/bajas/UseBajas"
//mui
import { post_rh_api } from "#api/Api_Call"
import { InputSelect } from "#common/input/InputSelect"
import { InputDate } from "#common/input/InputDate"
import { TextInfoField } from "#common/Texto"
import { Typography } from "@mui/material"
import TextField from "@mui/material/TextField"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import {
	BAJA_MOTIVO,
	NAME_COLLABORATOR,
	TYPE_BAJA,
} from "Components/bajas/TextBaja"
import { TERMINATION_DATE } from "../../bajas/TextBaja"
import { UseDetalleColaboradorContext } from "../Solicitudes/SolicitudesContext"
/**
 *
 * @param {Object} props
 * @param {boolean} props.open - Indica si el dialogo se va a abrir o cerrar
 * @param {function} props.setOpen - Función para cambiar el estado de apertura del dialogo
 * @param {function} props.reloadNow - Función para actualizar la tabla de solicitudes
 * @param {Object} props.colaborador - Propiedades del colaborador que se va a solicitar
 * @returns
 */
export default function DialogSolicitudBaja({ reload, ...props }) {
	const { colaborador: boss, isRH } = UseDetalleColaboradorContext()
	const { open, setOpen } = props
	const nombre_completo = props?.colaborador?.nombre_completo
	const id = props?.colaborador?.id
	const { bajas: types } = UseBajas("/types", { open })

	const [data, setData] = useState({
		id,
		request: boss?.id,
		reason: "",
		motivo: "",
		termination_date: null,
		type: null,
	})

	const selectDate = (date) => {
		setData({ ...data, termination_date: date?.format("YYYY-MM-DD") })
	}

	const sendRequest = async () => {
		const response = await post_rh_api("baja", data)
		if (response) {
			setOpen(false)
			reload()
		}
	}

	return (
		<DialogGeneral
			{...props}
			title={
				<Typography variant="h5">
					<strong>Solicitud de baja</strong>
				</Typography>
			}
			buttons={
				<ButtonDialog
					key={"enviar"}
					label="Enviar"
					onClick={sendRequest}
				/>
			}
		>
			<Grid2
				container
				spacing={2}
			>
				<Grid2 xs={12}>
					<TextInfoField
						campo={NAME_COLLABORATOR}
						valor={nombre_completo}
						whiteSpace="pre-line"
						separador={"\n"}
					/>
				</Grid2>
				<Grid2 xs={5}>
					<InputDate
						label={TERMINATION_DATE}
						onChange={selectDate}
						disablePast={!isRH}
					/>
				</Grid2>
				<Grid2 xs={7}>
					<InputSelect
						label={TYPE_BAJA}
						data={types}
						value={data.type}
						setValue={(d) => {
							setData({ ...data, type: d })
						}}
					/>
				</Grid2>
				<Grid2 xs={12}>
					<TextField
						id="bajaMotivo"
						label={BAJA_MOTIVO}
						variant="outlined"
						fullWidth
						multiline
						value={data.reason}
						onChange={(event) => {
							setData({ ...data, reason: event.target.value })
						}}
					/>
				</Grid2>
			</Grid2>
		</DialogGeneral>
	)
}
