import { IconButton, Tooltip } from "@mui/material"
import RadioButtonCheckedSharpIcon from "@mui/icons-material/RadioButtonCheckedSharp"
/**
 * @typedef {import('@mui/material').IconButtonProps} IconButtonProps
 * @typedef {import('@mui/material').TooltipProps} TooltipProps
 */
/**
 * @typedef {object} ButtonIconExtends
 * @property {string} [title=""] - Texto de la descripción del botón.
 * @property {React.ElementType} [icon=<RadioButtonCheckedSharpIcon />] - Icono del botón.
 * @property {boolean} [hidden=false] - Indica si el botón debe mostrarse o no.
 * @property {TooltipProps} [tooltip] - Propiedades adicionales para el componente Tooltip.
 */
/**
 * @typedef {ButtonIconExtends & IconButtonProps} ButtonIconProps
 */

/**
 * @param {ButtonIconProps} props
 * @returns
 */
export default function ButtonIcon({
	title = "",
	icon = <RadioButtonCheckedSharpIcon />,
	hidden = false,
	tooltip,
	...props
}) {
	if (hidden) return null
	return (
		<Tooltip
			title={title}
			{...tooltip}
		>
			<IconButton {...props}>{icon}</IconButton>
		</Tooltip>
	)
}
