import {
	Button,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getDocumentoCandidato } from "../../../Services/Api"
import { DialogGeneral } from "../../Common/Dialog"
import ModalNewDoc from "./ModalNewDoc"
import { ButtonIcon } from "../../Common/Buttons"
//icons
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import DownloadIcon from "@mui/icons-material/Download"
const ModalDocumentation = ({ open, setOpen, candidato }) => {
	const token = useSelector((state) => state.user.token)
	const [openNewDoc, setOpenNewDoc] = useState(false)
	const [documentos, setDocumentos] = useState([])

	useEffect(() => {
		if (candidato == null || !open) return
		setDocumentos([])
		getDocumentoCandidato(token, candidato.id).then((response) => {
			setDocumentos(response.data)
		})
	}, [candidato, token, open])

	if (!open || candidato == null) return
	const columns = [
		{ title: "Archivo" },
		{ title: "Fecha de subida" },
		{
			title: (
				<Stack
					width={"100%"}
					direction={"row"}
					alignItems={"center"}
					spacing={1}
					justifyContent="space-between"
				>
					<Typography fontWeight={"bold"}>Subido por</Typography>
					<ButtonIcon
						title="Añadir Documento"
						icon={<AddCircleOutlineIcon />}
						sx={{ color: "#eee" }}
						onClick={() => setOpenNewDoc(true)}
					/>
				</Stack>
			),
		},
	]
	const styleBody = {
		fontWeight: "bold",
		paddingTop: 1,
		paddingBottom: 1,
		fontSize: 16,
	}

	return (
		<DialogGeneral
			title={`${candidato.nombre}`}
			propsTitle={{
				sx: {
					paddingBottom: "0",
					fontWeight: "bold",
					color: "#555",
					whiteSpace: "pre-line",
				},
			}}
			open={true}
			setOpen={setOpen}
			maxWidth={"md"}
			fullWidth={true}
		>
			{documentos.length === 0 ? (
				<>No hay documentos disponibles</>
			) : (
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.title}
										sx={{
											backgroundColor: "#1d3454",
											color: "#eee",
											fontWeight: "bold",
											paddingTop: 1,
											paddingBottom: 1,
											fontSize: 16,
											...column.style,
										}}
									>
										{column.title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{documentos.map((documento) => {
								return (
									<TableRow key={documento.fecha}>
										<TableCell sx={{ ...styleBody, textAlign: "left" }}>
											<Button
												fullWidth
												variant="contained"
												startIcon={<DownloadIcon />}
												onClick={() =>
													window.open(documento.url_documento, "_blank")
												}
											>
												{documento.nombre_documento}
											</Button>
										</TableCell>
										<TableCell sx={styleBody}>{documento.fecha}</TableCell>
										<TableCell sx={styleBody}>
											{documento.colaborador__nombre +
												" " +
												documento.colaborador__apellido_paterno}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<ModalNewDoc
				open={openNewDoc}
				setOpen={setOpenNewDoc}
				candidato={candidato}
			/>
		</DialogGeneral>
	)
}

export default ModalDocumentation
