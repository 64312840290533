// React 
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import readXlsxFile from 'read-excel-file';

// 
import { getDepartamentos, getPerfiles, saveProfile, updateProfile } from '#api/Api';
import { reloadDataVacante } from 'app/pageSlice';

//componentes
import Competencias from './Competencias';
import Interacciones from './Interacciones';
import { VacanteHerramientas } from './VacanteHerramientas';

// Material
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { toast } from 'react-toastify';
import { InputAutocomplete } from '#common/Inputs2';


const ModalNewPerfil = ({ open, setOpen, dataPerfil }) => {
    const token = useSelector(state => state.user.token)
    const actionApi = dataPerfil == null ? saveProfile : updateProfile
    const dispatch = useDispatch()
    // Descripción 
    console.log(dataPerfil)
    const idPerfil = dataPerfil == null ? null : dataPerfil.id
    const [nombrePerfil, setNombrePerfil] = useState(dataPerfil == null ? '': dataPerfil.nombre)
    const [areaID, setAreaID] = useState(dataPerfil?.area?.id||null)
    const [reporta, setReporta] = useState(dataPerfil?.reporta_id||null)
    const [objetivo, setObjetivo] = useState(dataPerfil == null ? '' : dataPerfil.objetivo)
    const [defaultOptDepto, setDefaultDepto] = useState(dataPerfil == null ? {} :{ value: dataPerfil.area_id, label: dataPerfil.area.descripcion })
    const [defaultOptReporta, setDefaultReporta] = useState(dataPerfil == null ? {} :{ value: dataPerfil.reporta_id, label: dataPerfil.reporta_a })
    // Competencias
    const [listEducation, setListEducation] = useState(dataPerfil == null ? [] : dataPerfil.perfil_competencia.filter(item => item.tipo === 'Educación'))
    const [listCapacitation, setListCapacitation] = useState(dataPerfil == null ? [] : dataPerfil.perfil_competencia.filter(item => item.tipo === 'Capacitación'))
    const [listExperiencia, setListExperiencia] = useState(dataPerfil == null ? [] : dataPerfil.perfil_competencia.filter(item => item.tipo === 'Experiencia'))
    const [listIdiomas, setListIdiomas] = useState(dataPerfil == null ? [] : dataPerfil.perfil_competencia.filter(item => item.tipo === 'Idiomas'))
    const [listSoftware, setListSoftware] = useState(dataPerfil == null ? [] : dataPerfil.perfil_competencia.filter(item => item.tipo === 'Software'))
    const [listAdicionales, setListAdicionales] = useState(dataPerfil == null ? [] : dataPerfil.perfil_competencia.filter(item => item.tipo === 'Adicionales'))
    const [listRespo, setListRespo] = useState(dataPerfil == null ? [] : dataPerfil.perfil_competencia.filter(item => item.tipo === 'Responsabilidades'))
    const [listTools, setListTools] = useState(dataPerfil == null ? [] : dataPerfil?.herramientas)
    const [textRespo,setTextRespo] = useState('')
    // Interacciones
    const [interaccionesInt, setInteraccionesInt] = useState(dataPerfil == null ? [] : dataPerfil.perfil_interaccion.filter(item => item.tipo === 'Interna'))
    const [internaQuien,setInternaQuien]         = useState('')
    const [internaPara,setInternaPara]           = useState('')
    const [interaccionesExt, setInteraccionesExt] = useState(dataPerfil == null ? [] : dataPerfil.perfil_interaccion.filter(item => item.tipo === 'Externa'))
    const [externaQuien,setExternaQuien]         = useState('')
    const [externaPara,setExternaPara]           = useState('')
    
    const [expanded, setExpanded]                = useState('panelDescription'); // Accordion
    const [optDeptos,setOptDeptos]               = useState([])
    // Options and Loading
    const [loadingPuesto,setLoadingPuesto] = useState(false)
    const [perfiles,setPerfiles] = useState([])
    // Message
    const [btnDisabled,setBtnDisabled] = useState(false)

    useEffect(() => {
        if (!open) return
        if (!dataPerfil == null) setAreaID(0)
        getDepartamentos(token).then((response) => {
            response.map(depto => {
                depto.value = depto.id
                depto.label = depto.descripcion
            })
            setOptDeptos(response)
            setLoadingPuesto(true)
            getPerfiles(token).then((response) => {
                response.map(perfil => {
                    perfil.value = perfil.id
                    perfil.label = perfil.nombre
                })
                setPerfiles(response)
            }).catch((error) => { console.log('Error ' + error) }
            ).finally(() => setLoadingPuesto(false))
        }).catch(error => { console.log('Error ' + error) }
        ).finally(() => setLoadingPuesto(false))
    }, [open, token, dataPerfil])

    const fileChange = (e) =>{
        readXlsxFile(e.target.files[0]).then((rows) => {
            setNombrePerfil(rows[8][2])
            let area = optDeptos.filter((depto) => (depto.descripcion.toLowerCase() === rows[10][4].toLowerCase() ))
            setAreaID(area[0].id)
            let reporta = perfiles.filter((perfil) => (perfil.nombre === rows[10][16] ))
            if(reporta.length > 0)
                setReporta(reporta[0].id)
            setObjetivo(rows[14][2])
            setListEducation(createlistJson(rows[24][4].split(','),'Educacion'))
            setListCapacitation(createlistJson(rows[24][10].split(','),'Capacitacion'))
            setListExperiencia(createlistJson(rows[24][16].split(','),'Experiencia'))
            setListIdiomas(createlistJson(rows[28][4].split(',' ),'Idiomas'))
            setListSoftware(createlistJson(rows[28][10].split(','),'Software'))
            setListAdicionales(createlistJson(rows[28][16].split(','),'Adicionales'))
            let indexResponsabilidades = 34
            if(rows[32][2] !== 'RESPONSABILIDADES'){
                for(let xx = 32; xx<40; xx++){
                    if(rows[xx][2] === 'RESPONSABILIDADES'){
                        indexResponsabilidades = xx
                        break
                    }
                }
            }
            let listaResponsabilidades = []
            let indexInteracciones = indexResponsabilidades
            for(let zz = indexResponsabilidades; zz < 50; zz++){
                indexInteracciones++
                if(rows[zz][2] == null)
                    break
                    listaResponsabilidades.push({'id':0,'descripcion':rows[zz][2].replace('*',''),'tipo':'Responsabilidades'})
            }
            setListRespo(listaResponsabilidades)
            indexInteracciones += 4
            let listInteraccionesInternas =[]
            let listInteraccionesExternas =[]
            for(let yy = indexInteracciones; yy < rows.length; yy++){
                if(rows[yy] != null && rows[yy][2] !== null)
                    listInteraccionesInternas.push({con_quien:rows[yy][2],para_que:rows[yy][6]})
                if(rows[yy] != null && rows[yy][11] !== null)
                    listInteraccionesExternas.push({con_quien:rows[yy][11],para_que:rows[yy][15]})
            }
            setInteraccionesExt(listInteraccionesExternas)
            setInteraccionesInt(listInteraccionesInternas)
         })
         
    }

    // funcion para crear json de competencias con estructura {id:0,descripcion:'descripcion',tipo:'tipo'}
    const createlistJson = (list, tipo) => {    
        let newList = []
        list.map((item) => {
            newList.push({ 'id': 0, 'descripcion': item, 'tipo': tipo })
        })
        return newList
    }

      
    const handleChangePanel = (panel) => (event, isExpanded) => { setExpanded(isExpanded ? panel : false); };

    const addItem = (item) => {
        setListRespo([...listRespo, { 'id': 0, "tipo": "Responsabilidades", "descripcion": item }])
        setTextRespo('')
    }

    const removeItem  = indexList => setListRespo(listRespo.filter((item,index) => index !== indexList))
    
    const save = () => {

        const errorToast=(campo)=>{
            toast.error(`El ${campo} no puede estar vació`,{toastId:campo})
        }
        console.log(areaID)
        if(nombrePerfil === '') return errorToast("nombre del perfil ")
        else if(!areaID) return errorToast("Departamento ")
        else if(!reporta) return errorToast("jefe directo ")
        else if(!objetivo) return errorToast("objetivo del puesto ")
        else if(!listEducation.length ) return errorToast("listado de educación ")
        else if(!listCapacitation.length ) return errorToast("listado de capacitación ")
        else if(!listExperiencia.length ) return errorToast("listado de experiencia ")
        else if(!listRespo.length ) return errorToast("listado de responsabilidades ")
        else if(!interaccionesExt.length ) return errorToast("listado de interacciones ")
        else if(!interaccionesInt.length ) return errorToast("listado de interacciones ")
        else if(!listTools.length ) return errorToast("listado Herramientas ")
        
        setBtnDisabled(true)
        let response= actionApi(
            token, idPerfil,
            nombrePerfil, areaID,
            reporta, objetivo,
            listEducation,listCapacitation, 
            listExperiencia,listIdiomas, 
            listSoftware, listAdicionales, 
            listRespo, interaccionesInt,
            interaccionesExt,listTools
        )
        toast.promise(response,{
            pending: 'Actualizando...',
            success: 'Actualización exitosa ',
            error: 'Error al actualizar'
        })
        response.then((response) => {
            setOpen(false)
            dispatch(reloadDataVacante())
        }).catch( err =>  alert("Error al guardar el perfil "+err)
        ).finally(() => setBtnDisabled(false))
        

    }
    console.log(reporta)
    console.log(areaID)
    if(!1)
        return
    return(
        <Dialog open={open} maxWidth={'md'} fullWidth={true} onClose={() => {
            setOpen(false)
        }}>
            <DialogTitle>Nuevo perfil de puesto</DialogTitle>
            <DialogContent>
                {/* Descripción de puesto */ }
                <Accordion  expanded={expanded === 'panelDescription'} onChange={handleChangePanel('panelDescription')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header" >
                    <Box component="b" sx={{ fontSize:20,color:"#1d3454" }}> Descripcion de puesto </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{mt:0, mb:0 }}>
                                <Box >
                                    <TextField
                                        autoFocus
                                        id="nombre"
                                        label="Nombre del Puesto"
                                        fullWidth
                                        required 
                                        value={nombrePerfil}
                                        onChange={(event) => setNombrePerfil(event.target.value)}
                                        sx={{mt:0}} />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <InputAutocomplete 
                                    label='Departamento *'
                                    options={optDeptos}
                                    optionValue={"id"}
                                    setOption={setAreaID}
                                    value={areaID}
                                    defaultValue={defaultOptDepto}
                                />
                                {/* <Box sx={{ minWidth: 120, mt:2, mb:2 }}>
                                    <FormControl fullWidth>
                                        <Select id={'area_perfil'} defaultValue={defaultOptDepto} options={optDeptos} onChange={(selected) => setAreaID(selected.id)} />
                                    </FormControl>
                                </Box> */}
                            </Grid>
                            <Grid item xs={6}>
                                <InputAutocomplete 
                                    label='Reporta a *'
                                    options={perfiles}
                                    optionValue={"id"}
                                    setOption={setReporta}
                                    value={reporta}
                                    defaultValue={defaultOptReporta}
                                />
                                {/* <Box sx={{ minWidth: 120, mt:2,mb:2 }}>
                                    <FormControl fullWidth>
                                        <Select id={'reporta_perfil'} 
                                        defaultValue={defaultOptReporta} options={perfiles} onChange={(selected) => setReporta(selected.id)} isLoading={loadingPuesto} />
                                    </FormControl>
                                </Box> */}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Objetivo del puesto"
                                    multiline
                                    fullWidth
                                    required
                                    value={objetivo}
                                    rows={4}
                                    sx={{zIndex:0}}
                                    onChange={ event => setObjetivo(event.target.value)} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                
                {/* Competencias */ }
                <Competencias 
                    expanded={expanded} 
                    handleChangePanel={handleChangePanel} 
                    listEducacion = {listEducation} setListEducacion = {setListEducation}
                    listCapacitacion = {listCapacitation} setListCapacitacion = {setListCapacitation}
                    listExperiencia = {listExperiencia} setListExperiencia  = {setListExperiencia}
                    listIdiomas = {listIdiomas} setListIdiomas = {setListIdiomas} 
                    listSoftware = {listSoftware} setListSoftware = {setListSoftware}
                    listAdicionales ={listAdicionales} setListAdicionales={setListAdicionales} />
                {/* Responsabilidades */}
                <Accordion expanded={expanded === 'panelResponsabilidades'} onChange={handleChangePanel('panelResponsabilidades')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header" >
                    <Box component="b" sx={{fontSize:20,color:"#1d3454" }}> Responsabilidades </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                            <TextField
                                    margin="dense"
                                    onChange={(event)=> setTextRespo(event.target.value)}
                                    value={textRespo}
                                    fullWidth />
                            </Grid>
                            <Grid item md={2} >
                            <Grid item md={2} > <IconButton aria-label="delete" sx={{mt:1}} onClick={()=>addItem( textRespo)}> <AddCircleIcon color='success' fontSize='large'/> </IconButton> </Grid>
                            </Grid>
                            <Grid item md={12} >
                                <Grid item xs={12} sx={{paddingX:3}}>
                                    <Grid container spacing={2}>
                                        <Grid item md={12} >
                                            <List dense={false}>
                                                {listRespo.map((item,index)=>{
                                                    return <Box key={index}>
                                                                <ListItem
                                                                    secondaryAction={ <IconButton edge="end" onClick={()=>removeItem(index)}> <DeleteIcon color='error'/> </IconButton> } >
                                                                    <ListItemIcon> <TrackChangesIcon color='primary'/> </ListItemIcon>
                                                                    <ListItemText primary={item.descripcion} />
                                                                </ListItem>
                                                                <Divider />
                                                            </Box>
                                                })}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> 
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {/* Interacciones con otros */}
                <Accordion expanded={expanded === 'panelInteracciones'} onChange={handleChangePanel('panelInteracciones')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header" >
                        <Box component="b" sx={{ fontSize:20,color:"#1d3454" }}> Interacciones </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Interacciones 
                            title={'Interacciones Internas'} 
                            quienText={internaQuien} 
                            setQuienText={setInternaQuien} 
                            paraText={internaPara} 
                            setParaText={setInternaPara}
                            listInteraccion={interaccionesInt}
                            setListInteraccion={setInteraccionesInt} />
                        <Interacciones 
                            title={'Interacciones Externas'} 
                            quienText={externaQuien} 
                            setQuienText={setExternaQuien} 
                            paraText={externaPara} 
                            setParaText={setExternaPara}
                            listInteraccion={interaccionesExt}
                            setListInteraccion={setInteraccionesExt} />
                    </AccordionDetails>
                </Accordion>
                <VacanteHerramientas 
                    setListTools={setListTools}
                    listTools={dataPerfil?.herramientas||[]}
                    expanded={expanded} 
                    handleChangePanel={handleChangePanel} 
                    /> 
            </DialogContent>
            <DialogActions>
                <Button
                    component="label" >
                    Cargar perfil
                    <input  type="file" hidden onChange={fileChange}  accept=".xls,.xlsx"/>
                </Button>
                <Button onClick={() => setOpen(false)}>Cancelar</Button>
                <Button onClick={() => save()} disabled={btnDisabled}>Guardar perfil</Button>
            </DialogActions>
    </Dialog>
    )
}

export default ModalNewPerfil

