import DeleteIcon from "@mui/icons-material/Delete"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { Button, Stack } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { exportColaboradorPDF } from "../../../Hooks/CreatePDF"
import {
	apiDeleteDocumentos,
	apiGetDocumentos,
	apiGetDocumentosPDF,
	apiPostDocumentos,
} from "../../../Services/Api_Colaborador"
import { ButtonFile } from "../../Common/Buttons"
import { DialogConfirm, ViewPDF } from "../../Common/Dialog"
import { SkeletonSquareGrid } from "../../Common/Skeleton"
import InventarioPDF from "./InventarioPDF"
import { PRIMARYCOLOR } from "#constants/Styles"

export const GetDataPDF = (idColaborador) => {
	const token = useSelector((state) => state.user.token)
	const [data, setData] = useState({
		familiares: [],
		contacto: [],
		beneficiario: [],
		autos: [],
		escolares: [],
		externos: [],
		trabajos: [],
		trayectoria: [],
		skeleton: true,
	})
	useEffect(() => {
		if (!!idColaborador) {
			apiGetDocumentosPDF(token, setData, {
				colaborador: idColaborador,
			})
		}
	}, [idColaborador, token])
	return data
}
export const Pdf = ({ colaborador }) => {
	const colaboradorData = GetDataPDF(colaborador.id)
	if (colaboradorData.skeleton)
		return (
			<SkeletonSquareGrid
				propsG={{ xs: 12, md: 12 }}
				height={"55vh"}
				propsGC={{ container: false, xs: 12 }}
			/>
		)
	return (
		<Grid2
			container
			justifyContent="center"
		>
			<Grid2
				xs={12}
				container
				justifyContent={"center"}
			>
				<Button
					color="primary"
					variant="contained"
					onClick={() =>
						exportColaboradorPDF({
							colaborador: colaborador,
							data: colaboradorData,
						})
					}
				>
					Descargar
				</Button>
			</Grid2>
			<Grid2>
				<InventarioPDF
					colaborador={colaborador}
					data={colaboradorData}
				/>
			</Grid2>
		</Grid2>
	)
}

export default Documentos

export function Documentos({ colaboradorId }) {
	const token = useSelector((state) => state.user.token)
	const [documentos, setDocumentos] = useState([])
	const [actualizar, setActualizar] = useState(true)
	const [open, setOpen] = useState(false)
	const [confirm, setConfirm] = useState(false)
	const [Skelton, setSkeleton] = useState(true)
	const actualizarNow = () => setActualizar(!actualizar)

	useEffect(() => {
		apiGetDocumentos(token, setDocumentos, { colaborador: colaboradorId }).then(
			(response) => {
				if (response.success) {
					setSkeleton(false)
				}
			}
		)
	}, [actualizar, colaboradorId, token])

	if (Skelton)
		return (
			<SkeletonSquareGrid
				propsG={{ xs: 12, md: 12 }}
				height={"55vh"}
				propsGC={{ container: false, xs: 12 }}
			/>
		)
	return (
		<Grid2
			container
			justifyContent={"left"}
		>
			<Grid2 xs={10}>
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableBody>
							{documentos.map((row) => {
								let buttons = []
								let styleButton = {
									variant: "contained",
									fullWidth: true,
								}
								if (row.link) {
									buttons.push(
										<Stack
											spacing={4}
											direction="row"
										>
											<Button
												startIcon={<VisibilityIcon />}
												color="success"
												{...styleButton}
												onClick={() => {
													setOpen({
														open: true,
														url: row.link,
														title: row.description,
													})
												}}
											>
												Ver
											</Button>
											<Button
												startIcon={<DeleteIcon />}
												{...styleButton}
												label="Subir"
												color="error"
												onClick={() =>
													setConfirm({
														open: true,
														id: row.id,
														title: row.description,
													})
												}
											>
												Eliminar
											</Button>
										</Stack>
									)
								} else
									buttons.push(
										<ButtonFile
											accept=".pdf"
											{...styleButton}
											color='primary'
											label="Subir"
											operation={(documento) => {
												let formData = new FormData()
												formData.append("colaborador", colaboradorId)
												formData.append("tipo_documento", row.tipo_documento_id)
												formData.append("documento", documento)
												apiPostDocumentos(token, formData).then((response) => {
													if (response.success) actualizarNow()
												})
											}}
										/>
									)

								return (
									<TableRow
										key={row.name}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell
											component="th"
											scope="row"
											sx={{ fontWeight: "bold" }}
										>
											{row.description}
										</TableCell>
										<TableCell
											sx={{
												color: row.obligatorio ? "red" : "#327418",
												fontWeight: "bold",
											}}
											align="right"
										>
											{row.obligatorio ? "Obligatorio" : "Opcional"}
										</TableCell>
										<TableCell align="right">{buttons}</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
					<DialogConfirm
						open={confirm.open}
						setOpen={setConfirm}
						message={`¿Estás Seguro De Borrar ${confirm.title} ?`}
						disabledButtons={confirm.disabled}
						accept={() => {
							setConfirm({ ...confirm, disabled: true })
							apiDeleteDocumentos(token, { id: confirm.id }).then(
								(response) => {
									if (response.success) {
										actualizarNow()
										setConfirm(false)
									}
								}
							)
						}}
					/>
					<ViewPDF
						title={open.title}
						open={open.open}
						setOpen={setOpen}
						pdfUrl={open.url}
					/>
				</TableContainer>
			</Grid2>
		</Grid2>
	)
}
