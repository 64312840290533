import { FormControl } from "@mui/material"
import { useEffect } from "react"
import { useForm } from "react-hook-form"

import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { InputFormGrid2, InputSelectArrayFormGrid } from "../../Common/Inputs2"

import { useDispatch, useSelector } from "react-redux"
import { apiPutColaboradorGenerales } from "../../../Services/Api_Colaborador"
import { startActualizacion } from "../../../app/actualizar"
import MX_MUNICIPIOS from "../../../assets/estados-municipios.json"
import MX_ESTADOS from "../../../assets/estados.json"
import { GT_ESTADOS, GT_MUNICIPIOS } from "./IsGuatemala"
import { InputSelectFormGrid } from "../../Common/Inputs2"
import { useState } from "react"
import { Fragment } from "react"
import IsGuatemala from "./IsGuatemala"

export default function FormDataAddress({ dataColaborador, click, setClick }) {
	const token = useSelector((state) => state.user.token)
	const dispatch = useDispatch()
	const { have } = IsGuatemala()
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
	} = useForm()

	useEffect(() => {
		if (have) dataColaborador.cp = 0
		reset(dataColaborador)
	}, [reset, dataColaborador])

	useEffect(() => {
		if (click) {
			handleSubmit(onSubmit)()
			setClick(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [click])

	const onSubmit = async (data) => {
		setClick(true)
		const ESTADOS = have ? GT_ESTADOS : MX_ESTADOS
		const MUNICIPIOS = have ? GT_MUNICIPIOS : MX_MUNICIPIOS
		if (
			!validPopulation(ESTADOS, MUNICIPIOS, data["poblacion"], data["estado"])
		) {
			setError("poblacion", {
				type: "manual",
				message: "Seleccione un población",
			})
		} else {
			let response = await apiPutColaboradorGenerales(token, data, {
				datosAddress: "",
			})
			if (response["success"]) dispatch(startActualizacion())
		}
		setClick(false)
	}
	const DATOS = [
		{
			id: "domicilio",
			label: have ? "Calle O Avenida (N°ext N°int)" : "Domicilio (N°ext N°int)",
			registerParams: { required: true },
			propsG: {},
		},
		{
			id: "colonia",
			label: have ? "Zona" : "Colonia",
			registerParams: { required: true },
			propsG: {},
		},
	]
	if (!have) {
		DATOS.push({
			id: "cp",
			label: "CP",
			registerParams: { required: true },
			propsG: {},
			type: "number",
		})
	}

	return (
		<FormControl
			key={"address"}
			sx={{ width: "100%" }}
		>
			<Grid2
				container
				spacing={2}
				columns={12}
			>
				<EstadosRegionSelectForm
					control={control}
					errors={errors}
					estado={dataColaborador["estado"]}
				/>

				{DATOS.map((dato) => (
					<InputFormGrid2
						key={dato.label}
						{...dato}
						propsG={{ xs: 12 }}
						register={register}
						error={errors}
					/>
				))}
			</Grid2>
		</FormControl>
	)
}
export function validPopulation(ESTADOS, MUNICIPIOS, population, state) {
	let populationCorrect = false

	ESTADOS.forEach((estado) => {
		if (estado.nombre === state) {
			if (MUNICIPIOS[estado.clave].includes(population))
				populationCorrect = true
		}
	})
	return populationCorrect
}

export function EstadosRegionSelectForm({ control, errors, estado }) {
	const [municipio, setMunicipio] = useState([])
	const { have } = IsGuatemala()
	const ESTADOS = have ? GT_ESTADOS : MX_ESTADOS
	const MUNICIPIOS = have ? GT_MUNICIPIOS : MX_MUNICIPIOS

	function searchMunicipio(estado) {
		ESTADOS.forEach((element) => {
			if (element.nombre === estado) {
				setMunicipio(MUNICIPIOS[element.clave])
			}
		})
	}
	useEffect(() => {
		searchMunicipio(estado)
	}, [estado])
	const DATOS_SELECT = {
		ESTADO: {
			id: "estado",
			label: have ? "Departamento" : "Estado",
			dataLabel: "nombre",
			dataID: "nombre",
			propsG: { xs: 6, md: 6 },
			rules: {
				required: { value: true, message: "Se necesita estado" },
			},
		},
		POBLACION: {
			id: "poblacion",
			label: have ? "Zona" : "Población",
			rules: {
				required: { value: true, message: "Se necesita población" },
			},
			propsG: { xs: 6, md: 6 },
		},
	}

	return (
		<Fragment>
			<InputSelectFormGrid
				{...DATOS_SELECT.ESTADO}
				data={ESTADOS}
				control={control}
				error={errors}
				onChange={(e) => {
					let estadoSelect = e.target.value
					searchMunicipio(estadoSelect)
				}}
			/>
			<InputSelectArrayFormGrid
				{...DATOS_SELECT.POBLACION}
				data={municipio}
				control={control}
				error={errors}
			/>
		</Fragment>
	)
}
