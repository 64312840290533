import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { useForm } from "react-hook-form"

//API
import { getApiCursos } from "../../../Services/Api_cursos"

//Mis Componentes
import { ButtonGrid2 } from "../../Common/Buttons"
import ListaDrop from "./ListaDrop"
import { AutocompleteGrid } from "../../Common/Inputs"
import { SkeletonSquare } from "../../Common/Skeleton"

//Material
import { FormControl, Box } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import Typography from "@mui/material/Typography"

export default function CursosPlanAnualSelection({ areas = [] }) {
	const token = useSelector((state) => state.user.token)
	const colaborador = useSelector((state) => state.user.colaborador.id)

	//Control datos------------------------------------->
	const [db_cursos, set_DBCurso] = useState([]) //Curso
	const [skeleton, setSkeleton] = useState(false) //Curso

	//control cursos----------------------------------->
	const [value2, setValue2] = React.useState(null)
	const [actualizar, setActualizar] = React.useState(null)
	const [dataDrop, setDataDrop] = React.useState([])
	const [fallo, setFallo] = React.useState(false)

	//Control Vista Selección--------------------------->

	useEffect(() => {
		getApiCursos(
			"cursos",
			token,
			set_DBCurso,
			{
				orden: "",
			},
			setSkeleton
		)
	}, [token])

	useEffect(() => {
		setFallo(false)
	}, [value2])

	//
	const { handleSubmit } = useForm()

	const onSubmit = () => {
		let datos = {}
		datos.colaborador_creador = colaborador
		if (value2) {
			datos.curso = value2.id
			set_DBCurso(
				db_cursos.filter((curso) => {
					if (curso.id !== value2.id) return curso
					return 0
				})
			)

			setDataDrop([...dataDrop, value2])
		} else setFallo(true)

		setValue2(null)
	}

	return skeleton ? (
		<React.Fragment>
			{!(dataDrop.length === 12) && (
				<Grid
					container
					spacing={2}
					justifyContent="center"
					alignItems="center"
				>
					<Grid
						item
						xs={10}
						justifyContent="center"
						alignItems="center"
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<FormControl sx={{ width: "100%" }}>
									<div>
										<Typography
											variant="poster"
											component="h2"
											align="center"
										>
											Selecciona Curso
										</Typography>
									</div>
									<Grid
										container
										spacing={2}
										justifyContent="center"
										alignItems="center"
									>
										<React.Fragment>
											<AutocompleteGrid
												label="Cursos"
												data={db_cursos}
												dataLabel="curso"
												operation={(event, newValue) => {
													setValue2(newValue)
												}}
												md={12}
												xl={12}
											/>
										</React.Fragment>
										<ButtonGrid2
											label="Agregar curso"
											onClick={onSubmit}
											disabled={false}
										/>
									</Grid>
								</FormControl>
							</Box>
						</form>
					</Grid>
				</Grid>
			)}
			<ListaDrop
				areas={areas}
				items={dataDrop}
				setItems={setDataDrop}
				set_DBCurso={set_DBCurso}
				db_cursos={db_cursos}
				actualizar={actualizar}
				setActualizar={setActualizar}
			/>
		</React.Fragment>
	) : (
		<SkeletonSquare
			width={"100%"}
			size={12}
		/>
	)
}
