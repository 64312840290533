import { useState } from "react"
import { toast } from "react-toastify"
// #
import { api_rh_put } from "#api/API_RH"
import { MasterDialog } from "#common/Dialog/Dialog"
import { TextInfoField } from "#common/Texto"
import { TERMINATION } from "#constants/StatusRequest "
// Material
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
// Componentes
import * as TEXT from "../TextBaja"
import { ApproveTermination } from "./ApproveTermination"
import RejectTermination from "./RejectTermination"

function DetailBaja({ open, setOpen, baja, reload }) {
	const { id, request_date, perfil_puesto, reason } = baja
	const { no_empleado, name_collaborator, name_request_collaborator } = baja
	const { type, termination_date, termination_status, approval_date } = baja
	const { termination_type_1, termination_type_2, termination_type_3 } = baja
	const { termination_status_id } = baja

	const [selectedDataSend, setSelectedDataSend] = useState(null)
	const [sendData, setSendData] = useState(null)

	const info = [
		{ xs: 4, campo: TEXT.NO_EMPLEADO, valor: no_empleado },
		{
			xs: 4,
			campo: TEXT.NAME_COLLABORATOR,
			valor: name_collaborator,
		},
		{ xs: 4, campo: TEXT.PERFIL_PUESTO, valor: perfil_puesto },
		{
			xs: 4,
			campo: TEXT.NAME_REQUEST_COLLABORATOR,
			valor: name_request_collaborator,
		},
		{ xs: 4, campo: TEXT.TERMINATION_STATUS, valor: termination_status },
		{ xs: 4, campo: TEXT.TYPE_BAJA, valor: type },

		{ xs: 4, campo: TEXT.REQUEST_DATE, valor: request_date },
		{ xs: 4, campo: TEXT.TERMINATION_DATE, valor: termination_date },
		{ xs: 4, campo: TEXT.APPROVAL_DATE, valor: approval_date },

		{ xs: 12, campo: TEXT.REASON, valor: reason },
		{
			xs: 12,
			customRender: termination_type_1 ? (
				<Divider textAlign="left">{TEXT.DIVIDER} </Divider>
			) : null,
		},
		{ xs: 12, campo: TEXT.TYPE_1, valor: termination_type_1, noWrap: false },
		{ xs: 12, campo: TEXT.TYPE_2, valor: termination_type_2, noWrap: false },
		{ xs: 12, campo: TEXT.TYPE_3, valor: termination_type_3, noWrap: false },
	]
	let buttonsDialog = null
	const sendResponse = async () => {
		if (selectedDataSend === null) return
		let response = null

		if (selectedDataSend === true) {
			const { type1, type2, type3 } = sendData
			for (const type_n in sendData) {
				if (sendData[type_n] === "") {
					if (type_n === "type1") toast.error(TEXT.ERROR_TYPE_1)
					else if (type_n === "type2") toast.error(TEXT.ERROR_TYPE_2)
					else if (type_n === "type3") toast.error(TEXT.ERROR_TYPE_3)
					return
				}
			}
			const data = {
				id,
				termination_status: TERMINATION.VOBO_RH,
				termination_type_1: type1,
				termination_type_2: type2,
				termination_type_3: type3,
			}
			response = await api_rh_put("baja", data)
		} else {
			if (sendData === null || sendData.trim() === "") {
				toast.error(TEXT.ERROR_REASON_REJECTION)
				return
			}
			const data = {
				id,
				termination_status: TERMINATION.RECHAZADA,
				reason_rejection: sendData,
			}
			response = await api_rh_put("baja", data)
		}

		if (response) {
			reload()
			setOpen(false)
			setSelectedDataSend(null)
		}
	}
	const showApprove = termination_status_id !== TERMINATION.SOLICITADA

	if (selectedDataSend !== null)
		buttonsDialog = [
			{
				label: TEXT.SEND_BUTTON,
				onClick: sendResponse,
			},
		]
	return (
		<MasterDialog
			setOpen={setOpen}
			open={open}
			title={TEXT.TITLE_DIALOG}
			buttons={buttonsDialog}
			maxWidth={"md"}
			closeDialog={() => {
				setSelectedDataSend(null)
				setSendData(null)
			}}
		>
			<Grid2
				container
				rowSpacing={3}
				justifyItems={"center"}
				justifySelf={"center"}
			>
				{info.map((item, index) => {
					let contenido = null
					if (item?.customRender) contenido = item.customRender
					else if (item.valor)
						contenido = (
							<TextInfoField
								whiteSpace="pre-line"
								{...item}
								separador={"\n"}
							/>
						)
					else return null
					return (
						<Grid2
							xs={item["xs"]}
							key={item.campo + index}
						>
							{contenido}
						</Grid2>
					)
				})}
				<Grid2 xs={12}>
					<ButtonsSelected
						hidden={showApprove || selectedDataSend !== null}
						onClick={setSelectedDataSend}
					/>
					<ApproveTermination
						hidden={showApprove || selectedDataSend !== true}
						onChange={(value) => {
							setSendData(value)
						}}
					/>
					<RejectTermination
						hidden={showApprove || selectedDataSend !== false}
						onChange={(value) => {
							setSendData(value)
						}}
					/>
				</Grid2>
			</Grid2>
		</MasterDialog>
	)
}

export default DetailBaja

function ButtonsSelected({ hidden = false, onClick = () => {} }) {
	if (hidden) return null
	return (
		<Stack
			direction={"row"}
			spacing={5}
			justifyContent={"center"}
		>
			<Button
				variant="contained"
				onClick={() => onClick(false)}
			>
				Rechazar
			</Button>
			<Button
				variant="contained"
				onClick={() => onClick(true)}
			>
				Aceptar
			</Button>
		</Stack>
	)
}
