import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"

// Api
import { api_rh_get } from "#api/API_RH"

// Icons
import AddCircleIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import HandymanIcon from "@mui/icons-material/Handyman"

// Material
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import TextField from "@mui/material/TextField"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"

export const VacanteHerramientas = ({
	expanded,
	handleChangePanel,
	setListTools,
	listTools,
}) => {
	const [herramientas, setHerramientas] = useState([])
	const [seleccionadas, setSeleccionadas] = useState([])
	const [seleccionada, setSeleccionada] = useState(null)

	useEffect(() => {
		api_rh_get("herramientas_trabajo", { setData: setHerramientas })
	}, [])

	useEffect(() => {
		if (listTools.length && herramientas.length) {
			const filter = (id) => herramientas.filter((f) => f?.id === id)
			let x = listTools.map((lt) => filter(lt?.herramientas_trabajo_id).pop())
			setSeleccionadas(x)
		}
	}, [herramientas, listTools])

	useEffect(() => {
		if (seleccionadas.length) setListTools(seleccionadas.map((s) => s?.id))
	}, [seleccionadas, setListTools])

	const addItem = () => {
		if (seleccionada)
			if (!seleccionadas.includes(seleccionada)) {
				setSeleccionadas([seleccionada, ...seleccionadas])
				setSeleccionada(null)
			} else toast.error("herramienta ya agregada", { toastId: "existH" })
		else toast.error("herramienta no valida", { toastId: "invalidateH" })
	}

	const delItem = (id) => {
		const newSelect = seleccionadas.filter((item) => item?.id !== id)
		setSeleccionadas(newSelect)
	}

	const toolsFree = useMemo(
		() =>
			herramientas.filter(
				(herramienta) => !seleccionadas.includes(herramienta)
			),
		[herramientas, seleccionadas]
	)

	return (
		<Accordion
			expanded={expanded === "panelHerramientas"}
			onChange={handleChangePanel("panelHerramientas")}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel-herramientas"
				id="panel-herramientas"
			>
				<Box
					component="b"
					sx={{ fontSize: 20, color: "#1d3454" }}
				>
					Herramientas de Trabajo
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Grid2
					container
					spacing={2}
				>
					<Grid2 xs={10}>
						<InputAutocomplete
							label="Herramienta"
							value={seleccionada}
							options={toolsFree}
							setOption={setSeleccionada}
							getOptionLabel={(option) => {
								return `${option?.description} : [ ${option?.area_description} ]`
							}}
						/>
					</Grid2>
					<Grid2 md={2}>
						<Grid md={2}>
							<IconButton
								aria-label="delete"
								sx={{ mt: 1 }}
								onClick={addItem}
							>
								<AddCircleIcon
									color="success"
									fontSize="large"
								/>
							</IconButton>
						</Grid>
					</Grid2>
					<Grid md={12}>
						<Grid
							xs={12}
							sx={{ paddingX: 3 }}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid md={12}>
									<List dense={false}>
										{seleccionadas.map((item, index) => {
											return (
												<Box key={item.id}>
													<ListItem
														secondaryAction={
															<IconButton
																edge="end"
																onClick={() => delItem(item.id)}
															>
																<DeleteIcon color="error" />
															</IconButton>
														}
													>
														<ListItemIcon>
															<HandymanIcon color="primary" />
														</ListItemIcon>
														<ListItemText
															primary={`${item?.description} : [ ${item?.area_description} ]`}
														/>
													</ListItem>
													<Divider />
												</Box>
											)
										})}
									</List>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid2>
			</AccordionDetails>
		</Accordion>
	)
}

const InputAutocomplete = ({
	label = "",
	optionValue = null,
	optionLabel = "label",
	valuesIsId = true,
	setOption = () => {},
	onChange = (event, newValue) => {
		if (newValue) setOption(newValue[optionValue] || newValue)
		else setOption(null)
	},
	gridProps,
	textFieldProps,
	options,
	value,
	...props
}) => {
	const selectedValues = useMemo(() => {
		if (value == null) return null

		const selected = options.filter((v) => {
			if (optionValue && value) return v[optionValue] === value
			return v === value
		})

		if (props?.multiple) return selected
		else return selected[0]
	}, [optionValue, options, props?.multiple, value])

	return (
		<Autocomplete
			id="InputAutocomplete"
			disablePortal
			value={selectedValues}
			onChange={onChange}
			filterSelectedOptions
			filterOptions={(x) => x}
			options={options}
			{...props}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						label={label}
					/>
				)
			}}
		/>
	)
}
