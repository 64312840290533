import dayjs from "dayjs"
// Constants
import { VACATION as VACATION_STATUS } from "#constants/StatusRequest "
import { PERFILES } from "#constants/Strings"

// Common
import { ButtonIcon } from "#common/Buttons"
import { dateStringFormatting, HasPermission } from "#common/Funciones"

//componentes
import { useVacationContext } from "./UseContextVacation"

// Material
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded"
import EventAvailableIcon from "@mui/icons-material/EventAvailable"
import EventBusyIcon from "@mui/icons-material/EventBusy"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

const { RH } = PERFILES
export default function ColaboradorCard({ ...props }) {
	const { photo, colaborador_name, disponibles } = props
	const { days_taken, departamento, solicitud } = props
	const { days, date_start, date_end } = solicitud
	const StyleSubText = {
		variant: "subtitle1",
		color: "text.secondary",
		component: "div",
	}

	// let contenido = null

	// //Vacaciones activas
	// if (solicitud) {
	// 	let { date_start, date_end, status_id } = solicitud

	// 	if (status_id === VACATION_STATUS.APPROVE) {
	// 		date_start = dayjs(date_start, "YYYY-M-D").format("DD/MM/YYYY")
	// 		date_end = dayjs(date_end, "YYYY-M-D").format("DD/MM/YYYY")

	// 		contenido = (
	// 			<Stack
	// 				direction={"row"}
	// 				spacing={2}
	// 				alignItems={"flex-start"}
	// 			>
	// 				<Typography
	// 					variant="subtitle1"
	// 					color="text.secondary"
	// 					component="div"
	// 					alignItems={"center"}
	// 				>
	// 					<strong>{date_start}</strong> a <strong>{date_end}</strong>
	// 				</Typography>
	// 			</Stack>
	// 		)
	// 	}
	// }
	console.log(solicitud)
	return (
		<Card
			sx={{
				display: "flex",
				alignItems: "center",
				height: "100%",
				width: {
					md: 550, // Para md y arriba
				},
			}}
		>
			<CardMedia
				component="img"
				sx={{ width: 150, height: 150 }}
				image={photo}
				alt="colaborador"
				onError={(e) => {
					e.target.onerror = null
					e.target.src =
						"https://imagenespublicas.s3.us-east-2.amazonaws.com/usuario.webp"
				}}
			/>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<CardContent sx={{ flex: "1 0 auto" }}>
					<Typography
						component="div"
						variant="h5"
					>
						{colaborador_name}
					</Typography>
					<Typography {...StyleSubText}>{departamento}</Typography>
					<Typography {...StyleSubText}>
						Dias solicitados: <strong>{days}</strong>
					</Typography>

					<Stack
						direction={"row"}
						spacing={2}
						alignItems={"flex-start"}
					>
						<Typography
							{...StyleSubText}
							alignItems={"center"}
						>
							<strong>{dateStringFormatting(date_start)}</strong>
						</Typography>
						<Typography
							{...StyleSubText}
							alignItems={"center"}
						>
							a{" "}
						</Typography>

						<Typography
							{...StyleSubText}
							alignItems={"center"}
						>
							<strong>{dateStringFormatting(date_end)}</strong>
						</Typography>
					</Stack>
				</CardContent>
				<CardActions>
					<ButtonVacations {...props} />
				</CardActions>
			</Box>
		</Card>
	)
}

const ButtonVacations = ({ solicitud, colaborador_name, disponibles }) => {
	const { setOpenApprove, setSolicitud, setOpenRequest } = useVacationContext()
	const { SOLICITADA, APPROVE, RECHAZADA, CANCELADAS } = VACATION_STATUS
	const noValidadas = [RECHAZADA, CANCELADAS]
	const is_rh = HasPermission(RH)
	if (!solicitud) return null

	let { status_id } = solicitud
	let buttons = []

	if (is_rh && !noValidadas.includes(status_id)) {
		buttons.push(
			<ButtonIcon
				key="Cancelar"
				title="Cancelar"
				icon={<EventBusyIcon sx={{ fontSize: 30, color: "red" }} />}
				onClick={() => {
					setOpenApprove(true)
					setSolicitud({ colaborador_name, cancelar: true, ...solicitud })
				}}
			/>,
		)
	}

	if (status_id === SOLICITADA) {
		buttons.push(
			<ButtonIcon
				key="aceptar"
				title="Aceptar"
				icon={<EventAvailableIcon sx={{ fontSize: 30, color: "green" }} />}
				onClick={() => {
					setOpenApprove(true)
					setSolicitud({ colaborador_name, ...solicitud })
				}}
			/>,
		)
	}
	if (status_id === APPROVE && is_rh) {
		buttons.push(
			<ButtonIcon
				key="editar"
				title="Editar"
				icon={<EditNoteRoundedIcon sx={{ fontSize: 30 }} />}
				onClick={() => {
					setSolicitud({ colaborador_name, disponibles, ...solicitud })
					setOpenRequest(true)
				}}
			/>,
		)
	}

	return (
		<Stack
			direction={"row"}
			spacing={3}
		>
			{buttons}
		</Stack>
	)
}
