import  React from  'react';
import { useSelector } from 'react-redux'
import Login from './Pages/Login';
import Menu from './Components/Common/Menu';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './App.css';
import MenuPrincipal from './Components/menu/Menu';

function App() {
    const token = useSelector((state) => state.user.token)
    const fechaLogin = localStorage.getItem('fechaLogin');
    var expired = true;
    if (fechaLogin != null){
        const login = new Date(fechaLogin);
        const currentDate = new Date();
        const differenceInMilliseconds = currentDate - login;
        const differenceInHours = differenceInMilliseconds / 3600000;
        expired = differenceInHours > 20;
    }
    if (token == null || expired) 
        return <GoogleOAuthProvider clientId="951766540445-5psqm61o3q5fv285oks5s3deo4mdji0j.apps.googleusercontent.com">
                    <Login />
                </GoogleOAuthProvider>;
    return <MenuPrincipal />
}

export default App;