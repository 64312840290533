import {useEffect, useState} from 'react'
import "@react-pdf-viewer/core/lib/styles/index.css"
import { Viewer ,Worker} from "@react-pdf-viewer/core"
import { useDispatch, useSelector } from 'react-redux'
import { changeTitle } from '../app/pageSlice'
import { getDocumentacion } from '../Services/Api'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LoadViewTable } from '../Components/Common/LoadingView'

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Documentacion = () => {
    const token = useSelector(state => state.user.token)
    const dispatch = useDispatch()
    const [tabSelect, setTabSelect] = useState(0);
    const [documentos, setDocumentos] = useState(null)
    const [loading, setLoading] = useState(true)

  const handleChange = (event, newValue) => {
    setTabSelect(newValue);
  };

    useEffect(() => {
        dispatch(changeTitle("Documentos Lubtrac"))
        getDocumentacion(token).then(response => {
            setDocumentos(response)
        }).catch(err => alert("Error al obtener los documentos")
        ).finally(() => setLoading(false))


    }, [])

    const PdfViewer = ({url}) => {
        return (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js" >
                    <div
                        style={{border: '1px solid rgba(0, 0, 0, 0.3)',height: '750px'}}>
                        <Viewer 
					        defaultScale={1.5}
                            fileUrl={url} 
                        />
                    </div>
                </Worker>
        )
    }
    if(documentos === null && loading)
        return <LoadViewTable/>
    else
        return (<>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabSelect} onChange={handleChange} aria-label="Documentos">
                        <Tab label="Reglamento Interior" {...a11yProps(0)} wrapped/>
                        <Tab label="Código de ética (TRACSA)" {...a11yProps(1)} wrapped/>
                        <Tab label="Código de ética (LUBTRAC)" {...a11yProps(2)} wrapped/>
                        <Tab label="Kick Off Reconocimientos" {...a11yProps(3)} wrapped/>
                        <Tab label="Política Anticorrupción" {...a11yProps(4)} wrapped/>
                        <Tab label="Política Uso de vehiculos" {...a11yProps(5)} wrapped/>
                        <Tab label="Informe de resultados NOM 035" {...a11yProps(6)} wrapped />
                        <Tab label="Estatutos de Caja de Ahorro" {...a11yProps(7)} wrapped />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabSelect} index={0}>
                <PdfViewer url={documentos.url_reglamento}/>
                </CustomTabPanel>
                <CustomTabPanel value={tabSelect} index={1}>
                    <PdfViewer url={documentos.url_codigo}/>
                </CustomTabPanel>
                 <CustomTabPanel value={tabSelect} index={2}>
                    <PdfViewer url={documentos.url_codigo_lubtrac}/>
                </CustomTabPanel>
                 <CustomTabPanel value={tabSelect} index={3}>
                    <PdfViewer url={documentos.url_kick_off}/>
                </CustomTabPanel> 
                <CustomTabPanel value={tabSelect} index={4}>
                    <PdfViewer url={documentos.url_anticorrupcion}/>
                </CustomTabPanel>
                <CustomTabPanel value={tabSelect} index={5}>
                    <PdfViewer url={documentos.url_vehiculos}/>
                </CustomTabPanel> 
                <CustomTabPanel value={tabSelect} index={6}>
                    <PdfViewer url={documentos.url_nom_35}/>
                </CustomTabPanel> 
                <CustomTabPanel value={tabSelect} index={7}>
                    <PdfViewer url={documentos.url_caja_ahorro}/>
                </CustomTabPanel> 
                </>  );
}

export default Documentacion