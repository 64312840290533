import { useState, createContext} from 'react'
import ModalNewCandidato from './Candidatos/ModalNewCandidato';
import ModalVoBo from './ModalVoBo'
import ModalHistorial from './ModalHistorial';
import TableCandidatos from './Candidatos/TableCandidatos';
import { DataItem, ListCompet } from '../Common/DataDisplay';
import { Grid, Paper,  Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material/';
import ButtonsVacante from './ButtonsVacante';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from "dayjs"
import { ListTools } from 'Components/PerfilesPuesto/DetallePuesto';

export const ContextVacante = createContext()

const DetalleVacante = ({ dataVacante,days_passed }) => {
    const [expanded, setExpanded] = useState('panelCandidatos'); // PANEL
    const [openModalCand,setOpenModalCand] = useState(false) 
    const [openModalVoBo,setOpenModalVoBo] = useState(false) 
    const [openModalHist,setOpenModalHist] = useState(false) 
    const{herramientas=[]}=dataVacante
    
    const handleChangePanel = (panel) => (event, isExpanded) => { setExpanded(isExpanded ? panel : false); };

    function currencyFormat(num) { return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
    let fecha_solicitud= dataVacante.fecha_solicitud
    let fecha_rh= dataVacante.fecha_rh
    let hoy= new Date()
    fecha_solicitud= dayjs(fecha_solicitud, "D-M-YYYY")
    fecha_rh= dayjs(fecha_rh, "D-M-YYYY")
    let days_trascurridos=parseInt((hoy-fecha_solicitud)/(24 * 60 * 60 * 1000))
    let days_trascurridos_rh= parseInt((hoy-fecha_rh)/(24 * 60 * 60 * 1000))
 

    let reception_rh_passage=<DataItem label={'Dias RH Recepción'} value={days_trascurridos_rh??"N/A"} />
    if(days_passed && isNaN(days_trascurridos_rh))
        reception_rh_passage=null
 
    return (
            <Grid container component={Paper} elevation={12} id={"pdf"}>
                <ModalNewCandidato open={openModalCand} setOpen={setOpenModalCand} vacanteID={dataVacante.id}/>
                <ModalVoBo open={openModalVoBo} setOpen={setOpenModalVoBo} autorizaciones={dataVacante.vacante_autorizacion} idVacante={dataVacante.id}/>
                <ModalHistorial open={openModalHist} setOpen={setOpenModalHist} historial={dataVacante.vacante_historial}/>
                <Grid item container md={12} sx={{p:4,pb:1,pr:0}}>
                    <DataItem label={'Vacante'} value={'#'+(dataVacante.id-125)} size={1} fontSizeLabel={18} fontSizeValue={18}/>
                    <DataItem label={'Perfil Vacante'} value={dataVacante.perfil+" ("+dataVacante.area+")"} size={5} fontSizeLabel={18} fontSizeValue={18}/>
                    <DataItem label={'Status'} value={dataVacante.status} size={3} fontSizeLabel={18} fontSizeValue={18} fontColor={'#2e7d32'} fontBold={'bold'}/>
                    <Grid item md={3} sx={{ pt: 1.5 }}> <ButtonsVacante statusVacante={dataVacante.status_id} setOpenCandidato={setOpenModalCand} setOpenModalVoBo={setOpenModalVoBo} setOpenModalHist={setOpenModalHist} vacante={dataVacante.id} /> </Grid>
                    <DataItem label={'Solicitante'} value={dataVacante.jefe_nombre+' '+dataVacante.jefe_apellido} />
                    <DataItem label={'Sucursal'} value={dataVacante.sucursal} />
                    <DataItem label={'Tipo de vacante'} value={dataVacante.tipo_vacante} />
                    {dataVacante.colaborador !== null?
                        <DataItem label={dataVacante.tipo_vacante == "Reemplazo"?'¿Por Quien?':'¿Para Quien?'} value={dataVacante.por_quien_nombre+' '+dataVacante.por_quien_apellido} />:
                        <Grid item md={3}></Grid>
                    }
                    <DataItem label={'Fecha solicitud'} value={dataVacante.fecha_solicitud == null ? 'N/A':dataVacante.fecha_solicitud} />
                    <DataItem label={'Fecha autorización'} value={dataVacante.fecha_autorizacion == null ? 'N/A':dataVacante.fecha_autorizacion} />
                    <DataItem label={'Fecha recepción RH'} value={dataVacante.fecha_rh == null ? 'N/A':dataVacante.fecha_rh} />
                    {days_passed &&<DataItem label={'Dias en proceso'} value={days_trascurridos} />}
                    {reception_rh_passage}
                </Grid>
                <Grid item xs={12} sx={{p:3,pt:0}}>
                    <Accordion expanded={expanded === 'panelSueldo'} onChange={handleChangePanel('panelSueldo')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header" >
                        <Box component="b" sx={{fontSize:20,color:"#1d3454" }}> Sueldo </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <DataItem label={'Sueldo mensual'} value={currencyFormat(parseFloat(dataVacante.sueldo_mensual))} />
                                <DataItem label={'Bono trimestral'} value={dataVacante.bono_trimestral == 0 ? 'N/A' : currencyFormat(parseFloat(dataVacante.bono_trimestral)) } />
                                <DataItem label={'Garantia'} value={dataVacante.garantia == 0 ? 'N/A' : currencyFormat(parseFloat(dataVacante.garantia))} />
                                <DataItem label={'Duración'} value={dataVacante.duracion} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} sx={{p:3,pt:0}}>
                    <Accordion expanded={expanded === 'panelResponsabilidades'} onChange={handleChangePanel('panelResponsabilidades')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header" >
                        <Box component="b" sx={{fontSize:20,color:"#1d3454" }}> Caracteristicas de la vacante </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <ListCompet listItem={dataVacante.vacante_caracteristica.filter(carac => carac.tipo == 'Responsabilidades')} IconList={ThumbUpIcon} listName={"Responsabilidades"}/> 
                                <ListCompet listItem={dataVacante.vacante_caracteristica.filter(carac => carac.tipo == 'Educación')} IconList={ThumbUpIcon} listName={"Educación"}/> 
                                <ListCompet listItem={dataVacante.vacante_caracteristica.filter(carac => carac.tipo == 'Experiencia')} IconList={ThumbUpIcon} listName={"Experiencia"}/> 
                                <ListCompet listItem={dataVacante.vacante_caracteristica.filter(carac => carac.tipo == 'Adicionales')} IconList={ThumbUpIcon} listName={"Adicionales"}/> 
                                <ListTools listItem={herramientas} listName={"Herramientas de Trabajo"}/>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            { dataVacante.status_id < 3  ?  <></>:
                <Grid item xs={12} sx={{p:3,pt:0}}>
                    <Accordion expanded={expanded === 'panelCandidatos'} onChange={handleChangePanel('panelCandidatos')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header" >
                        <Box component="b" sx={{fontSize:20,color:"#1d3454" }}> Candidatos ({dataVacante.vacante_candidato.length})</Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item xs={12} sx={{p:3,pt:0}}> 
                            <ContextVacante.Provider value={dataVacante}>
                                <TableCandidatos candidatos={dataVacante.vacante_candidato}/> 
                            </ContextVacante.Provider>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }
            </Grid>     
    )
}

export default DetalleVacante