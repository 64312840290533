import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import EventAvailableIcon from "@mui/icons-material/EventAvailable"
import FolderSharedIcon from "@mui/icons-material/FolderShared"
import HistoryIcon from "@mui/icons-material/History"
import HowToRegIcon from "@mui/icons-material/HowToReg"
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox"
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech"
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material/"
import { Fragment, useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { OPCIONESCANDIDATO, PERFILES } from "../../../Constants/Strings"
import { TABLEHEADER, TABLEROW } from "../../../Constants/Styles"
import { aprobarCandidato } from "../../../Services/Api"
import { reloadDataVacante } from "../../../app/pageSlice"
import ModalNewColaborador from "../../Colaboradores/ModalNewColaborador"
import { ButtonIcon } from "../../Common/Buttons"
import { hasPermission } from "../../Common/Funciones"
import { ContextVacante } from "../DetalleVacante"
import ModalAgendar from "./ModalAgendar"
import ModalConfirm from "./ModalConfirm"
import ModalDocumentacion from "./ModalDocumentacion"
import ModalHistorialCandidato from "./ModalHistorialCandidato"
//icons 
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { notificationPromise } from "../../../Services/Api_Call"
import DialogNewColaborador from "Components/Colaboradores/DialogNewColaborador"
const TableCandidatos = ({ candidatos }) => {
	const [openHistorial, setOpenHistorial] = useState(false)
	const [openDocum, setOpenDocum] = useState(false)
	const [openConfirm, setOpenConfirm] = useState(false)
	const [openAgendar, setOpenAgendar] = useState(false)
	const [openColabo, setOpenColabo] = useState(false)
	const [disabledBtn, setDisabledBtn] = useState(false)
	const [candidato, setCandidato] = useState(null)
	const [opcCand, setOpcCand] = useState({})
	const [dataCandidato, setDataCandidato] = useState({})
	const vacante = useContext(ContextVacante)

	const profiles = useSelector((state) => state.user.profiles)
	const token = useSelector((state) => state.user.token)
	const dispatch = useDispatch()

	candidatos = candidatos == null ? [] : candidatos


	const openModalHistorial = (candidato) => {
		setCandidato(candidato)
		setOpenHistorial(true)
	}
	
	const openModalDocumentation = (candidato) => {
		setCandidato(candidato)
		setOpenDocum(true)
	}
	
	const openModalAgendar = (candidato, opcion) => {
		setOpcCand(opcion)
		setCandidato(candidato)
		setOpenAgendar({open:true})
	}
	const openModalAgendarFile = (candidato, option,file) => {
		setOpcCand(option)
		setCandidato(candidato)
		setOpenAgendar({open:true,file})
	}
	
	//Control del modal ModalConfirm
	const openModalConfirmation = (candidato, option) => {
		setOpcCand(option)
		setCandidato(candidato)
		setOpenConfirm(true)
	}

	const  cambioStatus = async(observaciones,file=null) => {
		setDisabledBtn(true)
		aprobarCandidato(
			token,
			candidato.id,
			opcCand.siguienteStatus,
			null,
			observaciones,file
		)
			.then((response) => {
				 toast.success(opcCand.alerta)
				dispatch(reloadDataVacante())
			})
			.catch((err) => alert("Hubo un error al cambiar el status del candidato"))
			.finally(() => setDisabledBtn(false))
	}

	const agendar = (fecha,file) => {
		setDisabledBtn(true)
		aprobarCandidato(token, candidato.id, opcCand.siguienteStatus, fecha,"",file)
			.then((response) => {
				 toast.success(opcCand.alerta)
				// alertA(opcCand.alerta)
				dispatch(reloadDataVacante())
			})
			.catch((err) => toast.error("Hubo un error al cambiar el status del candidato"))
			.finally(() => setDisabledBtn(false))
	}

	const nuevoColaborador = (candidato) => {
		console.log(candidato)
		let dataVacante = JSON.parse(JSON.stringify(vacante))
		setDataCandidato(candidato.id)
		setOpenColabo(true)
	}
	const CANDIDATOS_STATUS = {
		Filtros: "1",
		Agendar: "2", //psychometrics
		Agendado: "3",
		Entrevistado: "4", //carta oferta y si es reparto certificado_manejo
		Descartado: "5",
		Final: "6",
		Ingresar: "7",
		Colaborador: "8",
		Propuesta: "9",
	}

	const BUTTONS = {
		[CANDIDATOS_STATUS.Filtros]: {
			permisos: hasPermission(profiles, [PERFILES.RH]), //*["RH"],
			icon: <CheckCircleIcon color="success" />,
			title: OPCIONESCANDIDATO.PORAGENDAR.tooltip,
			onClick: (candidato) =>
				openModalConfirmation(candidato, OPCIONESCANDIDATO.PORAGENDAR),
		},

		[CANDIDATOS_STATUS.Agendar]: {
			permisos: true,
			icon: <CalendarMonthIcon color="success" />,
			title: OPCIONESCANDIDATO.AGENDAR.tooltip,
			onClick: (candidato) =>
			openModalAgendarFile(candidato, OPCIONESCANDIDATO.AGENDAR,"Psicometricos"),
		},

		[CANDIDATOS_STATUS.Agendado]: {
			permisos: true,
			icon: <HowToRegIcon color="success" />,
			title: OPCIONESCANDIDATO.ENTREVISTADO.tooltip,
			onClick: (candidato) =>
				openModalConfirmation(candidato, OPCIONESCANDIDATO.ENTREVISTADO),
		},

		[CANDIDATOS_STATUS.Entrevistado]: {
			permisos: true,
			icon: <MilitaryTechIcon color="success" />,
			title: OPCIONESCANDIDATO.CANDIDATOFINAL.tooltip,
			onClick: (candidato) =>
				openModalConfirmation(candidato, OPCIONESCANDIDATO.CANDIDATOFINAL),
		},

		[CANDIDATOS_STATUS.Propuesta]: {
			permisos: hasPermission(profiles, [PERFILES.RH]), // ["RH", "Admin"],
			icon: <EventAvailableIcon color="success" />,
			title: OPCIONESCANDIDATO.PROPUESTA.tooltip,
			onClick: (candidato) =>
			openModalAgendar(candidato, OPCIONESCANDIDATO.AGENDARINGRESO),
		},
		[CANDIDATOS_STATUS.Final]: {
			permisos: hasPermission(profiles, [PERFILES.RH]), // ["RH", "Admin"],
			icon: <RequestQuoteIcon color="success" />,
			title: OPCIONESCANDIDATO.AGENDARINGRESO.tooltip,
			onClick: (candidato) =>
			openModalConfirmation(candidato, OPCIONESCANDIDATO.PROPUESTA),
		},

		[CANDIDATOS_STATUS.Ingresar]: {
			permisos: hasPermission(profiles, [PERFILES.RH]), // ["RH", "Admin"],
			icon: <MeetingRoomIcon color="success" />,
			title: OPCIONESCANDIDATO.INGRESO.tooltip,
			onClick: (candidato) => nuevoColaborador(candidato),
		},

		// [CANDIDATOS_STATUS.propuesta]: {
		// 	permisos: hasPermission(profiles, [PERFILES.RH]), // ["RH", "Admin"],
		// 	icon: <EventAvailableIcon color="" />,
		// 	title: "Subir propuesta ",
		// 	onClick: (candidato) => nuevoColaborador(candidato),
		// },
	}

	return (
		<Fragment>
			
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={TABLEHEADER}>Nombre</TableCell>
							<TableCell sx={TABLEHEADER}>Correo</TableCell>
							<TableCell sx={TABLEHEADER}>Telefono</TableCell>
							<TableCell sx={TABLEHEADER}>Status</TableCell>
							<TableCell sx={TABLEHEADER}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{candidatos.map((candidato, index) => {
							let button = false
							let buttonRechazar = false
							let buttonShowDocument = (
								<ButtonIcon
									title="Ver Documentación"
									color="primary"
									onClick={() => openModalDocumentation(candidato)}
									icon={<FolderSharedIcon />}
								/>
							)
							let buttonShowHistory = (
								<ButtonIcon
									title="Historial del candidato"
									color="primary"
									onClick={() => openModalHistorial(candidato)}
									icon={<HistoryIcon />}
								/>
							)

							if (BUTTONS[candidato.status]?.permisos)
								button = (
									<ButtonIcon
										{...BUTTONS[candidato.status]}
										onClick={() => BUTTONS[candidato.status].onClick(candidato)}
									/>
								)
							if (candidato.status !== 5 && candidato.status !== 8)
								buttonRechazar = (
									<ButtonIcon
										title={OPCIONESCANDIDATO.RECHAZAR.tooltip}
										icon={<IndeterminateCheckBoxIcon color="error" />}
										onClick={() =>
											openModalConfirmation(
												candidato,
												OPCIONESCANDIDATO.RECHAZAR
											)
										}
									/>
								)
							return (
								<TableRow key={index + "candidato"}>
									<TableCell sx={TABLEROW}>{candidato.nombre}</TableCell>
									<TableCell sx={TABLEROW}>{candidato.correo}</TableCell>
									<TableCell sx={TABLEROW}>{candidato.telefono}</TableCell>
									<TableCell sx={TABLEROW}>{candidato.status_desc}</TableCell>
									<TableCell sx={TABLEROW}>
										{buttonShowHistory}
										{buttonShowDocument}
										{button}
										{buttonRechazar}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			
			<ModalHistorialCandidato
				open={openHistorial}
				setOpen={setOpenHistorial}
				candidato={candidato}
			/>

			<ModalDocumentacion
				open={openDocum}
				setOpen={setOpenDocum}
				candidato={candidato}
			/>

			<ModalAgendar
				open={openAgendar.open}
				setOpen={setOpenAgendar}
				candidato={candidato}
				agendarEntrevista={agendar}
				disabledBtn={disabledBtn}
				file={false}
			/>

			<ModalConfirm
				open={openConfirm}
				setOpen={setOpenConfirm}
				candidato={candidato}

				aprobar={cambioStatus}
				disabledBtn={disabledBtn}
				opcion={opcCand}
			/>
			
			<DialogNewColaborador
				open={openColabo}
				setOpen={setOpenColabo}
				candidato={dataCandidato}
			/>
		</Fragment>
	)
}

export default TableCandidatos
