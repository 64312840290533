import React from "react"
import "@react-pdf-viewer/core/lib/styles/index.css"
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core"
import { Worker } from "@react-pdf-viewer/core"

export default function ShowPdf({ pdfUrl }) {
	return (
		<div>
			<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">
				<Viewer
					theme={{
						theme: "dark",
					}}
					fileUrl={pdfUrl}
					defaultScale={SpecialZoomLevel.PageWidth}
				/>
			</Worker>
		</div>
	)
}
