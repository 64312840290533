import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { useForm } from "react-hook-form"

import {
	OPTIONS_ESCOLARIDAD,
	OPTIONS_ESTADO_CIVIL,
	OPTIONS_PARENTEZCO,
	OPTIONS_SEXO,
} from "../../../assets/OptionsSelects"
import FormBase from "../../Common/Forms"
import { DialogForm } from "./../../Common/Dialog"
import {
	apiPostColaboradorFamiliar,
	apiPutColaboradorFamiliar,
} from "../../../Services/Api_Colaborador"
import { formDateFormat } from "./../../Common/Forms"

const DATA = [
	{
		field: "input",
		id: "nombre",
		label: "Nombre",
		registerParams: { required: true },
	},
	{
		field: "select",
		id: "parentezco",
		label: "Parentesco",
		dataID: "value",
		data: OPTIONS_PARENTEZCO,
		registerParams: { required: true },
	},
	{
		field: "date",
		id: "fecha_nacimiento",
		label: "Fecha Nacimiento",
		registerParams: { required: { value: true } },
	},
	{
		field: "select",
		id: "sexo",
		label: "Sexo",
		dataID: "value",
		data: OPTIONS_SEXO,
		registerParams: { required: { value: true } },
	},
	{
		field: "select",
		id: "estado_civil",
		label: "Estado civil",
		dataID: "value",
		data: OPTIONS_ESTADO_CIVIL,
		registerParams: { required: true },
	},

	{
		field: "select",
		id: "escolaridad",
		label: "Escolaridad",
		dataID: "value",
		data: OPTIONS_ESCOLARIDAD,
		registerParams: { required: true },
	},

	{
		field: "input",
		id: "profesion",
		label: "Profesiones",
		registerParams: { required: true },
	},

	{
		field: "checkBox",
		id: "vive",
		label: "¿Vive?",
	},
]

export default function FormDataFamiliares({
	idColaborador,
	open,
	setOpen,
	edit,
	actualizar,
}) {
	const token = useSelector((state) => state.user.token)
	const form = useForm({ defaultValues: edit })
	const { handleSubmit, reset } = form
	const [button, setButton] = useState(false)
	useEffect(() => {
		reset({
			nombre: "",
			parentezco: "",
			fecha_nacimiento: null,
			sexo: "",
			estado_civil: "",
			escolaridad: "",
			profesion: "",
			vive: true,
		})
		if (!!edit) {
			reset(edit)
		}
	}, [edit, reset, open])

	const submit = async (data) => {
		setButton(true)
		data.colaborador = idColaborador
		data.fecha_nacimiento = formDateFormat(data.fecha_nacimiento)
		let response
		if (edit) response = await apiPutColaboradorFamiliar(token, data)
		else response = await apiPostColaboradorFamiliar(token, data, {}, {})

		if (response.success) {
			actualizar()
			setOpen(false)
		}
		setButton(false)
	}

	return (
		<React.Fragment>
			<DialogForm
				title={edit ? "Editar Familiar" : "Registrar Familiar"}
				open={open}
				setOpen={setOpen}
				accept={handleSubmit(submit)}
				disabledButtons={button}
			>
				<FormBase
					fields={DATA}
					useForm={form}
				/>
			</DialogForm>
		</React.Fragment>
	)
}
