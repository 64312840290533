import { Add } from '@mui/icons-material/';
import { Grid, IconButton, createTheme } from '@mui/material/';
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DetalleColaborador from '../Components/Colaboradores/DetalleColaboradorNew';
import ModalNewColaborador from '../Components/Colaboradores/ModalNewColaborador';
import ModalUploadColaborador from '../Components/Colaboradores/ModalUploadColaborador';
import { LoadViewTable } from '../Components/Common/LoadingView';
import { REDCOLOR as COLOR_RED } from '../Constants/Styles';
import { getColaboradores } from '../Services/Api';
import { changeTitle, reloadTableNow } from '../app/pageSlice';
import WarningIcon from '@mui/icons-material/Warning';
import VerifiedIcon from '@mui/icons-material/Verified';

const Colaboradores = () => {
    const token = useSelector(state => state.user.token);
    const reloadTable   = useSelector(state => state.page.reloadTable);
    const dispatch = useDispatch()
    const [gridSize,setGridSize]    = useState([12, 0])
    const [idColab,setIdColab]      = useState(null) 
    const [listColab,setListColab]  = useState([]) 
    const [openModal,setOpenModal]  = useState(false) 
    const [openUplad,setOpenUpload] = useState(false)
    const [loading,setloading]      = useState(false)
    const mdTheme = createTheme();
    var contentView = <></>

    
    useEffect(()=>{
        dispatch(changeTitle('Colaboradores'))
        setloading(true)
        getColaboradores(token).then( result =>{
            let arrayColabs =[]
            
            result.map( colab => {
                let is_verify="No Verificado"
                if(colab.verificado)
                    is_verify="Verificado"
                else if(colab.verificado===false)
                    is_verify="Por Verificar"
                
                
                return arrayColabs.push(
                [
                colab.id,
                colab.nombre+' '+colab.apellido_paterno+' '+colab.apellido_materno,
                colab.sucursal__nombre,
                colab.perfil_puesto__centro_costos__descripcion,
                colab.perfil_puesto__nombre, 
                colab.no_empleado.padStart(5, '0'),
                colab.fecha_baja == null? "Activo":"Baja",
                is_verify

            ],
            
            )})
            setListColab(arrayColabs)
        }).catch((error)=> console.log(error) )
        .finally(() => setloading(false) )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadTable])


    const closeColabView = () =>{
        setGridSize([12,0])
        setIdColab(null)
    }

    const openColabView = (rowData) =>{
        if(idColab !== 0 && idColab === rowData[0]){
            closeColabView()
            return
        }
        setGridSize([3,9]) 
        setIdColab(rowData[0])
    }
    const options = {
        selectableRowsHideCheckboxes:true,
        print:false,
        viewColumns:false,
        onRowClick:(rowData)=>openColabView(rowData),
        // customToolbar: () => { return (<><IconButton style={{ order: -1 }} onClick={() => setOpenModal(true)}> <Add /> </IconButton> {/*<IconButton style={{ order: -1 }} onClick={() => setOpenUpload(true)}> <UploadFileIcon /> </IconButton>*/}</>); }
    };
    const optionCellGeneral={customBodyRender: (value, tableMeta) => {
        let color = tableMeta.rowData[6] === "Activo" ? '': COLOR_RED;
        return <span style={{ color:color}}>{value}</span>;
    }}

    function styleName(value,activo,verificado){
        let color = activo === "Activo" ? '' : COLOR_RED;
                    let icon = null
                    
                    if(verificado === "Por Verificar" )
                        icon=<WarningIcon  sx={{ fontSize: 18,  marginRight:"5px", color:"#B3BE20"}}/>
                    else
                        if(verificado==="Verificado")
                            icon=<VerifiedIcon  sx={{ fontSize: 18,  marginRight:"5px", color:"#49A7A7"}}/>
                    
                    return (
                        <div style={{ 
                            display: 'flex', 
                            color: color 
                        }}>
                        {icon} <span>{value}</span>   
                        </div>
                    );
    }
    
    const columns = [
        {
            name: 'id', 
            label: 'id', 
            options: { 
                display: false, 
                filter: false, 
                sort: true ,
                
            }, 
        },
        {
            name: "Nombre",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return styleName(
                        value,
                        tableMeta.rowData[6],
                        tableMeta.rowData[7]
                    )
                }
            }
        },
        {
            name: "Sucursal",
            options: {
                filter: true,
                sort: true,
                display: idColab == null
                ,...optionCellGeneral,
                }
        },
        {
            name: "Departamento",
            options: {
                filter: true,
                sort: true,
                display: idColab == null
                ,...optionCellGeneral,
            }
        },
        {
            name: "Puesto",
            options: {
                filter: true,
                sort: true,
                display: idColab == null
                ,...optionCellGeneral,
                }
        },
        {
            name: "No empleado",
            options: {
                filter: true,
                sort: true,
                display: idColab == null
                ,...optionCellGeneral,
            }
        },
        {
            name: 'Activo', label: 'Activo', 
            options: { 
                display: false, 
                filter: true, 
                sort: false 
                ,...optionCellGeneral,
            }, 
        },
        {
            name: 'verificado', 
            label: 'Verificado', 
            options: { 
                display: false, 
                filter: true, 
                
                sort: false,
                ...optionCellGeneral,
            
            }, 
        }
        
    ];

    if(loading) contentView = <LoadViewTable/>
    else contentView =  <MUIDataTable
                            data={listColab}
                            columns={columns}
                            options={options}
                            // sx={{with: 400}} 
                            />
    return (
        <Grid container spacing={5}>
            <Grid item md={gridSize[0]} align='center'
                style={{transition: mdTheme.transitions.create("all", {
                        easing: mdTheme.transitions.easing.sharp, 
                        duration: mdTheme.transitions.duration.leavingScreen,
                })}}>
                {contentView}    
            </Grid>
            <Grid item md={gridSize[1]} 
                style={{transition: mdTheme.transitions.create("all", {
                    easing: mdTheme.transitions.easing.sharp, 
                    duration: mdTheme.transitions.duration.leavingScreen,
                    })}}>
                <DetalleColaborador idColaborador={idColab}/>
            </Grid>
            <ModalNewColaborador open={openModal} setOpen={setOpenModal}/>
            <ModalUploadColaborador open={openUplad} setOpen={setOpenUpload}/>
        </Grid>
    )
}

export default Colaboradores
