import { get, post, put } from "./Api_Call"
const URL_API = process.env.REACT_APP_SERVER_URL + "rh/solicitudes/prestamos"

//######################################################GET######################################################

/**
 * @description - Función para obtener los métodos de pago de los prestamos
 * @param {Int} token - Credenciales para la API
 * @param {UseState} set_db - UseState para guardar los datos
 * @param {string} [filtro=""] -"" Filtro para la API recuerda que es un string y empieza con ?
 * @param {UseState} [skeleton=() => {}] - UseState para desactivar el esqueleto cambiando el estado a true
 * @returns {Array} - Array de objetos con los datos de la API
 */
export async function getPrestamos(
	token,
	set_db,
	filtro = "",
	skeleton = () => {}
) {
	get(URL_API, token, set_db, filtro, skeleton)
}

/**
 * @description - Función para obtener los métodos de pago de los prestamos
 * @param {Int} token - Credenciales para la API
 * @param {UseState} set_db - UseState para guardar los datos
 * @param {string} [filtro=""] -"" Filtro para la API recuerda que es un string y empieza con ?
 * @param {UseState} [skeleton=() => {}] - UseState para desactivar el esqueleto cambiando el estado a true
 * @returns {Array} - Array con los datos de la API
 */
export async function getPrestamosMethodPay(
	token,
	set_db,
	filtro = "",
	skeleton = () => {}
) {
	get(URL_API + "/metodo_pago", token, set_db, filtro, skeleton)
}

/**
 * @description - Función para obtener La Fila de los prestamos
 * @param {Int} token - Credenciales para la API
 * @param {UseState} set_db - UseState para guardar los datos
 * @param {string} [filtro=""] -"" Filtro para la API recuerda que es un string y empieza con ?
 * @param {UseState} [skeleton=() => {}] - UseState para desactivar el esqueleto cambiando el estado a true
 * @returns {Array} - Array con los datos de la API
 */
export async function getPrestamosFila(
	token,
	set_db,
	filtro = "",
	skeleton = () => {}
) {
	get(URL_API + "/fila", token, set_db, filtro, skeleton)
}

/**
 * @description - Función para obtener los Estados de los prestamos
 * @param {Int} token - Credenciales para la API
 * @param {UseState} set_db - UseState para guardar los datos
 * @param {string} [filtro=""] -"" Filtro para la API recuerda que es un string y empieza con ?
 * @param {UseState} [skeleton=() => {}] - UseState para desactivar el esqueleto cambiando el estado a true
 * @returns {Array} - Array con los datos de la API
 */
export async function getPrestamosStatus(
	token,
	set_db,
	filtro = "",
	skeleton = () => {}
) {
	get(URL_API + "/status", token, set_db, filtro, skeleton)
}

/**
 * permite recibir un archivo PDF
 * @date 15/8/2023 - 10:58:01
 *
 * @async
 * @param {*} token
 * @param {*} link
 * @param {*} set_db
 * @param {string} [filtro=""]
 * @param {() => void} [skeleton=() => {}]
 * @returns {void) => any}
 */
export const getLinkPDF = async (
	token,
	link,
	set_db,
	filtro = "",
	skeleton = () => {}
) => {
	let url =
		process.env.REACT_APP_SERVER_URL + `rh/cursos/departamento/link?url=${link}`
	return await get(url, token, set_db, filtro, skeleton)
}

//######################################################POST######################################################
/**
 * Función para Enviar los datos de prestamos a la API
 * @date 22/8/2023 - 15:20:22
 *
 * @async
 * @param {*} token-Credenciales para la API
 * @param {*} datos-Datos a enviar a la API
 * @param {*} [params={}] - Ruta Alternativa
 * @param {string} [actualizar=""]
 * @param {() => void} [setActualizar=() => {}]
 * @returns {void) => any}
 */
export const postPrestamos = async (
	token,
	datos,
	params = {},
	actualizar = "",
	setActualizar = () => {}
) => {
	await post(URL_API, token, datos, params, actualizar, setActualizar)
}

//######################################################PUT######################################################

/**
 * Description placeholder
 * @date 14/8/2023 - 10:13:03
 *
 * @async
 * @param {*} token -Credenciales para la API
 * @param {*} datos -Nuevo estado para la solicitud
 * @param {{}} [params={}] - Filtro para las solicitud a modificar
 * @param {boolean} [actualizar=true]
 * @param {() => void} [setActualizar=() => {}]
 * @returns {void) => any}
 */
export const putPrestamos = async (
	token,
	datos,
	params = {},
	actualizar = true,
	setActualizar = () => {}
) => {
	const response = await put(
		URL_API,
		token,
		datos,
		params,
		actualizar,
		setActualizar
	)
	console.log(response)
	return response
}
