import { Fragment, createRef, useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { changeTitle } from "../../../app/pageSlice"

//Api
import {
	apiGetPromotion,
	apiGetPromotionDetail,
	apiPutPromotion,
} from "../../../Services/Api_promotion"

//componentes
import { PERFILES } from "../../../Constants/Strings"
import { PRIMARYCOLOR } from "../../../Constants/Styles"
import { ButtonIcon } from "../../Common/Buttons"
import { DialogGeneral } from "../../Common/Dialog"
import { hasPermission } from "../../Common/Funciones"
import { LoadViewTable } from "../../Vacantes/LoadViews"
import { ActualizarContext } from "./ContextActualizar"
import ModalHistorial from "./ModalHistorial"

//Material
import { Box, Paper, Stack, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import MUIDataTable from "mui-datatables"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"

//icons
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import HistoryIcon from "@mui/icons-material/History"
//
// import { useCheckPermission } from "../../Hooks/useCheckPermission"
import VacanteNew from "./VacanteNew"
// import { GridInputSelect, GridInputText } from "../Common/Inputs"

const STATUS = {
	"En Proceso": 1,
	"Aprobada RH": 2,
	Finalizada: 3,
	Rechazada: 4,
}

export default function Promotion({ promotion }) {
	ChangeTitle("Promociones")
	const [data, setData] = useState({})
	const { promotions } = usePromotionData(null, promotion.id)

	useEffect(() => {
		if (promotions.length) setData(promotions[0])
	}, [promotions])

	const { requestor, status, request_date, status_id } = data

	return (
		<Box
			component={Paper}
			elevation={12}
			width={"100%"}
		>
			<Grid2
				container
				spacing={3}
				padding={5}
				justifyContent={"space-between"}
				alignContent={"inherit"}
				alignItems={"inherit"}
			>
				<DataItem
					label={"Solicitante"}
					value={requestor}
					size={1}
					fontSizeLabel={18}
					fontSizeValue={18}
				/>
				<DataItem
					label={"Status"}
					value={status}
				/>
				<DataItem
					label={"Fecha Solicitud"}
					value={request_date}
				/>
				<Grid2>
					<PromotionButtons
						promotion_id={promotion.id || 0}
						disabled={status_id === STATUS["En Proceso"]}
					/>
				</Grid2>
				<Grid2 xs={12}>
					<DetailsPromotion
						promotion_id={promotion.id || 0}
						disable={status_id !== STATUS["Aprobada RH"]}
					/>
				</Grid2>
			</Grid2>
		</Box>
	)
}

function PromotionButtons({ promotion_id, disabled }) {
	const token = useSelector((state) => state.user.token)
	const { actualizarNow } = useContext(ActualizarContext)
	const [open, setOpen] = useState(false)
	const [openConfirm, setOpenConfirm] = useState(false)
	const [comentario, setComentario] = useState("")
	const [buttonDisabled, setButtonDisabled] = useState("")
	let texto = {
		4: "Rechazar Solicitud",
		2: "Aprobar Solicitud",
	}
	let componente = {
		4: (
			<TextField
				onChange={(e) => setComentario(e.target.value)}
				label="Comentario*"
				autoComplete="off"
				multiline
				fullWidth
			/>
		),
	}

	let sendResponse = async () => {
		let data = { status_id: openConfirm?.status_id, promotion_id }
		if (openConfirm?.status_id === 4)
			if (comentario.trim() !== "") {
				data["comentario"] = comentario.trim()
			} else {
				toast.error("Se necesita un comentario", {
					toastId: "error_comentario",
				})
				return null
			}
		setButtonDisabled(true)
		let { success } = await apiPutPromotion(token, { promotion_id, ...data })
		if (success) {
			setOpenConfirm(false)
			actualizarNow()
		}
		setButtonDisabled(false)
	}

	let buttons = [
		<Button
			key={"cancelar"}
			onClick={() => setOpenConfirm(false)}
			disabled={buttonDisabled}
		>
			Cancelar
		</Button>,
		<Button
			key={"aceptar"}
			onClick={sendResponse}
			disabled={buttonDisabled}
		>
			Aceptar
		</Button>,
	]

	return (
		<Stack direction={"row"}>
			<ButtonIcon
				title="Aprobar"
				sx={{ color: "green" }}
				icon={<CheckCircleIcon fontSize="inherit" />}
				onClick={() => {
					setOpenConfirm({ open: true, status_id: 2 })
					// SendResponse({ status_id: 2 })
				}}
				show={disabled}
				size="large"
			/>
			<ButtonIcon
				title="Rechazar"
				icon={<CancelIcon fontSize="inherit" />}
				onClick={() => {
					setOpenConfirm({ open: true, status_id: 4 })
					// SendResponse({ status_id: 4 })
				}}
				show={disabled}
				size="large"
				sx={{ color: "Red" }}
			/>
			<ButtonIcon
				title="Historial"
				icon={<HistoryIcon fontSize="inherit" />}
				onClick={() => {
					setOpen(true)
				}}
				// show={}
				size="large"
				sx={{ color: PRIMARYCOLOR }}
			/>
			<ModalHistorial
				setOpen={setOpen}
				open={open}
				promotion={promotion_id}
			/>
			<DialogGeneral
				title={texto[openConfirm?.status_id]}
				open={openConfirm?.open}
				setOpen={setOpenConfirm}
				maxWidth="xs"
				buttons={buttons}
			>
				{componente[openConfirm?.status_id]}
			</DialogGeneral>
		</Stack>
	)
}
const ChangeTitle = (title) => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(changeTitle(title))
	}, [dispatch, title])
}

const usePromotionData = (status, id = null) => {
	const token = useSelector((state) => state.user.token)
	const { actualizar } = useContext(ActualizarContext)
	const [promotions, setPromotions] = useState([])
	const [load, setLoad] = useState([])
	status = status ? status : null
	useEffect(() => {
		const controller = new AbortController()
		setLoad(true)
		apiGetPromotion({
			token,
			setDB: setPromotions,
			controller,
			params: { status, id },
		}).then((response) => {
			if (response?.success) setLoad(false)
		})
		return () => {
			controller.abort()
		}
	}, [token, actualizar, status, id])
	return { promotions, load }
}

const usePromotionDetailData = (promotion_id) => {
	const token = useSelector((state) => state.user.token)
	const { actualizar } = useContext(ActualizarContext)
	const [details, setPromotions] = useState([])
	const [load, setLoad] = useState([])
	useEffect(() => {
		const controller = new AbortController()
		setLoad(true)
		apiGetPromotionDetail({
			token,
			setDB: setPromotions,
			controller,
			params: { promotion_id },
		}).then((response) => {
			if (response?.success) setLoad(false)
		})
		return () => {
			controller.abort()
		}
	}, [promotion_id, token, actualizar])
	return { details, load }
}

export const DataItem = ({
	label,
	value,
	fontSizeLabel = 16,
	fontSizeValue = 16,
	fontColor = "#555",
	fontBold = "",
}) => {
	return (
		<Grid2>
			<Typography
				sx={{
					fontSize: fontSizeLabel,
					fontWeight: "bold",
					color: PRIMARYCOLOR,
				}}
			>
				{label}
			</Typography>
			<Typography
				noWrap={true}
				sx={{
					fontSize: fontSizeValue,
					fontWeight: fontBold,
					color: fontColor,
					display: "inline-block",
				}}
			>
				{value}
			</Typography>
		</Grid2>
	)
}

export function PromotionTable({ openVacanteDetail }) {
	const [status, setStatus] = useState(0)
	const { promotions, load } = usePromotionData(status)
	let perfiles = useSelector((state) => state.user.profiles)
	let showFilter = hasPermission(perfiles, [PERFILES.RH])
	let tableColumns = [
		{
			label: "id",
			name: "id",
			options: { display: false, filter: false, sort: true },
		},
		{
			label: "Status",
			name: "status",
			display: true,
			options: {
				filter: false,
			},
		},
		{ label: "Solicitante", name: "requestor" },
		{ label: "Fecha Solicitud", name: "request_date" },
	]

	const options = {
		selectableRowsHideCheckboxes: true,
		print: false,
		viewColumns: false,
		// confirmFilters: true,
		textLabels: { body: { noMatch: "No hay Promociones" } },
		customFilterListOptions: {
			City: (filterList, onChange, index, column) => (
				<Button
					filterList={filterList}
					onChange={onChange}
					index={index}
					column={column}
				/>
			),
		},
		onRowClick: (rowData) => {
			openVacanteDetail(
				promotions.filter((promotion) => promotion.id === rowData[0])
			)
		},
	}

	if (load) return <LoadViewTable />

	return (
		<Grid2 container>
			<Grid2 xs={12}>
				<MUIDataTable
					data={promotions}
					columns={tableColumns}
					options={options}
				/>
			</Grid2>
		</Grid2>
	)
}

function DetailsPromotion({ promotion_id, disable }) {
	const { details, load } = usePromotionDetailData(promotion_id)
	if (load)
		return (
			<Grid2
				container
				justifyContent={"center"}
			>
				<Grid2>
					<CircularProgress size={80} />
				</Grid2>
			</Grid2>
		)

	return (
		<Box
			sx={{
				padding: 2,
				width: ["100%"],
				backgroundColor: "#fffffffff",
				maxHeight: "70vh",
				overflowY: "auto",
				overflowX: "hidden",
				"::-webkit-scrollbar": "4px",
			}}
		>
			<Grid2
				container
				spacing={3}
				justifyContent={"center"}
			>
				{details.map((detail) => (
					<Grid2
						key={`card_${detail?.id}}`}
						xs={12}
					>
						<DetailCar
							detail={detail}
							disable={disable}
						/>
					</Grid2>
				))}
			</Grid2>
		</Box>
	)
}

function Text({ nombre, dato }) {
	return (
		<Typography noWrap>
			<span style={{ fontWeight: "bold" }}>{nombre}</span>
			{dato}
		</Typography>
	)
}

export function DetailCar({ detail, disable = false }) {
	const token = useSelector((state) => state.user.token)
	const { actualizarNow } = useContext(ActualizarContext)
	const [openConfirm, setOpenConfirm] = useState(false)
	const [openVacante, setOpenVacante] = useState(false)
	const [newVacante, setNewVacante] = useState(false)
	const [comentario, setComentario] = useState("")
	const [buttonDisabled, setButtonDisabled] = useState("")
	let { id, promotion } = detail
	let estado = "Espera de autorización"
	if (detail.authorized === true) estado = "Aprobado"
	else if (detail.authorized === false) estado = "Rechazada"
	if (detail.authorized === true || detail.authorized === false) disable = true

	let sendResponse = async () => {
		let data = {
			authorization: openConfirm?.authorization,
			detail_id: id,
			promotion_id: promotion,
		}
		if (!openConfirm?.authorization)
			if (comentario.trim() !== "") {
				data["comentario"] = comentario.trim()
			} else {
				toast.error("Se necesita un comentario", {
					toastId: "error_comentario",
				})
				return null
			}
		setButtonDisabled(true)
		let { success } = await apiPutPromotion(token, data)
		if (success) {
			setOpenConfirm(false)
			if (newVacante) setOpenVacante(true)
			else actualizarNow()
		}
		setButtonDisabled(false)
	}

	let buttons = [
		<Button
			key={"cancelar"}
			onClick={() => setOpenConfirm(false)}
			disabled={buttonDisabled}
		>
			Cancelar
		</Button>,
		<Button
			key={"aceptar"}
			onClick={sendResponse}
			disabled={buttonDisabled}
		>
			Aceptar
		</Button>,
	]

	let openResponse = (authorization) => {
		setOpenConfirm({ open: true, authorization })
	}

	let dialogoContenido
	if (openConfirm.authorization) {
		dialogoContenido = (
			<FormControlLabel
				label={`Al aceptar, el puesto de ${detail.old_profile} quedará vacío.\n ¿Deseas abrir un nuevo requerimiento?`}
				labelPlacement="top"
				control={
					<Switch
						size="50px"
						checked={newVacante}
						onClick={(event) => {
							setNewVacante(event.target.checked)
						}}
					/>
				}
			/>
		)
	} else {
		dialogoContenido = (
			<TextField
				onChange={(e) => setComentario(e.target.value)}
				label="Comentario*"
				autoComplete="off"
				multiline
				fullWidth
			/>
		)
	}

	return (
		<Fragment>
			<Card
				elevation={12}
				sx={{
					display: "flex",
					justifyContent: "space-between",
					width: "100%",
				}}
			>
				<CardMedia
					sx={{
						minWidth: "150px",
						width: "150px",
						borderRadius: "15px",
						padding: "10px",
					}}
					component="img"
					image={detail?.photo}
					title="Colaborador"
				/>
				<CardContent sx={{ flexGrow: 1 }}>
					<Grid2
						container
						spacing={0}
						columnSpacing={2}
					>
						<Grid2 xs={12}>
							<Typography
								gutterBottom
								variant="h5"
								component="div"
								fontWeight={"bold"}
							>
								{detail.name}
							</Typography>
						</Grid2>
						<Grid2>
							<Text
								nombre="Puesto Actual:"
								dato={detail.old_profile}
							/>
							<Text
								nombre={estado + " :"}
								dato={detail.authorization_name}
							/>
						</Grid2>
						<Grid2>
							<Text
								nombre="Puesto Requerido:"
								dato={detail.new_profile}
							/>
						</Grid2>
					</Grid2>
				</CardContent>

				{!disable && (
					<CardActions>
						<Button
							size="small"
							sx={{ color: "black" }}
							// color="black"
							onClick={() => {
								openResponse(true)
							}}
						>
							Aprobar
						</Button>
						<Button
							sx={{ color: "black" }}
							onClick={() => {
								openResponse(false)
							}}
							size="small"
						>
							Rechazar
						</Button>
					</CardActions>
				)}
			</Card>
			<DialogGeneral
				title={
					openConfirm.authorization ? "Aprobar Solicitud" : "Rechazar Solicitud"
				}
				open={openConfirm?.open}
				setOpen={setOpenConfirm}
				maxWidth="xs"
				buttons={buttons}
			>
				{dialogoContenido}
			</DialogGeneral>
			{/* Vacante modal */}
			<DialogGeneral
				title="Nueva Vacante"
				open={openVacante}
				onClose={() => {
					actualizarNow()
					setOpenVacante()
				}}
				maxWidth="lg"
			>
				<VacanteNew
					open={openVacante}
					setOpen={setOpenVacante}
					detail={detail}
					actualizar={actualizarNow}
				/>
			</DialogGeneral>
		</Fragment>
	)
}
