import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useEffect, useMemo, useState } from "react"

// Card Component
import Typography from "@mui/material/Typography"
//icons
// componentes
import { LoadSimpleTable } from "#common/LoadingView"
import { UseColaboradores } from "../UseColaborador"
import { CardColaborador } from "./cardComponents/CardColaborador"
import { ColaboradorTools } from "./ColaboradorTools"

const MiEquipoCards = ({ colaborador }) => {
	const { id } = colaborador
	const [colaboradoresCargo, setColaboradoresCargo] = useState(false)
	const [colaboradoresFilter, setColaboradoresFilter] = useState([])
	const [actualizar, setActualizar] = useState(false)
	const reload = () => setActualizar(!actualizar)

	const params = useMemo(() => {
		let params = {}
		if (colaboradoresCargo) params["sub_colaboradores"] = true
		return params
	}, [colaboradoresCargo])

	const { colaboradores, loading } = UseColaboradores(`MyTeam/${id}`, {
		params,
		actualizar: actualizar,
	})

	useEffect(() => {
		setColaboradoresFilter(colaboradores)
	}, [colaboradores])

	const cardsColaboradores = useMemo(() => {
		const cards = colaboradoresFilter.map((colaborador) => (
			<Grid2
				key={colaborador.id}
				// xs={12}
				// md={6}
				// lg={4}
				minWidth={"500px"}
			>
				<CardColaborador
					colaborador={colaborador}
					setActualizar={setActualizar}
					reload={reload}
				/>
			</Grid2>
		))
		if (cards.length === 0)
			return [
				<Typography
					key={"no_colaboradores"}
					variant="h6"
					sx={{ textAlign: "center" }}
				>
					No hay colaboradores
				</Typography>,
			]
		else return cards
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colaboradoresFilter])

	if (loading) return <LoadSimpleTable />

	return (
		<Grid2
			container
			spacing={2}
			// display={"flex"}
			// wrap="wrap"
			justifyContent={"center"}
		>
			<Grid2 xs={12}>
				<ColaboradorTools
					colaborador={colaborador}
					colaboradores={colaboradores}
					setColaboradoresFilter={setColaboradoresFilter}
					setColaboradoresCargo={setColaboradoresCargo}
					colaboradoresCargo={colaboradoresCargo}
					setActualizar={setActualizar}
				/>
			</Grid2>
			{cardsColaboradores}
		</Grid2>
	)
}

export default MiEquipoCards

// const CardColaborador = ({ colaborador, setActualizar, reload }) => {
// 	const {
// 		area,
// 		photo,
// 		sucursal,
// 		fondo_fijo,
// 		perfil_puesto,
// 		nombre_completo,
// 		telefono_lubtrac: celular,
// 		fondo_in_progress,
// 		baja_in_progress,
// 	} = colaborador

// 	const [openFondo, setOpenFondo] = useState(false)
// 	const [openSolicitudBaja, setOpenSolicitudBaja] = useState(false)
// 	const fields = {
// 		"Perfil Puesto": perfil_puesto,
// 		Departamento: area,
// 		Sucursal: sucursal,
// 		"Fondo Fijo": fondo_fijo,
// 		"Teléfono Lubtrac": celular,
// 	}
// 	if (fondo_fijo == null)
// 		fields["Fondo Fijo"] = (
// 			<Button
// 				size="small"
// 				disabled={fondo_in_progress}
// 				onClick={() => setOpenFondo(true)}
// 			>
// 				solicitar fondo
// 			</Button>
// 		)

// 	let fields_view = []
// 	for (const property in fields) {
// 		fields_view.push(
// 			<FieldsColaborador
// 				key={property}
// 				field={property}
// 				value={fields[property]}
// 			/>,
// 		)
// 	}

// 	return (
// 		<Card
// 			sx={{
// 				minWidth: 400,
// 				width: "100%",
// 				height: "100%",
// 				display: "flex",
// 			}}
// 		>
// 			<CardMedia
// 				component="img"
// 				sx={{ width: 100 }}
// 				image={photo}
// 				alt="Colaborador"
// 				onError={(e) => {
// 					e.target.onerror = null
// 					e.target.src =
// 						"https://imagenespublicas.s3.us-east-2.amazonaws.com/usuario.webp"
// 				}}
// 			/>
// 			<Box
// 				sx={{
// 					display: "flex",
// 					flexDirection: "column",
// 					width: "100%",
// 					height: "100%",
// 					justifyContent: "space-between", // Este estilo asegura que el contenido esté alineado
// 				}}
// 			>
// 				<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
// 					<CardContent>
// 						<Typography
// 							gutterBottom
// 							variant="h6"
// 							component="div"
// 						>
// 							{nombre_completo}
// 						</Typography>
// 						{fields_view}
// 					</CardContent>
// 				</Box>
// 				<CardActions>
// 					<Stack
// 						direction={"row"}
// 						spacing={2}
// 					>
// 						<ButtonIcon
// 							disabled={baja_in_progress}
// 							title="Dar de baja"
// 							icon={<PersonOffIcon />}
// 							size="small"
// 							color="primary"
// 							onClick={() => setOpenSolicitudBaja(true)}
// 						/>
// 					</Stack>
// 				</CardActions>
// 				<FondoFijoFormulario
// 					open={openFondo}
// 					setOpen={setOpenFondo}
// 					actualizar={() => setActualizar((actualizar) => !actualizar)}
// 					colaborador={colaborador.id || null}
// 				/>
// 			</Box>
// 			<DialogSolicitudBaja
// 				open={openSolicitudBaja}
// 				setOpen={setOpenSolicitudBaja}
// 				colaborador={colaborador}
// 				reload={reload}
// 			/>
// 		</Card>
// 	)
// }

// const ColaboradorTools = ({
// 	colaborador,
// 	colaboradores,
// 	setColaboradoresFilter,
// 	setColaboradoresCargo,
// 	colaboradoresCargo,
// }) => {
// 	const [search, setSearch] = useState("")
// 	const [openPromotion, setOpenPromotion] = useState(false)

// 	useEffect(() => {
// 		const colaboradoresFilter = colaboradores?.filter((colaborador) => {
// 			if (search.length === 0) return colaborador
// 			if (
// 				colaborador.nombre_completo
// 					?.toLowerCase()
// 					?.includes(search?.toLowerCase())
// 			)
// 				return colaborador
// 			return false
// 		})
// 		setColaboradoresFilter(colaboradoresFilter)
// 	}, [colaboradores, search, setColaboradoresFilter])

// 	return (
// 		<Box>
// 			<Stack
// 				width={"100%"}
// 				height={"40px"}
// 				direction={"row"}
// 				spacing={1}
// 				justifyContent={"right"}
// 			>
// 				<TextField
// 					sx={{ width: `350px`, height: "40px" }}
// 					label="Buscar por nombre"
// 					variant="outlined"
// 					size="small"
// 					autoComplete="off"
// 					value={search}
// 					onChange={(e) => setSearch(e.target.value)}
// 				/>
// 				<Button
// 					variant="contained"
// 					color={colaboradoresCargo ? "secondary" : "primary"}
// 					onClick={() => setColaboradoresCargo(!colaboradoresCargo)}
// 					startIcon={<PeopleIcon />}
// 				>
// 					Mostrar todos los colaboradores
// 				</Button>
// 				<Button
// 					variant="contained"
// 					size="large"
// 					onClick={() => setOpenPromotion(!openPromotion)}
// 				>
// 					<PersonIcon />
// 					<CachedIcon />
// 					Movimientos de personal
// 				</Button>
// 			</Stack>
// 			<ModalMovimientos
// 				colaboradorId={colaborador?.id}
// 				reporta_a={colaborador?.perfil_puesto}
// 				open={openPromotion}
// 				setOpen={setOpenPromotion}
// 			/>
// 		</Box>
// 	)
// }

// const FieldsColaborador = ({ field, value }) => {
// 	if (value == null) return null
// 	return (
// 		<Typography
// 			variant="body2"
// 			color="text.secondary"
// 		>
// 			{field} : {value}
// 		</Typography>
// 	)
// }
