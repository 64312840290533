import { styled } from "@mui/material/styles"
import { useMemo } from "react"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Stack } from "@mui/material"
import { ButtonDialog } from "#common/Dialog"

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
		overflowY: "auto",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}))

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	padding: "2vw !important",
	// Estilos personalizados para el scrollbar
	"&::-webkit-scrollbar": {
		width: "4px",
	},
	"&::-webkit-scrollbar-thumb": {
		backgroundColor: theme.palette.primary.main,
		borderRadius: "6px",
	},
	"&::-webkit-scrollbar-track": {
		backgroundColor: theme.palette.background.default,
		borderRadius: "10px",
	},
}))

export function MasterDialog({
	title,
	open,
	setOpen,
	children,
	buttons,
	propsChildren,
	propsTitle,
	closeDialog = () => {},
	...props
}) {
	const componentTitle = useMemo(() => {
		if (!title) return null
		return (
			<DialogTitle
				id="Dialog-Form"
				textAlign="center"
				{...propsTitle}
			>
				{title}
			</DialogTitle>
		)
	}, [propsTitle, title])

	const componentContent = useMemo(() => {
		if (!title) return null
		return (
			<CustomDialogContent
				dividers
				{...propsChildren}
			>
				{children}
			</CustomDialogContent>
		)
	}, [children, propsChildren, title])

	const componentButtons = useMemo(() => {
		if (!buttons) return null

		return (
			<DialogActions sx={{ alignContent: "center", justifyContent: "center" }}>
				<Stack
					direction="row"
					spacing={3}
				>
					{buttons.map((button) => (
						<ButtonDialog
							key={`buttonDialog${button.label}`}
							label={button.label}
							disabled={button.disabled}
							onClick={button.onClick}
						/>
					))}
				</Stack>
			</DialogActions>
		)
	}, [buttons])

	return (
		<BootstrapDialog
			fullWidth={true}
			open={open}
			onClose={() => {
				setOpen(false)
				closeDialog()
			}}
			{...props}
		>
			{componentTitle}
			{componentContent}
			{componentButtons}
		</BootstrapDialog>
	)
}
