import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from '../app/pageSlice';
//Api
import { getVacanteDetail, getVacantes } from '../Services/Api';
import { apiGetPromotion } from '../Services/Api_promotion';
//componentes 
import DetalleVacante from '../Components/Vacantes/DetalleVacante';
import { LoadViewDetail, LoadViewTable } from '../Components/Vacantes/LoadViews';
import ModalNewVacante from '../Components/Vacantes/ModalNewVacante';
import TableVacantes from '../Components/Vacantes/TableVacantes';
import Promotion, { PromotionTable } from "../Components/Colaboradores/MiEquipo/Promotion"
//Material
import { Box, Grid, Tab, Tabs, Typography, createTheme } from '@mui/material/';
import { ActualizarProvider } from '../Components/Colaboradores/MiEquipo/ContextActualizar';
import { PERFILES } from '../Constants/Strings';
import { hasPermission } from '../Components/Common/Funciones';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other} >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
}

const Vacantes = () => {
    const perfiles = useSelector(state => state.user.profiles);
    const [openModal,setOpenModal]       = useState(false) 
    const [listVacantes,setListVacantes] = useState(null)   
    const [dataVacante,setDataVacante]   = useState(null) 
    const [idVacante,setIdVacante]       = useState(null) 
    const [gridSize,setGridSize]         = useState([10, 2]) 
    const [loadingList,setLoadingList]   = useState(true) 
    const [loadVac,setLoadVac]           = useState(false) 
    const [loadList,setLoadList]         = useState(true)
    const [activeTab, setActiveTab]       = useState(0);
    const [textTab, setTextTab]           = useState('Vacantes ');
    const [vacantesActivas, setVacantesActivas] = useState(true)
    const reload   = useSelector(state => state.page.reloadVacante);
    const reloadTable   = useSelector(state => state.page.reloadTable);
    const token    = useSelector(state => state.user.token);
    const mdTheme  = createTheme();
    const dispatch = useDispatch()
    let contentView   = <></>
    let contentDetail = <></>
    const [promotion,setPromotion]       = useState([0]) 

    useEffect(()=>{ 
        setLoadingList(true)
        setListVacantes([])
        dispatch(changeTitle('Vacantes'))
        getVacantes(token,vacantesActivas).then((result)=>{
            let arrayVacantes = []
            result.map(vacante => {
                let fechaVacante = new Date(vacante.fecha_solicitud);
                
                let fecha =  (
                    fechaVacante.getDate()<10 
                        ? '0' + fechaVacante.getDate() 
                        : fechaVacante.getDate()) + '/' + 
                    ((fechaVacante.getMonth() + 1)<10 
                        ? '0'+(fechaVacante.getMonth() + 1)
                        :(fechaVacante.getMonth() + 1)) +  '/' + 
                    fechaVacante.getFullYear()
                arrayVacantes.push([
                    vacante.id, 
                    vacante.id-125, 
                    vacante.sucursal__nombre, 
                    vacante.perfil_puesto__nombre, 
                    fecha, 
                    vacante.status__descripcion,
                    vacante.last_modification])
                return 0
            })
            setListVacantes(arrayVacantes)
          }).catch((error)=> alert(error) 
          ).finally(()=> setLoadingList(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[token, loadList,reloadTable,vacantesActivas])

    useEffect(()=>{ // obtener detalle de la vacante
        setDataVacante(null)
        if(idVacante === 0 || idVacante == null) return
        setLoadVac(true)
        getVacanteDetail(token, idVacante).then(response => setDataVacante(response)
        ).catch(error => alert(error)
        ).finally(()=> setLoadVac(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idVacante, reload])


    const openVacanteDetail = (rowData) =>{
        if(idVacante !== 0 && idVacante === rowData[0]){
            closeVacanteDetail()
            return
        }
        setTextTab("")
        setGridSize([3,9]) 
        setIdVacante(rowData[0])
        
    }
    const openPromotionDetail = (rowData) =>{
        if(promotion?.id !== 0 && promotion?.id === rowData[0]?.id){
            closePromotionDetail()
            return
        }
        setTextTab("")
        setGridSize([3,9]) 
        setPromotion(rowData[0])
        
    }
    
    const closePromotionDetail = () =>{
        setPromotion([0])
        setTextTab("Vacante")
        setGridSize([10,0])
    }
    const closeVacanteDetail = () =>{
        setIdVacante(null)
        setTextTab("Vacante ")
        setGridSize([10,2])
    }

    const handleChange = (event, newValue) => {
        setVacantesActivas(!vacantesActivas)
        setActiveTab(newValue);
        closeVacanteDetail()
    };
    

    if(loadingList) contentView = <LoadViewTable/>
    else contentView = <TableVacantes listVacantes={listVacantes} idVacante={idVacante} openVacanteDetail={openVacanteDetail} setOpenModal={setOpenModal} showDay={vacantesActivas}/>
    
    if(loadVac){
        contentDetail = <LoadViewDetail/>
    }else if(dataVacante !== null )
        contentDetail = <DetalleVacante dataVacante={dataVacante} days_passed={vacantesActivas}/>

    if(activeTab===2){
        contentView=<PromotionTable
            openVacanteDetail={openPromotionDetail}
        />
        contentDetail=  gridSize[1]>5? <Promotion promotion={promotion} />:[]
    }
    
    let permisos=hasPermission( perfiles,PERFILES.RH)
    return (
        <Grid container spacing={5}>
            <ActualizarProvider>

            <Grid item md={gridSize[0]}
                align='center'
                style={{transition: mdTheme.transitions.create("all", {
                    easing: mdTheme.transitions.easing.sharp, 
                    duration: mdTheme.transitions.duration.leavingScreen,
                })}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={activeTab} onChange={handleChange} >
                            <Tab iconPosition="start" label={textTab+"Activas"} {...a11yProps(0)} />
                            <Tab  iconPosition="start" label={textTab+"Cerradas"} {...a11yProps(1)} />
                            {permisos&&<Tab  iconPosition="start" label={"Promociones"} {...a11yProps(2)} />}
                        </Tabs>
                    </Box>
                {contentView}
            </Grid>
            <Grid item   md={gridSize[1]} 
                style={{transition: mdTheme.transitions.create("all", {
                    easing: mdTheme.transitions.easing.sharp, 
                    duration: mdTheme.transitions.duration.leavingScreen,
                })}}>
                {contentDetail}
            </Grid>
            <ModalNewVacante open={openModal} setOpen={setOpenModal} setLoadList={setLoadList} loadList={loadList}/>
            </ActualizarProvider>
        </Grid>
  )
}

export default Vacantes
