import { COLOR_THIRD } from '#constants/Styles'
import { Add } from '@mui/icons-material/'
import { IconButton } from '@mui/material'
import MUIDataTable from "mui-datatables"

const TableVacantes = ({listVacantes, idVacante, openVacanteDetail, setOpenModal,showDay=false}) => {
    if(listVacantes == null) return
    const columns = [
        { name: 'id', label: 'id', options: { display: false, filter: false, sort: true, }, },
        { name: 'id', label: '#', options: { filter: false, sort: true, }, },
        "Sucursal",
       // { name: 'Solicitante', options: { filter: true, sort: true, display: vacante == null } },
        "Perfil Vacante",
        { name: "Fecha Solicitud", options: { filter: true, sort: true, display: idVacante == null } },
        { name: "Status", options: { filter: true, sort: true, display: idVacante == null } },
        {
            name: "Modificación",
            options: {
                filter: true,
                sort: true,
                display:showDay&& (idVacante ==null ),
                customBodyRender: (value) => {
                    let color="#A91A1A"
                    let txt=COLOR_THIRD
                    
                    if(value<7)
                        { color="#266818"
                    // txt=COLOR_THIRD
                }
                    
                    


                    return (
                    <div style={{ position: 'relative', textAlign: 'center' ,display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'}}>
                        <div style={{
                            width: '50px',
                            borderRadius:5,
                            color:txt,
                            fontWeight: 'bold',
                            backgroundColor: color,

                            }}
                        >
                            {value}
                        </div>
                    </div>
                    );
                },
                setCellProps: () => ({
                    style: {
                    textAlign: 'center'
                    }
                })
                }
        }


    ];
    const options = {
        selectableRowsHideCheckboxes:true,
        print:false,
        viewColumns:false,
        textLabels: { body: { noMatch: 'No hay vacantes activas', } },
        onRowClick:(rowData)=>openVacanteDetail(rowData),
        customToolbar: () => {
            return (
                <IconButton style={{ order: -1 }} onClick={()=> setOpenModal(true)}> <Add /> </IconButton>
            );
        }
    };
    return (
            <MUIDataTable
                title={""}
                data={listVacantes}
                columns={columns}
                options={options}
                sx={{with: 400}} />
    )
}

export default TableVacantes