import { ChangeTitle } from "Components/menu/funciones"
import MUIDataTable from "mui-datatables"
import { useState } from "react"
import DetailBaja from "./DetailBaja/DetailBaja"
import UseBajas from "./UseBajas"

import { Box, Stack } from "@mui/material"
import Skeleton from "@mui/material/Skeleton"

export default function SolicitudesBajas() {
	ChangeTitle("Solicitudes de bajas")
	const [reloadBajas, setReloadBajas] = useState(false)
	const reload = () => {
		setReloadBajas(!reloadBajas)
	}
	const { bajas, loading } = UseBajas("", { reload: reloadBajas })
	const [open, setOpen] = useState(false)
	const [baja, setBaja] = useState({})
	const columnNoShow = {
		options: {
			filter: false,
			sort: false,
			display: false,
		},
	}
	const columns = [
		{ name: "id", ...columnNoShow },
		{ name: "collaborator", ...columnNoShow },
		{ name: "request_collaborator", ...columnNoShow },
		{ name: "name_collaborator", label: "Colaborador" },
		{ name: "name_request_collaborator", label: "Solicitante" },
		{ name: "type", label: "Tipo de baja" },
		{ name: "termination_status", label: "Estado Solicitud" },
		{ name: "termination_date", label: "Fecha de baja" },
		{ name: "request_date", label: "Fecha de solicitud" },
		{ name: "approval_date", label: "Fecha VoBo RH" },
	]
	const options = {
		textLabels: {
			body: {
				noMatch: "no hay solicitudes",
			},
		},
		filterType: "checkbox",
		search: true,
		filter: true,
		download: true,
		print: false,
		viewColumns: false,
		onRowClick: (rowData, rowMeta) => {
			setOpen(true)
			setBaja(bajas[rowMeta.dataIndex])
		},

		selectableRows: "none",
	}
	//Esqueleto de tabla de solicitudes de bajas
	if (loading) return <SkeletonTable />

	return (
		<div>
			<MUIDataTable
				data={bajas}
				columns={columns}
				options={options}
			/>
			<DetailBaja
				key={"detailBaja"}
				baja={baja}
				open={open}
				setOpen={setOpen}
				reload={reload}
			/>
		</div>
	)
}

function SkeletonTable() {
	let componente = []
	let componente2 = []

	for (let index = 0; index < 8; index++) {
		componente.push(
			<Skeleton
				key={`SkeletonBaja${index}`}
				variant="rectangular"
				width={"80vw"}
				height={"5vh"}
			/>,
		)
	}
	for (let index = 0; index < 8; index++) {
		componente2.push(
			<Stack
				key={`StackBaja${index}`}
				spacing={1}
				direction={"row"}
			>
				{componente}
			</Stack>,
		)
	}

	return (
		<Box sx={{ width: "100%" }}>
			<Stack spacing={2}>
				<Skeleton
					variant="rectangular"
					width={"96vw"}
					height={"10vh"}
				/>
				{componente2}
			</Stack>
		</Box>
	)
}
