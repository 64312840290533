import { styled } from "@mui/material/styles"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
// PDF View
// import { toast } from "sonner"
import { Skeleton, Stack } from "@mui/material"
import {
	ProgressBar,
	SpecialZoomLevel,
	Viewer,
	Worker,
} from "@react-pdf-viewer/core"
import { COLOR_PRIMARY } from "../../Constants/Styles"
import { getApiCursos, getLinkPDF } from "../../Services/Api_cursos"
import { Box } from "@mui/material"
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
		overflowY: "auto",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}))

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	// Estilos personalizados para el scrollbar
	"&::-webkit-scrollbar": {
		width: "4px",
	},
	"&::-webkit-scrollbar-thumb": {
		backgroundColor: theme.palette.primary.main,
		borderRadius: "6px",
	},
	"&::-webkit-scrollbar-track": {
		backgroundColor: theme.palette.background.default,
		borderRadius: "10px",
	},
	padding: "100px",
}))
/**
 * @type {Button}
 */
export const ButtonDialog = ({ label, ...props }) => {
	const ButtonS = styled(Button)({
		color: COLOR_PRIMARY,
		"&:hover": {
			borderColor: COLOR_PRIMARY,
			boxShadow: `0 0 0 0.1rem ${COLOR_PRIMARY}`,
		},
		// '&:active': {
		//   boxShadow: 'none',
		//   backgroundColor: '#0062cc',
		//   borderColor: '#005cbf',
		// },
		// "&:focus": {
		// 	boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
		// },
	})
	return <ButtonS {...props}>{label}</ButtonS>
}

export function DialogForm({
	open,
	setOpen,
	title,
	disabledButtons,
	accept = () => {},
	children,
	buttonText = "Aceptar",
}) {
	return (
		<BootstrapDialog
			open={open}
			onClose={() => setOpen(false)}
		>
			<DialogTitle id="Dialog-Form">{title}</DialogTitle>
			<CustomDialogContent dividers>{children}</CustomDialogContent>
			<DialogActions sx={{ alignContent: "center", justifyContent: "center" }}>
				<ButtonDialog
					label="Cancelar"
					disabled={disabledButtons}
					onClick={() => setOpen(false)}
					autoFocus={false}
				/>

				<ButtonDialog
					label={buttonText}
					disabled={disabledButtons}
					autoFocus={false}
					onClick={accept}
				/>
			</DialogActions>
		</BootstrapDialog>
	)
}
/**
 * Componente ViewPDF
 *
 * Este componente proporciona una vista de PDF con opciones de descarga y visualización.
 *
 * @component
 * @example
 * // Ejemplo de uso:
 * <ViewPDF
 *   title="Documento PDF"
 *   open={true}
 *   setOpen={setOpen}
 *   pdfUrl="https://example.com/documento.pdf"
 * />
 *
 * @param {object} props - Propiedades del componente.
 * @param {string} props.title - Título de la ventana de diálogo.
 * @param {boolean} props.open - Estado que indica si la ventana de diálogo está abierta.
 * @param {function} props.setOpen - Función para cambiar el estado de apertura de la ventana de diálogo.
 * @param {string} props.pdfUrl - URL del archivo PDF que se va a mostrar.
 * @returns {ReactNode} - El componente ViewPDF.
 */
export function ViewPDF({ title, open, setOpen, pdfUrl = "" }) {
	const token = useSelector((state) => state.user.token)
	const [url, setUrl] = useState(null)

	useEffect(() => {
		if (!!pdfUrl && open === true)
			getLinkPDF(token, pdfUrl).then((result) => {
				setUrl(result)
			})
	}, [pdfUrl, token, open])

	const handleDownload = async () => {
		let response = await getApiCursos("link", token, () => {}, { url: pdfUrl })
		window.open(response.data, "_blank")
	}
	return (
		<BootstrapDialog
			open={open}
			onClose={() => {
				setUrl(null)
				setOpen(false)
			}}
			fullWidth={true}
			maxWidth={"xl"}
		>
			<DialogTitle id="Dialog-Form">{title}</DialogTitle>
			<CustomDialogContent dividers>
				<div>
					{url ? (
						<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">
							<Viewer
								defaultScale={SpecialZoomLevel.PageWidth}
								fileUrl={url}
								renderLoader={(percentages) => (
									<div style={{ width: "80vh" }}>
										<ProgressBar progress={Math.round(percentages)} />
									</div>
								)}
							/>
						</Worker>
					) : (
						<Skeleton
							variant="rounded"
							height="80vh"
						/>
					)}
				</div>
			</CustomDialogContent>
			<DialogActions sx={{ alignContent: "center", justifyContent: "center" }}>
				<Button onClick={() => setOpen(false)}>Cerrar</Button>
				<Button onClick={handleDownload}>Descargar</Button>
			</DialogActions>
		</BootstrapDialog>
	)
}
export function ViewFile({
	title,
	open,
	setOpen,
	baseUrl = "",
	variant = "pdf",
}) {
	const token = useSelector((state) => state.user.token)
	const [url, setUrl] = useState(null)

	useEffect(() => {
		if (!!baseUrl && open === true)
			getLinkPDF(token, baseUrl).then((result) => {
				setUrl(result)
			})
	}, [token, open, baseUrl])

	const handleDownload = async () => {
		let response = await getApiCursos("link", token, () => {}, { url: baseUrl })
		window.open(response.data, "_blank")
	}
	function dialogClose() {
		setUrl(null)
		setOpen(false)
	}
	let contenido = (
		<Skeleton
			variant="rounded"
			width="100%"
			height="80vh"
		/>
	)

	if (url && variant === "pdf")
		contenido = (
			<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">
				<Viewer
					defaultScale={SpecialZoomLevel.PageWidth}
					fileUrl={url}
					// renderLoader={(percentages) => (
					// 	<div style={{ width: "100%" }}>
					// 		<ProgressBar progress={Math.round(percentages)} />
					// 	</div>
					// )}
				/>
			</Worker>
		)
	if (url && variant === "img")
		contenido = (
			<img
				src={url}
				alt="Pago"
			/>
		)

	return (
		<BootstrapDialog
			open={open}
			onClose={() => {
				dialogClose()
			}}
			fullWidth={true}
			maxWidth={"xl"}
		>
			<DialogTitle id="Dialog-Form">{title}</DialogTitle>
			<CustomDialogContent dividers>
				<div
					id="soy_yo"
					style={{
						display: "flex",
						width: "100%",
						alignItems: "center",
						alignContent: "center",
						justifyContent: "center",
					}}
				>
					<div
						style={{
							display: "flex",
							width: "80vw",
							alignItems: "center",
							alignContent: "center",
							justifyContent: "center",
						}}
					>
						{contenido}
					</div>
				</div>
			</CustomDialogContent>
			<DialogActions sx={{ alignContent: "center", justifyContent: "center" }}>
				<Button onClick={() => dialogClose()}>Cerrar</Button>
				<Button onClick={handleDownload}>Descargar</Button>
			</DialogActions>
		</BootstrapDialog>
	)
}

/**
 * Componente DialogConfirm
 *
 * Este componente proporciona un cuadro de diálogo de confirmación con opciones de aceptar y cancelar.
 *
 * @component
 * @example
 * // Ejemplo de uso:
 * <DialogConfirm
 *   open={true}
 *   setOpen={setOpen}
 *   message="¿Estás seguro de realizar esta acción?"
 *   disabledButtons={false}
 *   accept={() => handleAccept()}
 * />
 *
 * @param {object} props - Propiedades del componente.
 * @param {boolean} props.open - Estado que indica si el cuadro de diálogo está abierto.
 * @param {function} props.setOpen - Función para cambiar el estado de apertura del cuadro de diálogo.
 * @param {string} props.message - Mensaje de confirmación que se muestra en el cuadro de diálogo.
 * @param {boolean} props.disabledButtons - Estado que indica si los botones de aceptar y cancelar están deshabilitados.
 * @param {function} props.accept - Función que se ejecuta al hacer clic en el botón de aceptar.
 * @returns {ReactNode} - El componente DialogConfirm.
 */
export function DialogConfirm({
	open,
	setOpen,
	message,
	disabledButtons,
	accept = () => {},
}) {
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
		>
			<DialogTitle id="alert-dialog-title">{message}</DialogTitle>
			<DialogActions sx={{ alignContent: "center", justifyContent: "center" }}>
				<Button
					disabled={disabledButtons}
					onClick={() => setOpen(false)}
				>
					Cancelar
				</Button>
				<Button
					disabled={disabledButtons}
					autoFocus
					onClick={accept}
				>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	)
}

/**
 * Componente DialogComponent
 *
 * Este componente proporciona un cuadro de diálogo personalizable.
 *
 * @component
 *
 * @param {object} props - Propiedades del componente.
 * @param {boolean} props.open - Estado que indica si el cuadro de diálogo está abierto.
 * @param {function} props.setOpen - Función para cambiar el estado de apertura del cuadro de diálogo.
 * @param {string} [props.title] - Título del cuadro de diálogo.
 * @param {string} [props.propsTitle] - Permite editar el diseño del titulo.
 * @param {ReactNode} [props.children] - Contenido del cuadro de diálogo.
 * @param {Array<ReactNode>|ReactNode} [props.buttons] - Botones o elementos de acción para el cuadro de diálogo.
 * @param {string} [props.maxWidth="md"] - Tamaño máximo del cuadro de diálogo. Puede ser "xs", "sm", "md", "lg", "xl" o fijo.
 * @returns {ReactNode} - El componente DialogComponent.
 * @example
 * // Ejemplo de uso:
 * <DialogComponent
 *   title="Título del diálogo"
 *   open={true}
 *   setOpen={setOpen}
 *   buttons={[<Button onClick={() => setOpen(false)}>Cerrar</Button>]}
 *   maxWidth="sm"
 * >
 *   Contenido del diálogo...
 * </DialogComponent>
 */
export function DialogGeneral({
	title,
	open,
	setOpen,
	children,
	buttons,
	propsChildren,
	propsTitle,
	closeDialog = () => {},
	...props
}) {
	let componentes = []
	if (title)
		componentes.push(
			<DialogTitle
				key={"title"}
				id="Dialog-Form"
				textAlign="center"
				{...propsTitle}
			>
				{title}
			</DialogTitle>,
		)
	if (children)
		componentes.push(
			<CustomDialogContent
				key={"content"}
				dividers
				style={{ padding: "2vw" }}
				{...propsChildren}
			>
				{children}
			</CustomDialogContent>,
		)
	if (buttons)
		componentes.push(
			<DialogActions
				key={"actions"}
				sx={{ alignContent: "center", justifyContent: "center" }}
			>
				<Stack
					direction="row"
					spacing={3}
				>
					{buttons}
				</Stack>
			</DialogActions>,
		)

	return (
		<BootstrapDialog
			fullWidth={true}
			open={open}
			onClose={() => {
				closeDialog()
				setOpen(false)
			}}
			{...props}
		>
			{componentes}
		</BootstrapDialog>
	)
}
