import axios from "axios"
import { saveAs } from "file-saver"
import { toast } from "react-toastify"
import { getApiGeneralV2, post } from "./Api_Call"
import { useSelector } from "react-redux"
const URLAPI = process.env.REACT_APP_SERVER_URL
const URL = "rh/solicitudes/"
const URL_CAJA = {
	CAJA: `${URLAPI + URL}caja_ahorro`,
}
/**
 *
 * @param {*} token
 * @param {*} set_db
 * @param {*} filtro
 */
export async function getCajaAhorro(
	token,
	set_db,
	filtro = "",
	skeleton = () => {}
) {
	try {
		let response = await axios.get(`${URLAPI}${URL}caja_ahorro${filtro}`, {
			headers: { Authorization: "Token " + token },
		})
		response = response.data.data
		const array = []
		response.forEach((solicitudes) => {
			array.unshift(solicitudes)
		})
		set_db(array)
		skeleton(true)
	} catch (error) {
		toast.error(`Error: ${error} `, {
			position: "top-right",
		})
	}
}

/**
 * Enviar una solicitud tipo POST a la Api para una nueva solicitud  Caja De Ahorro
 * @description Crea la solicitud Post a caja de ahorro e implementa mensajes tipo toast en lo que se envía la solicitude
 *
 * @async
 * @param {*} token -Credenciales para la API
 * @param {*} datos -Nuevo estado para la solicitud
 * @param {{}} [params={}] - Filtro para las solicitud a modificar
 * @param {boolean} [actualizar=true]
 * @param {() => void} [setActualizar=() => {}]
 * @returns {void) => any}
 */
export const postCajaAhorro = async (
	token,
	datos,
	params = {},
	actualizar = true,
	setActualizar = () => {}
) => {
	const URL2 = URLAPI + URL + "caja_ahorro"
	await post(URL2, token, datos, params, actualizar, setActualizar)
}
/**
 * @description -Modifica El Estado de Una Solicitud de Ahorro
 * @param {*} token
 * @param {*} datos -Un objeto {	"id":2,"status":2}
 * @param {*} actualizar -Un objeto {	"id":2,"status":2}
 * @param {*} setActualizar -Un objeto {	"id":2,"status":2}
 * @returns
 */

export const putCajaAhorro = async (
	token,
	datos,
	actualizar = true,
	setActualizar = () => {}
) => {
	try {
		const request = await toast.promise(
			axios.put(URLAPI + URL + "caja_ahorro", datos, {
				headers: { Authorization: "Token " + token },
			}),
			{
				pending: "!Actualizando Solicitud¡",
				success: {
					render({ data }) {
						setActualizar(!actualizar)
						return `${data.data.response}`
					},
				},
				error: "A Ocurrido un error  🤯",
			}
		)
		return request.data
	} catch (error) {
		console.group("getApiCursos")
		console.error(error.response.data.error["tipo"])
		console.error(error.response.data.error["mensaje"])
		console.groupEnd("getApiCursos")
	}
}

/**
 *
 * @param {*} token acceso a la api
 * @param {*} set_db usesetState para guardar los datos
 * @param {*} skeleto usesetState para no mostrar el skeleto
 */
export async function getStatusCajaAhorro(token, set_db, skeleto = () => {}) {
	try {
		let response = await axios.get(`${URLAPI}${URL}status_caja`, {
			headers: { Authorization: "Token " + token },
		})
		response = response.data.data
		const array = []
		response.forEach((solicitudes) => {
			array.unshift(solicitudes)
		})
		set_db(array)
		skeleto(true)
	} catch (error) {
		toast.error(`Error: ${error} `, {
			position: "top-right",
		})
	}
}

/**
 * @description Descarga el archivo excel con los cálculos
 *
 * @param {Object} params parámetro de búsqueda
 * @param {string} token clave para la api
 * @returns Descarga un archivo excel
 */
export async function GetCajaAhorroDoc(token, params) {
	toast.promise(
		axios.get(URL_CAJA["CAJA"], {
			headers: { Authorization: "Token " + token },
			params: params,
			responseType: "blob",
		}),
		{
			pending: "Descargando archivo",
			success: {
				render({ data }) {
					data = data.data
					// console.log(data)
					saveAs(data, `Caja_Ahorro.xlsx`)
					return "Descarga Exitosa"
				},
			},
			error: "Ocurrió Un Error En La Descarga ",
		}
	)
}
