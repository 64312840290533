import Grid2 from "@mui/material/Unstable_Grid2"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { toast } from "react-toastify"
import React from "react"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { styled } from "@mui/material/styles"

/**
 * Componente ButtonGrid
 *
 * Un botón personalizado con varias opciones de configuración y manejo de clic.
 *
 * @component
 *
 * @example
 * <ButtonGrid2 label="Hacer clic" onClick={() => alert("¡Botón clicked!")} />
 *
 * @param {object} props - Las propiedades del componente.
 * @param {string} props.label - El texto que se mostrará en el botón.
 * @param {string} props.variant - Estilo de botón: "text" | "contained" | "outlined".
 * @param {boolean} props.disabled - Determina si el botón está deshabilitado.
 * @param {boolean} props.fullWidth - Indica si el botón debe ocupar todo el ancho disponible.
 * @param {string} props.propsG - props del Grid2
 * @param {React.ElementType} props.component - Tipo de elemento HTML o componente personalizado para renderings el botón.
 * @param {string} props.size - Tamaño del botón: "small" | "medium" | "large".
 * @param {function} props.onClick - Función a ejecutar cuando se hace clic en el botón.
 * @returns {JSX.Element} El elemento JSX que representa el botón personalizado.
 */
export const ButtonGrid2 = ({
	label = "",
	variant = "contained",
	fullWidth = true,
	propsG,
	...props
}) => {
	return (
		<Grid2 {...propsG}>
			<Button
				fullWidth={fullWidth}
				variant={variant}
				{...props}
			>
				{label}
			</Button>
		</Grid2>
	)
}
/**
 * Componente ButtonGrid
 *
 * Un botón personalizado con varias opciones de configuración y manejo de clic.
 *
 * @component
 *
 * @example
 * <ButtonGrid label="Hacer clic" onClick={() => alert("¡Botón clicked!")} />
 *
 * @param {object} props - Las propiedades del componente.
 * @param {string} props.label - El texto que se mostrará en el botón.
 * @param {React.ElementType} props.component - Tipo de elemento HTML o componente personalizado para renderings el botón.
 * @param {string} props.variant - Estilo de botón: "text" | "contained" | "outlined".
 * @param {boolean} props.disabled - Determina si el botón está deshabilitado.
 * @param {boolean} props.fullWidth - Indica si el botón debe ocupar todo el ancho disponible.
 * @param {string} props.size - Tamaño del botón: "small" | "medium" | "large".
 * @param {number} props.xs - Ancho del botón en pantallas extra pequeñas (XS).
 * @param {number} props.md - Ancho del botón en pantallas medianas (MD).
 * @param {number} props.xl - Ancho del botón en pantallas extra grandes (XL).
 * @param {function} props.onClick - Función a ejecutar cuando se hace clic en el botón.
 * @returns {JSX.Element} El elemento JSX que representa el botón personalizado.
 */
export const ButtonGrid = ({
	label = "Soy Un Botón",
	component = "button",
	variant = "contained",
	disabled = false,
	fullWidth = true,
	onClick = () => {},
	sxg = {},
	...props
}) => {
	let { xs, sm, md, lg, xl } = props
	return (
		<Grid2
			sx={sxg}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		>
			<Button
				{...props}
				disabled={disabled}
				fullWidth={fullWidth}
				variant={variant}
				component={component}
				onClick={onClick}
			>
				{label}
			</Button>
		</Grid2>
	)
}

/**
 * Componente ButtonIconGrid
 *
 * Un botón personalizado con varias opciones de configuración y manejo de clic.
 *@deprecated
 * @component
 *
 * @param {Object} props - Las propiedades del componente.
 * @param {() => void} [props.onClick=() => {}] - Función de devolución de llamada que se ejecuta cuando se hace clic en el botón.
 * @param {string} props.tooltip - Información adicional que se muestra como tooltip cuando se pasa el ratón sobre el botón.
 * @param {JSX.Element} props.icon - Elemento o componente de ícono que se muestra dentro del botón.
 * @param {boolean} [props.show=true] - Indica si el botón debe mostrarse o no. Por defecto, se muestra.
 * @param {Object} [props.sxg={}] - Propiedades adicionales de estilo específicas para el componente `Grid2`.
 * @param {Object} [props.sx={}] - Propiedades adicionales de estilo específicas para el componente `IconButton`.
 * @returns {JSX.Element} - El elemento JSX que representa el botón personalizado.
 *
 * @example
 * // Uso típico del componente ButtonIconGrid
 * <ButtonIconGrid
 *   tooltip="Haz clic para realizar una acción"
 *   icon={<IconoPersonalizado />}
 *   onClick={() => handleButtonClick()}
 *   show={true}
 *   xs={1}
 *   md={3}getApiDepartamentosButtonIconGrid
 *   sx={{ color: "black" }}
 *   sxg={{ margin: '10px' }}
 * />
 */
export function ButtonIconGrid({
	onClick = () => {},
	tooltip = "",
	icon,
	show = true,
	sxg = {},
	...props
}) {
	let { xs, sm, md, lg, xl } = props
	if (show)
		return (
			<Grid2
				sx={sxg}
				xs={xs}
				sm={sm}
				md={md}
				lg={lg}
				xl={xl}
			>
				<Tooltip title={tooltip}>
					<IconButton
						{...props}
						aria-label=""
						onClick={onClick}
						size="small"
					>
						{icon}
					</IconButton>
				</Tooltip>
			</Grid2>
		)
	else return <React.Fragment></React.Fragment>
}
/**
 * Componente `ButtonIconGrid2`
 *
 * Un botón personalizado con opciones de configuración y manejo de clic.
 *
 * @component
 *
 * @param {Object} props - Propiedades del componente `ButtonIconGrid`.
 * @param {string} [props.title="Soy de ayuda"] - Información adicional que aparece como tooltip al pasar el ratón sobre el botón.
 * @param {JSX.Element} props.icon - Elemento o componente de ícono que se muestra dentro del botón.
 * @param {() => void} [props.onClick=() => {}] - Función de devolución de llamada que se ejecuta al hacer clic en el botón.
 * @param {boolean} [props.show=true] - Indica si el botón debe mostrarse o no. Por defecto, se muestra.
 * @param {Object} [props.propsG={}] - Propiedades adicionales para el componente `Grid2` que envuelve al botón.
 * @param {Object} [props.propsT={}] - Propiedades adicionales para el componente `Tooltip` que proporciona información adicional al usuario.
 * @returns {JSX.Element} - Elemento JSX que representa el botón personalizado.
 *
 * @example
 * // Uso típico del componente `ButtonIconGrid`
 * <ButtonIconGrid
 *   title="Haz clic para realizar una acción"
 *   icon={<IconoPersonalizado />}
 *   onClick={() => handleButtonClick()}
 *   show={true}
 * 	 propsG={{
 *   md:3
 *   xs:1
 *   sx:{ margin: '10px' }
 * }}
 *   sx={{ color: "black" }}
 * />
 */
export function ButtonIconGrid2({
	title = "Soy un de ayuda",
	icon,
	show = true,
	onClick = () => {},
	propsG = {},
	propsT = {},
	...props
}) {
	if (show)
		return (
			<Grid2 {...propsG}>
				<Tooltip
					title={title}
					{...propsT}
				>
					<IconButton
						{...props}
						onClick={onClick}
					>
						{icon}
					</IconButton>
				</Tooltip>
			</Grid2>
		)
	else return <React.Fragment></React.Fragment>
}

/**
 * Componente ButtonIcon
 *
 * Un botón personalizado con varias opciones de configuración y manejo de clic.
 * @deprecated
 * @component
 *
 * @param {Object} props - Las propiedades del componente.
 * @param {string} props.title - Información adicional que se muestra como tooltip cuando se pasa el ratón sobre el botón.
 * @param {JSX.Element} props.icon - Elemento o componente de ícono que se muestra dentro del botón.
 * @param {boolean} [props.show=true] - Indica si el botón debe mostrarse o no. Por defecto, se muestra.
 * @param {() => void} [props.onClick=() => {}] - Función de devolución de llamada que se ejecuta cuando se hace clic en el botón.
 * @param {Object} [props.sx={}] - Propiedades adicionales de estilo específicas para el componente `IconButton`.
 * @returns {JSX.Element} - El elemento JSX que representa el botón personalizado.
 *
 * @example
 * // Uso típico del componente ButtonIconGrid
 * <ButtonIcon
 *   tooltip="Haz clic para realizar una acción"
 *   icon={<IconoPersonalizado />}
 *   onClick={() => handleButtonClick()}
 *   show={true} *
 *   sx={{ color: "black" }}
 * />
 */
export function ButtonIcon({
	title = " ",
	icon,
	show = true,
	onClick = () => {},
	propsT,
	...props
}) {
	if (show)
		return (
			<Tooltip
				title={title}
				{...propsT}
			>
				<IconButton
					onClick={onClick}
					{...props}
				>
					{icon}
				</IconButton>
			</Tooltip>
		)
	else return <React.Fragment></React.Fragment>
}
export function ButtonIconFile({
	title = "Soy un Mensaje",
	id = "",
	icon = <CloudUploadIcon />,
	accept = "",
	show = true,
	onChange = () => {},
	propsTooltip,
	propsInput,
	...props
}) {
	if (show === false) return <React.Fragment></React.Fragment>
	return (
		<React.Fragment>
			<Tooltip
				title={title}
				{...propsTooltip}
			>
				<div>
					<input
						accept={accept}
						style={{ display: "none" }}
						id={`file-input${id}`}
						type="file"
						onChange={onChange}
						{...propsInput}
					/>
					<label htmlFor={`file-input${id}`}>
						<IconButton
							component="span"
							{...props}
						>
							{icon}
						</IconButton>
					</label>
				</div>
			</Tooltip>
		</React.Fragment>
	)
}

/**
 * Componente ButtonFile
 *
 * Este componente proporciona un botón de carga de archivos personalizado con opciones configurable.
 *
 * @component
 * @example
 * // Ejemplo de uso:
 * <ButtonFile
 *   accept="image/*"
 *   label="Subir imagen"
 *   startIcon={<CloudUploadIcon />}
 *   operation={(file) => handleFileUpload(file)}
 *   color="primary"
 * />
 *
 * @param {object} props - Propiedades del componente.
 * @param {string} props.accept - Atributo 'accept' del input de tipo archivo, define los tipos de archivos permitidos.
 * @param {string} props.label - Etiqueta del botón.
 * @param {ReactNode} props.startIcon - Icono que se mostrará al inicio del botón.
 * @param {function} props.operation - Función de operación que se ejecutará al cargar un archivo.
 * @param {...any} props - Otras propiedades que se pueden pasar al componente Button de Material-UI.
 * @returns {ReactNode} - El componente ButtonFile.
 */
export const ButtonFile = ({
	label = "",
	accept = "",
	startIcon = <CloudUploadIcon />,
	operation = () => {},
	propsInput,
	...props
}) => {
	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	})
	const handleFileUpload = (event) => {
		const file = event.target.files[0]
		operation(file)
	}
	return (
		<Button
			component="label"
			variant="contained"
			startIcon={startIcon}
			onChange={handleFileUpload}
			{...props}
		>
			{label}
			<VisuallyHiddenInput
				type="file"
				accept={accept}
				{...propsInput}
			/>
		</Button>
	)
}
