import { TextField } from "@mui/material"
import React from "react"
import { OPTION_REJECT } from "../TextBaja"

export default function RejectTermination({ hidden = true, onChange }) {
	if (hidden) return null
	return (
		<TextField
			label={OPTION_REJECT}
			variant="outlined"
			fullWidth
			multiline
			onChange={(event) => {
				onChange(event.target.value)
			}}
		/>
	)
}
