
import { useState } from "react";
//
import { useDispatch, useSelector } from "react-redux";
import { useCheckPermission } from "../../Hooks/useCheckPermission";
import { reloadDataVacante, reloadTable } from "../../app/pageSlice";

//Api
import { recepcionRH, rechazoRH } from "../../Services/Api";

//Componentes

//Material Ui
import { FactCheck, History, PersonAddAlt1 } from '@mui/icons-material/';
import { Box, IconButton, TextField, Tooltip } from '@mui/material/';

//Material Icons
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { toast } from "react-toastify";
import { ButtonDialog, DialogGeneral } from "../Common/Dialog";

const ButtonsVacante = ({statusVacante, urlFirmado, setOpenCandidato, setOpenModalVoBo, setOpenModalHist, vacante }) => {
    const profiles = useSelector(state => state.user.profiles);
    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch()
    const isRH = useCheckPermission(profiles, 'RH')

    const [dialogData, setDialogData] = useState({})
    const [disabled,setDisabled] = useState(false)
    const [comentario,setComentario] = useState("")

    const TEXT_RECHAZAR='¿Está seguro de rechazar la vacante?'
    const TEXT_RECEPTIONS='¿Está seguro de confirmar la recepción?'

    let botones = []
  
    const reception = () => {
        setDisabled(true)
        recepcionRH(token,vacante).then(response => {
            toast.success("Se ha registrado la recepción de la vacante por RH")
            dispatch(reloadTable())
            dispatch(reloadDataVacante())
        }).catch( err => console.log(err))
        .finally(()=> setDisabled(false))
    }

    const rechazo =(comentario) => {
        setDisabled(true)
        rechazoRH(token,vacante,comentario).then(response => {
             toast.success("Se ha rechazado la vacante")
            dispatch(reloadDataVacante())
            dispatch(reloadTable())
        }).catch( err => {
            toast.error("Error al rechazar la vacante")
            console.log(err)
        }).finally(()=> setDisabled(false))
    }

    const optionReceptions = {
        'message': TEXT_RECEPTIONS,
        'action': reception
    }

	const optionRechazo = {
        'message': TEXT_RECHAZAR,
        'action': rechazo
    }


    const openModal = (option) => {
        setDialogData({
            title:option.message,
            option:option.action,
            open:true
        })
    }


    
    if( statusVacante < 9){
        botones.push({
            title: "VoBo's",
            color:"success",
            onClick:() => setOpenModalVoBo(true),
            icono: <FactCheck fontSize="inherit" />
            })

        if (isRH){
            botones.push({
                title: 'Cancelar Vacante',
                color:'error',
                disabled:disabled,
                onClick:() => openModal(optionRechazo),
                icono: <DangerousIcon fontSize="inherit" />
            })
        }
    }
    
    if (statusVacante >= 3 && statusVacante <= 8 && isRH)
        botones.push({
            title: 'Agregar candidato',
            color: 'success',
            onClick:() => setOpenCandidato(true),
            icono: <PersonAddAlt1 fontSize="inherit" />
        })



    if( isRH && statusVacante === 2)
        botones.push({
            'title': 'Recepción RH',
            'color': 'success',
            disabled:disabled,
            onClick:() => openModal(optionReceptions),
            'icono': <AssignmentTurnedInIcon fontSize="inherit" />
        })
    
    let contenido=null
    
    if(dialogData.title===TEXT_RECHAZAR)
        contenido=<TextField
        label="Observaciones*"
        rows={3}
		multiline
        
        fullWidth
        onChange={event=>{
            setComentario(event.target.value)}}
        ></TextField>
    
    return (
            <>
                <Box
                    display="flex"
                    justifyContent="right">
                    {botones.map((button) =>
                        <Tooltip key={button.title} title={button.title}>
                            <IconButton
                            color={button.color}
                            disabled={button.disabled}
                            onClick={button.onClick}
                            size="large"
                            >
                                {button.icono}
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Ver Historial" onClick={() => setOpenModalHist(true)}>
                        <IconButton color="primary" size="large">
                            <History fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Box>  
                <DialogGeneral
                    title={dialogData.title}
                    open={dialogData.open}
                    setOpen={setDialogData}
                    buttons={[
                        <ButtonDialog key={'cancelar'} disabled={disabled} onClick={()=>setDialogData(false)} label="Cancelar" />,
                        <ButtonDialog key={'aceptar'} disabled={disabled} onClick={()=>dialogData.option(comentario)} label="aceptar"/>
                ]}
                >
                {contenido} 
                </DialogGeneral>
            </>
    )
}

export default ButtonsVacante