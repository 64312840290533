import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
//Componentes
import VistaUsuario from "../Components/Capacitaciones/VistaUsuario"
import { changeTitle } from "../app/pageSlice"

const Capacitaciones = () => {
	const dispatch = useDispatch()

	const user = useSelector(state => state.user)

	useEffect(() => {
		dispatch(changeTitle("Mis Cursos"))
	}, [])
	return (
		<React.Fragment>
			<VistaUsuario id={user.colaborador.id} />
		</React.Fragment>
	)
}

export default Capacitaciones
