import React,{useState} from 'react';
import ListCompetencia from './ListCompetencia';
import { Grid, Box, Divider } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TranslateIcon from '@mui/icons-material/Translate';
import LaptopIcon from '@mui/icons-material/Laptop';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Competencias = ({expanded, handleChangePanel, listEducacion, setListEducacion, listCapacitacion, setListCapacitacion, listExperiencia, setListExperiencia, listIdiomas, setListIdiomas, listSoftware, setListSoftware, listAdicionales, setListAdicionales}) => {
    const [textEducacion,setTextEducacion]       = useState('')
    const [textCapacitacion,setTextCapacitacion] = useState('')
    const [textExperiencia,setTextExperiencia]   = useState('')
    const [textIdiomas,setTextIdiomas]           = useState('')
    const [textSoftware,setTextSoftware]         = useState('')
    const [textAdicionales,setTextAdicionales]   = useState('')

    const addItem = (list,item) =>{
        if(list === "Educacion") {
            setListEducacion([...listEducacion,{'id':0,"tipo":"Educacion", "descripcion":item}])
            setTextEducacion('')
        }
        else if (list === "Capacitacion"){
            setListCapacitacion([...listCapacitacion, { 'id': 0, "tipo": "Capacitacion", "descripcion": item }])
            setTextCapacitacion('')
        } 
        else if (list === "Experiencia"){
            setListExperiencia([...listExperiencia, { 'id': 0, "tipo": "Experiencia", "descripcion": item }])
            setTextExperiencia('')
        } 
        else if (list === "Idiomas"){
            setListIdiomas([...listIdiomas, { 'id': 0, "tipo": "Idiomas", "descripcion": item }])
            setTextIdiomas('')
        }
        else if (list === "Software"){
            setListSoftware([...listSoftware, { 'id': 0, "tipo": "Software", "descripcion": item }])
            setTextSoftware('')
        } 
        else if (list === "Adicionales"){
            setListAdicionales([...listAdicionales, { 'id': 0, "tipo": "Adicionales", "descripcion": item }])
            setTextAdicionales('')
        }  
    }

    const removeItem = (list,indexList) =>{
        if(list === "Educacion") setListEducacion(listEducacion.filter((item,index) => index !== indexList))
        else if(list === "Capacitacion") setListCapacitacion(listCapacitacion.filter((item,index) => index !== indexList))
        else if(list === "Experiencia") setListExperiencia(listExperiencia.filter((item,index) => index !== indexList))
        else if(list === "Idiomas") setListIdiomas(listIdiomas.filter((item,index) => index !== indexList))
        else if(list === "Software") setListSoftware(listSoftware.filter((item,index) => index !== indexList))
        else if(list === "Adicionales") setListAdicionales(listAdicionales.filter((item,index) => index !== indexList))
    }

    return (
        <Accordion expanded={expanded === 'panelCompetencias'} onChange={handleChangePanel('panelCompetencias')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header" >
                <Box component="b" sx={{fontSize:20,color:"#1d3454" }}> Competencias </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <ListCompetencia titulo={"Educación*"}  list={listEducacion} nameList={"Educacion"} addItem={addItem} removeItem={removeItem} textValue={textEducacion} setTextValue={setTextEducacion} Iconlist={SchoolIcon}/>
                    <ListCompetencia titulo={"Formación y Capacitación*"}  list={listCapacitacion} nameList={"Capacitacion"} addItem={addItem} removeItem={removeItem} textValue={textCapacitacion} setTextValue={setTextCapacitacion} Iconlist={CastForEducationIcon}/>
                    <Grid item xs={12} sx={{mt:5}}> <Divider/> </Grid>
                    <ListCompetencia titulo={"Experiencia (tiempo y/o area)*"}  list={listExperiencia} nameList={"Experiencia"} addItem={addItem} removeItem={removeItem} textValue={textExperiencia} setTextValue={setTextExperiencia} Iconlist={WorkIcon}/>
                    <ListCompetencia titulo={"Idiomas"}  list={listIdiomas} nameList={"Idiomas"} addItem={addItem} removeItem={removeItem} textValue={textIdiomas} setTextValue={setTextIdiomas} Iconlist={TranslateIcon}/>
                    <Grid item xs={12} sx={{mt:5}}> <Divider/> </Grid>
                    <ListCompetencia titulo={"Software"}  list={listSoftware} nameList={"Software"} addItem={addItem} removeItem={removeItem} textValue={textSoftware} setTextValue={setTextSoftware} Iconlist={LaptopIcon}/>
                    <ListCompetencia titulo={"Adicionales"}  list={listAdicionales} nameList={"Adicionales"} addItem={addItem} removeItem={removeItem} textValue={textAdicionales} setTextValue={setTextAdicionales} Iconlist={ThumbUpIcon}/>
                    
                </Grid>
            </AccordionDetails>
        </Accordion>
  )
}

export default Competencias