import { Box, Button, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { ButtonFile } from "../../Common/Buttons"
import { useFondoContext } from "../useContextFondo"
import { FONDO_STATUS } from "../ButtonsFondo"
import { useSelector } from "react-redux"
import { getLinkPDF } from "../../../Services/Api_cursos"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { getDocumentoFondo } from "../../../Services/Api_Fondo_Fijo"
import { ViewPDF } from "../../Common/Dialog"
import { PRIMARYCOLOR as PRIMARY_COLOR } from "../../../Constants/Styles"

export function ContentNF8({ solicitud }) {
	const token = useSelector((state) => state.user.token)
	const { setSendData } = useFondoContext()
	const [doc, setDoc] = useState(null)
	const { colaborador, quantity: cantidad } = solicitud

	useEffect(() => {
		let formData = new FormData()
		formData.append("fondo", solicitud?.id)
		formData.append("status", FONDO_STATUS.NF9)
		formData.append("doc", doc)
		setSendData(formData)
	}, [doc, setSendData, solicitud?.id])

	return (
		<Box justifyContent={"center"}>
			<Stack
				direction={"row"}
				spacing={3}
			>
				<Button
					fullWidth
					startIcon={<CloudDownloadIcon />}
					variant="contained"
					sx={{ backgroundColor: PRIMARY_COLOR }}
					onClick={() => {
						getDocumentoFondo(token, {
							colaborador,
							pdf: true,
							cantidad,
							fondo_type: true,
						})
					}}
				>
					Bajar Documento
				</Button>
				<ButtonFile
					label="Subir Documento"
					accept=".pdf"
					sx={{ backgroundColor: PRIMARY_COLOR }}
					operation={(e) => {
						setDoc(e)
					}}
					fullWidth
				/>
			</Stack>
			<Typography>{doc?.name}</Typography>
		</Box>
	)
}
export function ContentNF9({ solicitud }) {
	const [open, setOpen] = useState(false)
	console.log(solicitud)
	return (
		<Box textAlign="center">
			<Button
				onClick={() => setOpen(true)}
				variant="contained"
				sx={{ backgroundColor: PRIMARY_COLOR }}
			>
				Ver Documento
			</Button>
			<ViewPDF
				open={open}
				setOpen={setOpen}
				pdfUrl={solicitud?.file_signed}
			/>
		</Box>
	)
}

function ImgDownload({ url, open = true }) {
	const token = useSelector((state) => state.user.token)
	const [img, setImg] = useState(null)

	useEffect(() => {
		if (open) {
			getLinkPDF(token, url).then((result) => {
				setImg(result)
			})
		}
	}, [open, token, url])

	return (
		<Box
			sx={{
				bgcolor: "#cfe8fc",
				height: "50vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<img
				src={img}
				alt="Comprobante de pago"
				width="100%"
				height="100%"
			></img>
		</Box>
	)
}
