import React, { useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { getDocumentoFondo } from "../../../Services/Api_Fondo_Fijo"
import { ButtonFile } from "../../Common/Buttons"
//Material
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import Button from "@mui/material/Button"
import InputAdornment from "@mui/material/InputAdornment"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { PRIMARYCOLOR as PRIMARY_COLOR } from "../../../Constants/Styles"

export default function SelectMovimiento({ data, onChang, onChangDoc }) {
	const token = useSelector((state) => state.user.token)
	const [cantidad, setCantidad] = useState(null)
	const [nombre, setNombre] = useState("")

	const { type, colaborador, quantity, nombre_completo } = data
	let texto = [
		<Typography key="colaborador">{`Colaborador: ${nombre_completo}`}</Typography>,
		<Typography key="proceso">{`Proceso: ${type}`}</Typography>,
	]

	if (type !== "Nuevo")
		texto.push(
			<Typography key="cantidad">{`Fondo Fijo Actual $${quantity}`}</Typography>
		)

	if (type === "Cancelación") {
		return (
			<Stack spacing={1}>
				{texto}
				<Typography>{`Estas seguro de quitar el fondo fijo de este colaborador`}</Typography>
			</Stack>
		)
	}
	console.log(type)
	return (
		<Stack spacing={1}>
			{texto}
			<TextField
				type="number"
				value={cantidad}
				onChange={(e) => {
					let value = e.target.value
					if (type === "Decremento") {
						if (value < quantity) {
							setCantidad(value)
							onChang({ cantidad: value })
						} else toast.error("la cantidad debe ser menor a la actual")
					} else {
						setCantidad(value)
						onChang({ cantidad: value })
					}
				}}
				InputProps={{
					startAdornment: <InputAdornment position="start">$</InputAdornment>,
				}}
				label={type === "Nuevo" ? "Cantidad" : "Nueva Cantidad"}
			/>

			{type !== "Decremento" && (
				<Grid2
					container
					direction={"row"}
					justifyContent={"center"}
					spacing={3}
					columns={2}
				>
					<Grid2 xs={1}>
						<Button
							sx={{ backgroundColor: PRIMARY_COLOR }}
							startIcon={<CloudDownloadIcon />}
							variant="contained"
							onClick={() => {
								if (type === "Incremento") {
									if (cantidad > quantity)
										getDocumentoFondo(token, {
											colaborador: colaborador,
											pdf: true,
											cantidad,
											fondo_type: true,
										})
									else toast.error("La cantidad debe ser mayor a la anterior")
								} else if (cantidad > 0)
									getDocumentoFondo(token, {
										colaborador: colaborador,
										pdf: true,
										cantidad,
									})
								else toast.error("La cantidad debe ser mayor a 0")
							}}
						>
							Bajar Documento
						</Button>
					</Grid2>
					<Grid2 xs={1}>
						<ButtonFile
							sx={{ backgroundColor: PRIMARY_COLOR }}
							label="Subir documento"
							accept=".pdf"
							operation={(e) => {
								setNombre(e.name)
								onChangDoc(e)
							}}
						/>
					</Grid2>
					{nombre && (
						<Grid2>
							<Typography>Documento :{nombre}</Typography>
						</Grid2>
					)}
				</Grid2>
			)}
		</Stack>
	)
}
