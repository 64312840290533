import React from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux" //Token
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Typography from "@mui/material/Typography"
import { InputSelect } from "../Common/Inputs"
import { MESES } from "./constants_capacitaciones"
import { ButtonGrid2 } from "../Common/Buttons"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { getApiCursos } from "../../Services/Api_cursos"
import { postApiCursos, putApiCursos } from "../../Services/Api_cursos"
import { Grid2Autocomplete } from "../Common/Inputs"
function FormularioCursoColaborador({
	idColaborador = "",
	open = false,
	setOpen = () => {},
	actualizar,
	setActualizar = () => {},
	dataCurso = false,
	setDataCurso = () => {},
}) {
	const token = useSelector((state) => state.user.token) //Token
	const handleClose = () => {
		setOpen(false)
		setDataCurso(false)
		setData({
			mes: "",
			colaborador_id: idColaborador,
			area_id: "",
			curso_id: "",
		})
	}
	const [data, setData] = React.useState({
		mes: "",
		colaborador_id: idColaborador,
		area_id: "",
		curso_id: "",
	})
	const [cursos, setCursos] = React.useState([])

	useEffect(() => {
		getApiCursos("cursos", token, setCursos)
	}, [token])

	useEffect(() => {
		if (dataCurso !== false) setData(dataCurso)
	}, [dataCurso])
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Typography
							id="transition-modal-title"
							variant="h6"
							component="h2"
						>
							{dataCurso ? "Editar curso" : "Crear curso"}
						</Typography>
						<Grid
							container
							spacing={2}
							alignItems={"center"}
							justifyContent={"center"}
							alignContent={"center"}
						>
							<InputSelect
								md={10}
								xl={10}
								label="Mes"
								data={MESES}
								option={data["mes"]}
								operation={(event) => {
									setData({ ...data, mes: event.target.value })
								}}
							/>

							<Grid2Autocomplete
								label="Cursos"
								md={10}
								xl={10}
								valueId={data["curso_id"]}
								data={cursos}
								dataLabel="curso"
								operation={(event, newValue) => {
									if (newValue === null) setData({ ...data, curso_id: "" })
									else setData({ ...data, curso_id: newValue["id"] })
								}}
							/>
							<ButtonGrid2
								label={dataCurso ? "Actualizar curso" : "Crear curso"}
								onClick={() => {
									if (dataCurso === false)
										postApiCursos(
											"colaborador",
											token,
											data,
											{},
											actualizar,
											setActualizar
										).then(handleClose)
									else
										putApiCursos(
											"colaborador",
											token,
											data,
											{ dataUpdate: "", id: dataCurso["id"] },
											actualizar,
											setActualizar
										).then(handleClose)
								}}
								disabled={!(!!data["curso_id"] && data["mes"] !== "")}
							/>
						</Grid>
					</Box>
				</Fade>
			</Modal>
		</div>
	)
}

export default FormularioCursoColaborador
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "background.paper",
	// border: "2px solid #000",
	boxShadow: 24,
	p: 4,
}
