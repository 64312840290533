import { toast } from "sonner"
import { getLinkPDF } from "../../../../Services/Api_cursos"
/**
 *
 * @param {*} token
 * @param {*} linkPDF
 * @param {*} setUrlPDF
 * @param {*} db_cursos
 */
export const PDFlink = (token, linkPDF, setUrlPDF, db_cursos) => {
	toast.promise(
		getLinkPDF(token, linkPDF)
			.then((result) => {
				setUrlPDF(result)
			})
			.catch((error) => console.log(error)),
		{
			loading: `Descargando PDF ${db_cursos.curso}`,
			success: (data) => {
				return `PDF ${db_cursos.curso}`
			},
			error: "Error"
		}
	)
}
