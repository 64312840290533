import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { PRIMARYCOLOR } from "../../../Constants/Styles"

//Material UI
import { ButtonIcon } from "../../Common/Buttons"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"

//icons
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { CardBar } from "./EscolaresTables/CardComponent"
import { apiDeleteCar, apiGetCar } from "../../../Services/Api_Colaborador"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { DialogConfirm } from "../../Common/Dialog"
import FormCar from "../forms/FormCar"

export function GetData(colaborador, actualizar) {
	const token = useSelector((state) => state.user.token)
	const [cars, setCars] = useState([])
	useEffect(() => {
		apiGetCar(token, setCars, { colaborador: colaborador.id })
	}, [colaborador.id, token, actualizar])
	return { cars }
}

export default function Car({ colaborador, edit }) {
	const token = useSelector((state) => state.user.token)
	const [open, setOpen] = useState([])
	const [confirm, setConfirm] = useState(false)
	const [actualizar, setActualizar] = useState(true)
	const actualizarNow = () => setActualizar(!actualizar)
	const { cars } = GetData(colaborador, actualizar)

	const handleNew = () => {
		setOpen({ open: true })
	}
	function handleEdit(car) {
		setOpen({ open: true, edit: car })
	}
	function handleDelete(id) {
		apiDeleteCar(token, {
			id: id,
		}).then((response) => {
			actualizarNow()
			setConfirm(false)
		})
	}
	return (
		<React.Fragment>
			<Grid2
				container
				spacing={1}
			>
				<Grid2 xs={12}>
					<CardBar
						title="Tus Vehículos"
						edit={edit}
						buttonAdd={handleNew}
						color="#eeeeee"
					/>
				</Grid2>
				{cars.map((car) => {
					return (
						<Grid2 xs={2}>
							<CardComponen
								car={car}
								edit={edit}
								buttonEdit={() => handleEdit(car)}
								buttonDeleted={() => {
									setConfirm({ open: true, id: car.id, car: car.model })
								}}
							/>
						</Grid2>
					)
				})}
			</Grid2>
			<DialogConfirm
				open={confirm.open}
				setOpen={setConfirm}
				message={`¿Eliminar ${confirm.car}?`}
				accept={() => handleDelete(confirm.id)}
			/>
			<FormCar
				idColaborador={colaborador.id}
				open={open.open}
				edit={open.edit}
				setOpen={setOpen}
				actualizar={actualizarNow}
			/>
		</React.Fragment>
	)
}

export function CardComponen({ car, edit, buttonEdit, buttonDeleted }) {
	const { brand, model, year } = car
	const fontSize = 15

	return (
		<Card sx={{ flex: 1, minWidth: 170, borderRadius: 3, height: "100%" }}>
			<CardActions
				backgroundColor="red"
				sx={{ backgroundColor: PRIMARYCOLOR }}
			>
				<div
					style={{
						width: "100%",
						height: "50px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography
						sx={{ fontSize: 17, color: "#ffffff" }}
						variant="h7"
					>
						{model}
					</Typography>
					{edit && (
						<Stack
							direction="row"
							justifyContent="flex-end"
						>
							<ButtonIcon
								title="Editar"
								icon={<EditIcon />}
								onClick={buttonEdit}
								size="small"
								sx={{ color: "#ffffff" }}
							/>
							<ButtonIcon
								title="Eliminar"
								icon={<DeleteIcon />}
								onClick={buttonDeleted}
								size="small"
								sx={{ color: "#ffffff" }}
							/>
						</Stack>
					)}
				</div>
			</CardActions>

			<CardContent>
				<Typography
					sx={{ fontSize: fontSize }}
					color="text.secondary"
					gutterBottom
				>
					<span style={{ fontWeight: "bold" }}>Año :</span>
					{` ${year}`}
				</Typography>

				<Typography
					sx={{ fontSize: fontSize }}
					color="text.secondary"
					gutterBottom
				>
					<span style={{ fontWeight: "bold" }}>Marca :</span>
					{` ${brand}`}
				</Typography>
			</CardContent>
		</Card>
	)
}
