import { useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

//api
import { noHaceCursos } from "#api/Api"
import {
	cambiarContraseña,
	modificarDatosGeneralesColaborador,
} from "#api/ApiColaboradores"

//mui
import SpeedDial from "@mui/material/SpeedDial"
import SpeedDialAction from "@mui/material/SpeedDialAction"
import { styled } from "@mui/material/styles"

//modales
import ModalBajaColaborador from "../ModalBajaColaborador"
import ModalDatosGenerales from "../ModalDatosGenerales"

//icons
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned"
import CloudDoneIcon from "@mui/icons-material/CloudDone"
import CloudOffIcon from "@mui/icons-material/CloudOff"
import EditIcon from "@mui/icons-material/Edit"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import RuleFolderIcon from "@mui/icons-material/RuleFolder"
import SendIcon from "@mui/icons-material/Send"
import DialogPersonalApproval from "./DialogPersonalApproval"
import MailLockIcon from "@mui/icons-material/MailLock"
import DialogEmailPassword from "./DialogEmailPassword"
import { COLOR_PRIMARY } from "#constants/Styles"
import SpeedDialIcon from "@mui/material/SpeedDialIcon"

export default function ButtonsColaborador({
	dataColaborador,
	reloadNow,
	hidden,
}) {
	const { id: id_colaborador } = dataColaborador
	const token = useSelector((state) => state.user.token)
	//Dialogs Open
	const [openGenerales, setOpenGenerales] = useState(false)
	const [openBaja, setOpenBaja] = useState(false)
	const [openPersonal, setOpenPersonal] = useState(false)
	const [openEmail, setOpenEmail] = useState(false)

	const { verificado, realiza_cursos, no_empleado = "" } = dataColaborador

	const list_buttons = [
		{
			name: "Acceso Correo",
			icon: <MailLockIcon />,
			onClick: () => setOpenEmail(true),
			notView: !no_empleado.includes("VC"),
		},
		{
			name: "Revisar datos",
			icon: <RuleFolderIcon />,
			onClick: () => setOpenPersonal(true),
			notView: verificado !== false,
		},
		{
			name: "Editar Datos Generales",
			icon: <EditIcon />,
			onClick: () => setOpenGenerales(true),
		},
		{
			name: "Reenviar accesos",
			icon: <SendIcon />,
			onClick: () => reenviarAccesos(token, id_colaborador),
		},
		{
			name: "Baja de colaborador",
			icon: <AssignmentReturnedIcon />,
			onClick: () => setOpenBaja(true),
		},
		{
			name: "Este colaborador no realiza cursos",
			icon: <CloudOffIcon />,
			onClick: () => noHace(token, id_colaborador, reloadNow),
			notView: !realiza_cursos,
		},
		{
			name: "Este colaborador  realiza cursos",
			icon: <CloudDoneIcon />,
			onClick: () =>
				editar_campo(token, id_colaborador, true, "realiza_cursos", reloadNow),
			notView: realiza_cursos,
		},
	]

	return (
		<div style={{ position: "relative" }}>
			<StyledSpeedDial
				ariaLabel="Menu Colaborador"
				hidden={hidden}
				icon={<MoreVertIcon sx={{ color: COLOR_PRIMARY }} />}
				direction={"left"}
				sx={{
					// position: "absolute",
					top: "5vh",
					"& .MuiSpeedDial-fab": {
						backgroundColor: "#0FFFFF00", // Cambia esto por el color que desees
						boxShadow: "none",
						"&:hover": {
							backgroundColor: "#0FFFFF00", // Color al hacer hover
						},
					},
				}}
			>
				{list_buttons.map((action) => {
					if (action.notView) {
						return null
					}
					return (
						<SpeedDialAction
							key={action.name}
							icon={action.icon}
							tooltipTitle={action.name}
							onClick={action.onClick}
						/>
					)
				})}
			</StyledSpeedDial>
			<ModalDatosGenerales
				open={openGenerales}
				setOpen={setOpenGenerales}
				colaboradorData={dataColaborador}
				reload={reloadNow}
			/>
			<ModalBajaColaborador
				open={openBaja}
				setOpen={setOpenBaja}
				colaboradoID={dataColaborador.id}
				reload={reloadNow}
			/>
			<DialogPersonalApproval
				open={openPersonal}
				setOpen={setOpenPersonal}
				colaborador={dataColaborador}
				reloadNow={reloadNow}
			/>
			<DialogEmailPassword
				open={openEmail}
				setOpen={setOpenEmail}
				colaborador={dataColaborador}
				reloadNow={reloadNow}
			/>
		</div>
	)
}

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
	position: "absolute",
	"&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
		bottom: theme.spacing(0),
		right: theme.spacing(0),
	},
	"&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
		top: theme.spacing(0),
		left: theme.spacing(0),
	},
}))

const reenviarAccesos = (token, id_colaborador) => {
	toast.promise(
		cambiarContraseña(token, id_colaborador),
		{
			pending: "Enviando accesos...",
			success: {
				render({ data }) {
					return `Accesos enviados correctamente, el usuario es ${data.username} y la nueva contraseña es ${data.password}`
				},
			},
			error: "Hubo un error actualizando los datos",
		},
		{ autoClose: false, closeOnClick: false },
	)
}

const noHace = (token, id_colaborador, reloadNow) => {
	toast.promise(noHaceCursos(token, id_colaborador), {
		pending: "Actualizando estatus...",
		success: {
			render({ data }) {
				reloadNow()
				return `Este Colaborador ya no realiza cursos`
			},
		},
		error: "Hubo un error actualizando los datos",
	})
}

function editar_campo(token, id_colaborador, valor, campo, reloadNow) {
	toast.promise(
		modificarDatosGeneralesColaborador(token, id_colaborador, campo, valor),
		{
			pending: "Enviando petición ...",
			success: {
				render({ data }) {
					reloadNow()

					return "El Colaborador puede hacer cursos"
				},
			},
			error: "Error al actualizar los datos",
		},
	)
}
