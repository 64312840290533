import { useEffect, useState } from 'react';
import {getHistorialCandidato} from '../../../Services/Api'
import {Grid, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useSelector } from "react-redux"
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ModalHistorialCandidato = ({open, setOpen, candidato}) => {
    const token = useSelector(state => state.user.token);
    const [historial,setHistorial] = useState([])
    const [loading,setLoading] = useState(true)


    useEffect(()=>{
        if(candidato == null || !open)
            return
        setLoading(true)
        getHistorialCandidato(token,candidato.id).then(response => {
            setHistorial(response.data)
            setLoading(false)
        })
    },[candidato, open, token])
    
    if(!open || candidato == null) return

    let contenido=[]

    if(loading)
        contenido= <LoadingHistory/>
    else
        contenido= <Timeline  position="left">
        {historial.map((log, index) => {
            let action=log.accion
            let icon = <></>

            if (action === 'Creacion')
                icon = <TimelineDot color='primary'><CreateIcon /></TimelineDot>

            if (action === 'Aprobado')
                icon = <TimelineDot color="success"> <CheckCircleIcon /> </TimelineDot>

            if (action === 'Descartado')
                icon = <TimelineDot color="error"> <CancelIcon /> </TimelineDot>

            if (action === 'Nuevo')
                icon = <TimelineDot color="primary"> <PersonAddAlt1Icon /> </TimelineDot>

                if (action === 'Entrevistado')
                icon = <TimelineDot color="success"> <HowToRegIcon /> </TimelineDot>

            if (action === 'Final')
                icon = <TimelineDot color="success"> <EmojiEventsIcon /> </TimelineDot>

            if (action === 'Agendar')
                icon = <TimelineDot color="primary"> <CalendarMonthIcon /> </TimelineDot>

            return(
                <TimelineItem key={'hist' + index}>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary" >
                        {log.fecha}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        {icon}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span" fontWeight={'bolder'} fontSize={18}>
                            {log.name }
                        </Typography>
                        <Typography> {log.descripcion} </Typography>
                    </TimelineContent>
                </TimelineItem>
            )
        })}
    </Timeline>
    return(
        <Dialog open={open} maxWidth={'md'} fullWidth={true}  onClose={()=>setOpen(false)}>
            <DialogTitle sx={{ fontSize: 24, fontWeight: 'bold', color: "#555" }}>Historial de {candidato.nombre} </DialogTitle>
            <DialogContent>
                <Grid container sx={{mt:0}} spacing={3}>
                    {contenido}
                </Grid>
            </DialogContent>
            
        </Dialog>
    )
}

export default ModalHistorialCandidato

function LoadingHistory(){
    let radio=60
    return (
        <Stack 
        width={"100%"}
            direction={'row'} 
            spacing={2} 
            justifyContent={'center'} 
            alignContent={'center'} 
            alignItems={"center"}
            padding={3}
            
        >
            <Skeleton variant="rounded" width={"100%"} height={70} />
            <Skeleton variant="circular" width={radio} height={radio} />
            <Skeleton variant="rounded" width={"100%"} height={30} />
        </Stack>
    );
}