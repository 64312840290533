import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useCheckPermission } from "../../../Hooks/useCheckPermission"

//Api
import {
	getMisColaboradores,
	getPerfilDetail,
	getPerfiles,
	getSucursales,
	saveVacante,
} from "../../../Services/Api"

//componentes
import { GridInputSelect } from "../../Common/Inputs"
import { InputGrid2, InputSelectGrid2 } from "../../Common/Inputs2"

//Material
import {
	Box,
	DialogActions,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"

//icons
import AddCircleIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/Delete"
import SchoolIcon from "@mui/icons-material/School"
import TrackChangesIcon from "@mui/icons-material/TrackChanges"
import { ButtonDialog, DialogGeneral } from "../../Common/Dialog"
import { toast } from "react-toastify"

export default function VacanteNew({
	open,
	setOpen,
	detail = null,
	actualizar,
}) {
	const token = useSelector((state) => state.user.token)
	const dataColaborador = useSelector((state) => state.user.colaborador)
	const profiles = useSelector((state) => state.user.profiles)
	const [btnDisabled, setbtnDisabled] = useState(false)
	//data
	const [listPerfiles, setListPerfiles] = useState([])
	const [listSuc, setListSuc] = useState([])
	const [listColaborador, setListColaborador] = useState([])
	const [listaColabReemplazo, setListaColabReemplazo] = useState([])
	//formulario
	const [sucursal, setSucursal] = useState(
		detail?.sucursal_id ? detail?.sucursal_id : ""
	)
	const [perfilVacante, setPerfilVacante] = useState(
		detail?.old_profile_id | ""
	)
	const [jefe, setJefe] = useState(detail?.authorization | "")

	const [tipoVac, setTipoVac] = useState(detail ? "Reemplazo" : "")
	const [colaborador, setColaborador] = useState(detail?.colaborador | "")

	const [sueldo, setSueldo] = useState(0)
	const [bono, setBono] = useState(0)
	const [garantia, setGarantia] = useState(0)
	const [duracion, setDuracion] = useState("")

	const isRH = useCheckPermission(profiles, "RH")
	const [qualities, setQualities] = useState({})
	const list_qualities = [
		"Responsabilidades",
		"Educación",
		"Experiencia",
		"Adicionales",
	]

	const defaultPerfil = useMemo(
		() =>
			listPerfiles.filter((v) => {
				return v.value === perfilVacante
			}),
		[listPerfiles, perfilVacante]
	)
	const defaultJefe = useMemo(
		() =>
			listColaborador.filter((v) => {
				return v.value === jefe
			}),
		[listColaborador, jefe]
	)
	const defaultColaborador = useMemo(
		() =>
			listaColabReemplazo.filter((v) => {
				return v.value === colaborador
			}),
		[listaColabReemplazo, colaborador]
	)
	let editFile = !!detail

	function save() {
		let validar = [sucursal, perfilVacante, jefe, tipoVac, sueldo, duracion]

		let needData = validar.filter((dato) => {
			return !dato ? true : false
		})

		if (
			needData.length ||
			((tipoVac === "Reemplazo" || tipoVac === "Promoción") && !colaborador)
		) {
			toast.error("Debes de llenar todos los campos requeridos")
			return
		}
		setbtnDisabled(true)
		saveVacante(
			token,
			perfilVacante,
			jefe,
			tipoVac,
			sueldo,
			bono,
			garantia,
			duracion,
			sucursal,
			colaborador,
			qualities.Responsabilidades,
			qualities.Educación,
			qualities.Experiencia,
			qualities.Adicionales
		)
			.then((response) => {
				actualizar()
				setOpen(false)
				toast.success("La vacante ha sido creada con exito")
			})
			.catch((error) => alert(error))
			.finally(() => setbtnDisabled(false))
	}

	const changePerfil = (perfil) => {
		setPerfilVacante(perfil)
		getPerfilDetail(token, perfil)
			.then((response) => {
				let competencias = response.perfil_competencia
				let cualidades = {}
				list_qualities.forEach((quality) => {
					let qualityArray = []
					competencias
						.filter((competition) => competition.tipo === quality)
						.map((quality) => qualityArray.push(quality.descripcion))
					cualidades = { ...cualidades, [quality]: qualityArray }
				})
				setQualities(cualidades)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	useEffect(() => {
		changePerfil(perfilVacante)
	}, [perfilVacante])

	useEffect(() => {
		async function getData() {
			try {
				const perfiles = await getPerfiles(token)
				const sucursales = await getSucursales(token)
				const colaboradores = await getMisColaboradores(
					token,
					dataColaborador.id
				)

				let perfilesArray = perfiles.map((perfil) => ({
					label: perfil.nombre,
					value: perfil.id,
				}))
				setListPerfiles(perfilesArray)

				let sucursalesArray = sucursales.map((sucursal) => ({
					value: sucursal.id,
					label: sucursal.nombre,
				}))
				setListSuc(sucursalesArray)

				let colaboradorArray = []
				let reemplazoArray = []
				colaboradores.map((colaborador) => {
					reemplazoArray.push({
						value: colaborador.id,
						label: `${colaborador.nombre} ${colaborador.apellido_paterno}`,
					})

					if (!colaborador.baja)
						colaboradorArray.push({
							value: colaborador.id,
							label: `${colaborador.nombre} ${colaborador.apellido_paterno}`,
						})
					return null
				})
				setListaColabReemplazo(reemplazoArray)
				setListColaborador(colaboradorArray)
			} catch (error) {}
		}
		if (open) getData()

		// getMisColaboradores(token, dataColaborador.id)
		// 	.then((response) => {
		// 		let arrayColab = []
		// 		let arrayReemplazo = []
		// 		response.map((colab) => {
		// 			arrayReemplazo.push({
		// 				value: colab.id,
		// 				label: colab.nombre + " " + colab.apellido_paterno,
		// 			})
		// 			console.log(colab.baja)
		// 			if (!colab.baja)
		// 				arrayColab.push({
		// 					value: colab.id,
		// 					label: colab.nombre + " " + colab.apellido_paterno,
		// 				})
		// 			return null
		// 		})
		// 		setListaColabReemplazo(arrayReemplazo)
		// 		setListColaborador(arrayColab)
		// 	})
		// 	.catch((error) => {
		// 		alert(error)
		// 	})
	}, [dataColaborador.id, open, token])

	const addItem = (list, item) => {
		if (qualities[list] !== undefined) {
			let add = [...qualities[list], item]
			setQualities({ ...qualities, [list]: add })
		}
	}

	const removeItem = (list, indexList) => {
		if (qualities[list] !== undefined) {
			let remove = qualities[list].filter((item, index) => index !== indexList)
			setQualities({ ...qualities, [list]: remove })
		}
	}

	let listsCompetencias = <></>

	if (Object.keys(qualities).length > 0) {
		let icon = {
			[list_qualities[0]]: <TrackChangesIcon color="primary" />,
			[list_qualities[1]]: <SchoolIcon color="primary" />,
			[list_qualities[2]]: <AddCircleIcon color="primary" />,
			[list_qualities[3]]: <AddCircleIcon color="primary" />,
		}
		let competencias = list_qualities.map((quality) => {
			return (
				<Grid2
					xs={6}
					key={quality}
				>
					<QualityView
						title={quality}
						quality={quality}
						icon={icon[quality]}
						addItem={addItem}
						removeItem={removeItem}
						objeto={qualities}
					/>
				</Grid2>
			)
		})
		listsCompetencias = (
			<Grid2
				container
				xs={11.5}
			>
				{competencias}
			</Grid2>
		)
	}

	let viewColab = <></>
	if (tipoVac === "Reemplazo" || tipoVac === "Promoción")
		viewColab = (
			<Grid2 xs={4}>
				<Autocomplete
					id="remplazo"
					disabled={editFile}
					disablePortal
					defaultValue={defaultColaborador[0]}
					options={listaColabReemplazo}
					sx={{ width: 400 }}
					onChange={(event, value) =>
						setColaborador(value == null ? null : value.value)
					}
					renderInput={(params) => (
						<TextField
							{...params}
							label={
								tipoVac === "Reemplazo" ? "¿Por Quien?*" : "¿Para Quien?* "
							}
						/>
					)}
				/>
			</Grid2>
		)
	let salario = [
		{
			label: "Sueldo Mensual*",
			value: sueldo,
			setOption: setSueldo,
		},
		{ label: "Bono trimestral", value: bono, setOption: setBono },
		{ label: "Garantía", value: garantia, setOption: setGarantia },
	]

	if ((!defaultPerfil[0] || !defaultJefe[0]) && !!detail)
		return <SkeletonVacante />
	return (
		<Grid2
			container
			spacing={3}
		>
			<InputSelectGrid2
				label={"Sucursal*"}
				value={sucursal}
				disabled={editFile}
				setValue={setSucursal}
				data={listSuc}
				dataID="value"
				GridProps={{ xs: 4 }}
			/>

			<Grid2 xs={4}>
				<Autocomplete
					id="perfil"
					disabled={editFile}
					disablePortal
					value={defaultPerfil[0]}
					options={listPerfiles}
					onChange={(event, value) =>
						changePerfil(value == null ? null : value.value)
					}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Perfil de puesto"
						/>
					)}
				/>
			</Grid2>
			<Grid2 md={4}>
				<Autocomplete
					id="jefe"
					disabled={editFile}
					disablePortal
					defaultValue={defaultJefe[0]}
					options={listColaborador}
					onChange={(event, value) => {
						setJefe(value == null ? null : value.value)
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Jefe directo"
						/>
					)}
				/>
			</Grid2>
			<Grid2
				item
				md={12}
			>
				<Typography sx={{ fontSize: 20, fontWeight: "bold", color: "#555" }}>
					Razones de la vacante
				</Typography>
			</Grid2>
			<InputSelectGrid2
				label={"Tipo vacante*"}
				value={tipoVac}
				disabled={editFile}
				setValue={setTipoVac}
				dataID="value"
				data={[
					{ value: "Nueva Creación", label: "Nueva Creación" },
					{ value: "Reemplazo", label: "Reemplazo" },
					{ value: "Becario", label: "Becario" },
				]}
				GridProps={{ xs: 6 }}
			/>

			{viewColab}
			<Grid2 md={12}>
				<Typography sx={{ fontSize: 20, fontWeight: "bold", color: "#555" }}>
					Sueldo
				</Typography>
			</Grid2>
			{salario.map((tipo) => (
				<InputGrid2
					key={tipo.label}
					{...tipo}
					propsGrid={{ xs: 3 }}
					type="number"
				/>
			))}
			<InputSelectGrid2
				label={"Duración de la garantía"}
				value={duracion}
				dataID="value"
				setValue={setDuracion}
				data={[
					{ value: "N/A", label: "N/A" },
					{ value: "Indefinido", label: "Indefinido" },
					{ value: "3 meses", label: "3 meses" },
					{ value: "6 meses", label: "6 meses" },
				]}
				GridProps={{ xs: 3 }}
			/>
			{listsCompetencias}
			<Grid2 xs={12}>
				<Divider />
				<Stack
					sx={{ alignContent: "center", justifyContent: "center" }}
					direction="row"
					spacing={2}
				>
					<ButtonDialog
						label="Cancelar"
						onClick={() => {
							actualizar()
							setOpen(false)
						}}
					></ButtonDialog>
					<ButtonDialog
						label="Aceptar"
						onClick={() => save()}
					></ButtonDialog>
				</Stack>
			</Grid2>
		</Grid2>
	)
}

const QualityView = ({ title, quality, objeto, icon, addItem, removeItem }) => {
	const [text, setText] = useState("")
	return (
		<div>
			<Grid2
				container
				padding={2}
			>
				<Grid2
					md={11}
					sx={{ pt: "0px" }}
				>
					<Box
						component="b"
						sx={{ fontSize: 18, color: "#555" }}
					>
						{title}
					</Box>
					<TextField
						margin="dense"
						onChange={(event) => setText(event.target.value)}
						value={text}
						fullWidth
					/>
				</Grid2>
				<Grid2 md={1}>
					{" "}
					<IconButton
						aria-label="delete"
						sx={{ mt: 4 }}
						onClick={() => addItem(quality, text)}
					>
						{" "}
						<AddCircleIcon
							color="success"
							fontSize="large"
						/>{" "}
					</IconButton>{" "}
				</Grid2>
				<Grid2 md={12}>
					<List dense={false}>
						{objeto[quality]?.map((item, index) => {
							return (
								<Box key={item.id}>
									<ListItem
										secondaryAction={
											<IconButton
												edge="end"
												onClick={() => removeItem(quality, index)}
											>
												<DeleteIcon color="error" />
											</IconButton>
										}
									>
										<ListItemIcon>{icon} </ListItemIcon>
										<ListItemText primary={item} />
									</ListItem>
									<Divider />
								</Box>
							)
						})}
					</List>
				</Grid2>
			</Grid2>
		</div>
	)
}

const SkeletonVacante = () => {
	return (
		<Grid2 container={2}>
			<Grid2 xs={12}>
				<Skeleton
					variant="rectangular"
					width="100%"
					height={"80vh"}
				/>
			</Grid2>
		</Grid2>
	)
}
