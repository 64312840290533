import { api_rh_get } from "#api/API_RH"
import { useEffect, useState } from "react"

export default function UseBajas(url = "", params = {}) {
	const { typeData = [], open = true, reload = true } = params
	const [bajas, setBajas] = useState(null)
	const [loading, setLoading] = useState(true)
	
	const success = (response) => {
		setBajas(response)
		setLoading(false)
	}

	useEffect(() => {
		const controller = new AbortController()
		if (!open) return
		setBajas(null)
		api_rh_get(`baja${url}`, {
			success,
			signal: controller.signal,
		})
		return () => {
			controller.abort()
		}
	}, [open, url, reload])

	return { bajas: bajas || typeData, loading }
}
