import React, { useState } from "react"
import { useSelector } from "react-redux"
import { ButtonFile, ButtonGrid2 } from "../Common/Buttons"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"
import { apiPostAsset } from "../../Services/Api_inventario"

export default function AssetPictureUpload({ id, actualizar }) {
	const token = useSelector((state) => state.user.token)
	const [archivo, setArchivo] = useState({ file: new FormData(), url: "" })
	const [buttonDisable, setButtonDisable] = useState(false)
	const nameFile = "foto[]"
	function archivoSave(nameFile = "", files) {
		let formData = new FormData()
		let urls = []

		for (const file in files) {
			if (file === "length") break
			urls.push(URL.createObjectURL(files[file]))
			formData.append(nameFile, files[file])
		}
		// const imageUrl = URL.createObjectURL(file)
		setArchivo({ file: formData, url: urls[0] })
	}

	const handleDrop = (event) => {
		event.preventDefault()
		const file = event.dataTransfer.files
		archivoSave(nameFile, file)
	}

	const handleDragOver = (event) => {
		event.preventDefault()
	}
	const handlePaste = (event) => {
		const clipboardData = event.clipboardData || window.clipboardData
		const items = clipboardData.items

		for (let item of items) {
			if (item.type.indexOf("image") !== -1) {
				const blob = item.getAsFile()
				archivoSave(nameFile, blob)
				break
			}
		}
	}
	let generalWidth = 200
	let generalHeight = 255
	let generalBorder = 15
	return (
		<div
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			onPaste={handlePaste}
			style={{
				width: generalWidth,
				height: generalHeight,
				border: "2px solid",
				borderRadius: generalBorder,
				display: "flex",
				alignContent: "center",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			{archivo["url"] ? (
				<Grid
					container
					spacing={2}
					width={"100%"}
					height={"100%"}
					justifyContent={"space-between"}
				>
					<ButtonGrid2
						label="Borrar"
						size="small"
						variant="outlined"
						onClick={() => setArchivo({ file: new FormData(), url: "" })}
					/>
					<ButtonGrid2
						size="small"
						label="Subir"
						variant="outlined"
						disabled={buttonDisable}
						onClick={async () => {
							setButtonDisable(true)
							let response = await apiPostAsset(
								"ASSET_FOTOS",
								token,
								archivo["file"],
								{
									idAsset: id,
								},
								{ pending: "Subiendo imagen" }
							)
							if (response["success"]) {
								actualizar()
								setArchivo({ file: new FormData(), url: "" })
							}
							setButtonDisable(false)
						}}
					/>

					<Grid
						xs={12}
						sx={{}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<img
								alt="Imagen subida"
								src={archivo["url"]}
								width={"80%"}
								style={{
									borderRadius: generalBorder,
									maxHeight: generalHeight - 70,
								}}
							/>
						</div>
					</Grid>
				</Grid>
			) : (
				<ButtonFile
					variant="text"
					propsInput={{
						accept: "image/*",
						capture: "environment",
					}}
					sx={{
						width: "100%",
						height: generalHeight - 5,
						borderRadius: generalBorder - 11,
					}}
					operation={(file) => {
						archivoSave(nameFile, [file])
					}}
					startIcon={
						<AddAPhotoIcon
							fontSize="large"
							variant="contained"
							sx={{ width: "100%", height: generalHeight }}
						/>
					}
				/>
			)}
		</div>
	)
}
