import React, { useEffect, useState } from "react"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import {
	apiDeleteAssetFotos,
	apiGetAsset2,
} from "../../Services/Api_inventario"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { ButtonIcon } from "../Common/Buttons"
import { DialogConfirm } from "../Common/ModalConfirmacion"

export default function AssetPicture({ token, idAsset, refresh, permission }) {
	const [pictures, setPictures] = useState([])
	const [open, setOpen] = useState(false)
	const [actualizar, setActualizar] = useState(false)
	useEffect(() => {
		apiGetAsset2("ASSET_FOTOS", token, setPictures, {
			link: "",
			idAsset: idAsset,
		})
	}, [idAsset, token, actualizar, refresh])

	if (!pictures.length) return "No hay imágenes"

	return (
		<React.Fragment>
			<ImageList
				sx={{
					width: "100%", // pictures.length * 206,
					height: 268,
					// Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
					// transform: "translateZ(0)",
				}}
				cols={pictures.length + 1} //{pictures.length}
				// variant="quilted"
				//rowHeight={100}
				gap={5}
			>
				{pictures.map((item, index) => {
					return (
						<ImageListItem
							key={item.url_picture}
							cols={1}
							rows={1}
							sx={{ width: "200px" }}
						>
							<img
								style={{ borderRadius: "15px" }}
								src={item.url_picture}
								alt={item.id}
							/>
							<ImageListItemBar
								sx={{ borderRadius: "15px 15px 0 0" }}
								title={item.update_date}
								position="top"
								actionIcon={
									permission ? (
										<ButtonIcon
											title="Borrar"
											mensaje="¿Borrar Imagen?"
											sx={{ color: "red" }}
											aria-label={`star ${item.title}`}
											onClick={() => setOpen(item["id"])}
											icon={<DeleteOutlineIcon />}
										/>
									) : (
										<React.Fragment></React.Fragment>
									)
								}
								actionPosition="left"
							/>
						</ImageListItem>
					)
				})}
			</ImageList>
			<DialogConfirm
				open={open}
				setOpen={setOpen}
				message="¿Borrar Imagen?"
				accept={async () => {
					setOpen(false)
					let response = await apiDeleteAssetFotos(token, {
						id: open,
					})
					if (response["success"]) setActualizar(!actualizar)
				}}
			/>
		</React.Fragment>
	)
}
