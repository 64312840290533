import axios from 'axios'
const URLAPI = process.env.REACT_APP_SERVER_URL

export const saveColaborador = async(token, jsonColaborador) => {
    const request = await axios.post(URLAPI+'rh/colaboradores/',jsonColaborador,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const cambiarContraseña = async(token, colaborador_id, password = null) => {
    const request = await axios.put(URLAPI + 'core/user/cambiar_password',{colaborador_id, password},{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const getDatosGeneralesColaborador = async(token,colaborador_id) => {
    const request = await axios.get(URLAPI+'rh/colaboradores/datos_generales',{ params:{colaborador_id},headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const modificarDatosGeneralesColaborador = async(token,colaborador_id,campo) => {
    const request = await axios.put(URLAPI +'rh/colaboradores/'+colaborador_id, campo,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const bajaColaborador = async(token,colaborador_id,fecha_baja, motivo_baja) => {
    const request = await axios.post(URLAPI+'rh/baja_colaborador',{colaborador_id,fecha_baja,motivo_baja},{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const getColaboradoresPorPerfil = async(token,perfil_puesto_id) => {
    const request = await axios.get(URLAPI+'rh/colaboradores/colaboradores_por_perfil',{ params:{perfil_puesto_id},headers: { Authorization: 'Token ' + token }})
    return request.data
}