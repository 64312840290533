import { useEffect, useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, } from "react-router-dom";
import { logout } from '../../app/userSlice';
import { openDrawer } from '../../app/pageSlice';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton } from '@mui/material/';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import MenuListItems  from './ItemsMenu';
import logo from '../../Images/logo.jpg'
// Pages
import Colaboradores from '../../Pages/Colaboradores';
import PerfilesPuesto  from '../../Pages/PerfilesPuesto';
import Vacantes from '../../Pages/Vacantes';
// import Vacaciones from '../../Pages/Vacaciones';
import Capacitaciones from '../../Pages/Capacitaciones';
import CursosPorAprobar from '../Capacitaciones/CursosPorAprobar';
import Cursos from '../Capacitaciones/Cursos'; 
import Organigrama from '../../Pages/Organigrama';
import TuPerfil from '../../Pages/TuPerfil';
import SolicitudesCajaAhorro from '../../Pages/SolicitudesCajaAhorro';
import PrestamosSolicitudes from '../../Pages/PrestamosSolicitudes';
import Documentacion from '../../Pages/Documentacion';
import MatrizCursos from '../Capacitaciones/MatrizCursos';
import Uniformes from '../../Pages/Uniformes';

import { ToastContainer } from "react-toastify"
import { actualizarNotify } from '../../app/notificationSlice';
import { PRIMARYCOLOR, SECONDARYCOLOR } from '../../Constants/Styles';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open',  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                  width: theme.spacing(9),
                },
            }),
        },
    }),
);

const Menu = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [open, setOpen] = useState(false);
    const titlePage = useSelector(state => state.page.title);
    const user = useSelector(state => state.user);
    var dispatch = useDispatch()

    const version = localStorage.getItem('version')

    if(version == null || version !== process.env.REACT_APP_VERSION){
        localStorage.clear()
        dispatch(logout())
        window.location.reload(true);
    }
    
    const mdTheme = createTheme({
        palette: {
            mode: process.env.REACT_APP_COLOR_MODE,
            primary: {main: PRIMARYCOLOR, },
            secondary: { main: SECONDARYCOLOR, },
        },
    });

    const footerStyle = {
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#fff',
        color: PRIMARYCOLOR
    };
    
    useEffect(() => {
        dispatch(actualizarNotify(user.token))
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])
    
    const toggleDrawer = () =>{
        dispatch(openDrawer())
        setOpen(!open); 
    } 

    const autoClose = () =>{
        if(open)
            dispatch(openDrawer())
        setOpen(false); 
    } 

  
    return ( 
        <ThemeProvider theme={mdTheme} >
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar position="absolute" open={open} sx={{ backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,position: 'fixed', top: '0' }}>
                        <Toolbar sx={{ pr: '24px', }} >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{ marginRight: '36px', ...(open && { display: 'none' }), }} >
                                <MenuIcon />
                            </IconButton>
                            {!open && screenWidth > 600?  <Box
                                    component="img"
                                    sx={{
                                        height: 40,
                                        maxHeight: { xs: 233, md: 167 },
                                        maxWidth: { xs: 350, md: 250 },
                                        marginRight:1
                                    }}
                                    alt="The house from the offer."
                                    src={logo} />:<></>}
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }} >
                               {titlePage}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={open} 
                    sx={{"height":screenHeight, position: 'fixed',
                        top: '0px',
                        left: '0px',
                        zIndex: 1000,}}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }} >
                        </Toolbar>
                        <Box
                            sx={{
                                display: open? 'flex' : 'none',
                                flexDirection: 'column', 
                                alignItems:"center",
                                mt:-3}} >
                            <Box
                                component="img"
                                sx={{ width:120, marginRight:1, marginTop:-2 }}
                                alt="The house from the offer."
                                src={'https://imagenespublicas.s3.us-east-2.amazonaws.com/Logos/TRAC/logo.jpg'} />
                            <Typography sx={{fontSize: 18, fontWeight:'bold',color: "#555",mt:2}} >  Bienvenido</Typography>
                            <Typography sx={{fontSize: 14,color: "#555"}} > {user.nombre}</Typography>
                        </Box>
                        <Divider />
                        <List component="nav" sx={{ overflow: 'auto',
                            scrollbarWidth: 'thin',
                            overflowX: 'hidden', // Oculta la barra de desplazamiento horizontal
                            '&::-webkit-scrollbar': {
                            width: '2px', // Ancho de la barra de desplazamiento
                            },
                            '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888', // Color del "pulgón" (la parte que se arrastra)
                            },
                            '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1', // Color del fondo de la barra de desplazamiento
                            },
                        }}>
                            <MenuListItems />
                        </List>
                            <Typography variant="body1" color="inherit" style={footerStyle}>
                                V {process.env.REACT_APP_VERSION}
                            </Typography>
                    </Drawer>
                    <Box
                        component="main"
                        onClick={()=> autoClose()}
                        sx={{
                            padding:3,
                            paddingLeft:12,
                            backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                            flexGrow: 1,
                            width:"100%",
                            height: "100%",
                            // maxHeight: screenHeight,
                            overflow: 'scroll',
                            scrollbarWidth: 'thin',
                            // overflowX: 'hidden', // Oculta la barra de desplazamiento horizontal
                            '&::-webkit-scrollbar': {
                            width: '2px', // Ancho de la barra de desplazamiento
                            },
                            '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888', // Color del "pulgón" (la parte que se arrastra)
                            },
                            '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1', // Color del fondo de la barra de desplazamiento
                            },
                        }} >
                        <Toolbar />
                       
                        <Routes>
                            <Route path="/" element={<TuPerfil />} />
                            <Route path="colaboradores" element={<Colaboradores />} />
                            <Route path="vacantes" element={<Vacantes />} />
                            <Route path="perfiles" element={<PerfilesPuesto/>} />
                            {/* <Route path="vacaciones" element={<Vacaciones/>} /> */}
                            <Route path="inducciones" element={<PerfilesPuesto/>} />
                            <Route path="prestamos" element={<PrestamosSolicitudes/>} />
                            <Route path="caja-ahorro" element={<SolicitudesCajaAhorro/>} />
                            <Route path="incidentes" element={<PerfilesPuesto/>} />
                            <Route path="bonos" element={<PerfilesPuesto/>} />
                            <Route path="Lubtrac" element={<PerfilesPuesto/>} />
                            <Route path="capacitaciones" element={<Capacitaciones/>} />
                            <Route path="curos-aprobar" element={<CursosPorAprobar/>} />
                            <Route path="curso-colaboradores" element={<MatrizCursos/>} />
                            <Route path="cursos" element={<Capacitaciones/>} />
                            <Route path="cursos-lista" element={<Cursos/>} />
                            <Route path="organigrama" element={<Organigrama/>} />
                            <Route path="perfil" element={<TuPerfil/>} />
                            <Route path="Documentacion" element={<Documentacion/>} />
                            <Route path="uniformes" element={<Uniformes/>} />
                        </Routes>
                    </Box>
                </Box>
                <ToastContainer
                
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
				theme="dark"
                />
            </ThemeProvider>
    );
}

export default Menu
