import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Grid, Box ,Button} from '@mui/material/';
import imgCamisa from '../../../Images/camisa.png'
import imgPolo from '../../../Images/polo.png'
import imgPantalon from '../../../Images/pantalones.png'
import imgBotas from '../../../Images/botas.png'
import { PRIMARYCOLOR } from '../../../Constants/Styles'
import ItemUniforme from './ItemUniforme';
import { TALLASPANTALONES, TALLASBOTAS, TALLASCAMISAS } from '../../../Constants/Strings';
import {saveUniformeColaborador, getUniformeColaborador} from '../../../Services/Api_uniformes';
import { toast } from 'react-toastify';
import {LoadViewDetail} from '../../Common/LoadingView';

const UniformeColaborador = ({ setClose , reloadData, setReloadData, dataColaborador}) => {
    const token = useSelector((state) => state.user.token);
    const [disabledSelect, setDisabledSelect] = useState(true)
    const [disabledButton, setDisabledButton] = useState(false)
    const [camisa, setCamisa] = useState(null)
    const [polo, setPolo] = useState(null)
    const [pantalon, setPantalon] = useState(null)
    const [botas, setBotas] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (dataColaborador.idColaborador === null)
            return
        setDisabledSelect(true)
        setLoading(true)
        getUniformeColaborador(token, dataColaborador.idColaborador).then((response) => {
            setCamisa(response.camisa)
            setPolo(response.polo)
            setPantalon(response.pantalon)
            setBotas(response.botas)
        }).catch((error) => {
            if(error.response.status === 404){
                setDisabledSelect(false)
                setCamisa(null)
                setPolo(null)
                setPantalon(null)
                setBotas(null)
            }else{
                console.log(error);
                toast.error("Ocurrio un error al cargar los totales de uniformes");
            }
        }).finally(() => setLoading(false));
    }, [dataColaborador])

    if (dataColaborador === null)
        return <></>
    if (loading)
        return <LoadViewDetail/>

    const handleClick = () => {
        if(!disabledSelect){
            setDisabledButton(true)
            let year = new Date().getFullYear()
            toast.promise(saveUniformeColaborador(token, dataColaborador.idColaborador, camisa, polo, pantalon, botas, year), {
                pending: 'Guardando uniforme...',
                success: { render({ data }) {
                    setDisabledButton(false)
                    setClose(true)
                    setReloadData(!reloadData)
                    return `Uniforme guardado exitosamente` } },
                error: "Error al guardar el uniforme"
            })
            console.log(camisa, polo, pantalon, botas)
        }
        setDisabledSelect(!disabledSelect)
    }
    return (
        <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                <Button  color="error" onClick={() => setClose(true)}> Cerrar </Button>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Box component={'span'} sx={{ fontSize: 22, fontWeight: 'bold', color: PRIMARYCOLOR }}> {dataColaborador.nombre} </Box><br/>
                <Box component={'span'} sx={{ fontSize: 22, fontWeight: 'bold', color: dataColaborador.status == 'Pendiente' ? 'red' : PRIMARYCOLOR }}> {dataColaborador.status} </Box>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Box component={'span'} sx={{ fontSize: 18, fontWeight: 'bold', color: PRIMARYCOLOR }}> Tallas de uniformes </Box>
            </Grid>
            {camisa != null ||  !disabledSelect ? <ItemUniforme size={12} imagen={imgCamisa} nombre={'Camisa'} tallas={TALLASCAMISAS} value={camisa}  setValue={setCamisa} disabledSelect={disabledSelect}/>: <> </>}
            {polo != null ||  !disabledSelect ? <ItemUniforme size={12} imagen={imgPolo} nombre={'Polo'} tallas={TALLASCAMISAS} value={polo} setValue={setPolo} disabledSelect={disabledSelect}/>: <> </>}
            {pantalon != null ||  !disabledSelect ? <ItemUniforme size={12} imagen={imgPantalon} nombre={'Pantalon'} tallas={TALLASPANTALONES} value={pantalon} setValue={setPantalon} disabledSelect={disabledSelect}/>: <> </>}
            {botas != null ||  !disabledSelect ? <ItemUniforme size={12} imagen={imgBotas} nombre={'Botas'} tallas={TALLASBOTAS} value={botas} setValue={setBotas} disabledSelect={disabledSelect}/>: <> </>}
            <Grid item xs={3} sx={{ textAlign: 'center' }}>                </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <Button variant="contained" color='success' fullWidth sx={{mt:2}} onClick={()=> handleClick()} disabled={disabledButton}>{disabledSelect? 'Editar': "Enviar"}</Button>
            </Grid>
        </Grid>
    )
}

export default UniformeColaborador