import React, { useMemo, useState } from "react"
//UseForm
import { Controller, FormProvider } from "react-hook-form"
//Material Ui
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Slider from "@mui/material/Slider"
import { styled } from "@mui/material/styles"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid2 from "@mui/material/Unstable_Grid2"
//icons
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Button from "@mui/material/Button"
//Date
import { Autocomplete } from "@mui/material"
import "dayjs/locale/es-mx"
import { InputDateFormGrid, InputDateRangeFormGrid } from "./InputsDate"
const Root = styled("div")(({ theme }) => ({
	width: "100%",
	...theme.typography.body2,
	color: theme.palette.text.secondary,
	"& > :not(style) ~ :not(style)": {
		marginTop: theme.spacing(2),
	},
}))

export default function Formulario({ fields = [], formMethods }) {
	const {
		control,
		register,
		formState: { errors },
	} = formMethods
	const inputs = useMemo(
		() =>
			fields.map((campo, index) => {
				const { field } = campo

				const propsField = {
					key: `Input_${index}`,
					grid: { xs: 12 },
					...campo,
					register: register,
					control: control,
					error: errors,
				}

				switch (field) {
					case "Text":
						return <InputTextFormGrid2 {...propsField} />
					case "Select":
						return <InputSelectFormGrid {...propsField} />
					case "AutoComplete":
						return <InputAutocomplete {...propsField} />
					case "Date":
						return <InputDateFormGrid {...propsField} />
					case "DateRange":
						return <InputDateRangeFormGrid {...propsField} />
					case "CheckBox":
						return <CheckBoxFormGrid {...propsField} />
					case "Slider":
						return <SliderFormGrid {...propsField} />
					case "File":
						return <FileFormGrid {...propsField} />
					case "Divider":
						return (
							<Root>
								<Divider textAlign="left">{campo.content}</Divider>
							</Root>
						)
					default:
						return <></>
				}
			}),
		[control, errors, fields, register]
	)

	return (
		<FormControl
			key={"formPersonal"}
			sx={{ width: "100%" }}
		>
			<Grid2
				container
				spacing={2}
				columns={12}
			>
				<FormProvider {...formMethods}>{inputs}</FormProvider>
			</Grid2>
		</FormControl>
	)
}

export const InputTextFormGrid2 = ({
	id = "inputGrid2",
	label = "InputGrid2",
	autoComplete = "off",
	rules = {},
	register = () => {},
	error = {},
	grid,
	...props
}) => {
	error = error[id]
	return (
		<Grid2 {...grid}>
			<TextField
				id={id}
				label={label}
				autoComplete={autoComplete}
				fullWidth
				error={error}
				helperText={error?.message || null}
				{...props}
				{...register(id, rules)}
			/>
		</Grid2>
	)
}
export const InputSelectFormGrid = ({
	id,
	label,
	control,
	data = [],
	dataId = "id",
	dataLabel = "label",
	error = {},
	onChange = () => {},
	grid = {},
	controller = {},
	formControl = {},
	...props
}) => {
	const labelId = `label_${id}`

	const items = data.map((option) => (
		<MenuItem
			key={option[dataId]}
			value={option[dataId]}
		>
			{option[dataLabel]}
		</MenuItem>
	))

	return (
		<Grid2 {...grid}>
			<Controller
				{...controller}
				name={id}
				control={control}
				render={({ field }) => {
					return (
						<FormControl
							fullWidth
							error={error.id}
							{...formControl}
						>
							<InputLabel id={labelId}>{label}</InputLabel>
							<Select
								id={id}
								labelId={labelId}
								label={label}
								{...props}
								{...field}
								onChange={(e) => {
									field.onChange(e)
									onChange(e)
								}}
							>
								{items}
							</Select>
							<FormHelperText>
								{error[id] ? error[id]["message"] : ""}
							</FormHelperText>
						</FormControl>
					)
				}}
			/>
		</Grid2>
	)
}
export const InputAutocomplete = ({
	id,
	defaultValue = "",
	label,
	// options = [],
	dataID = "id",
	dataLabel = "label",

	error = {},
	register,
	registerParams = {},
	control,
	grid,
	field,
	...props
}) => {
	return (
		<Grid2 {...grid}>
			<Controller
				name={id}
				control={control}
				defaultValue=""
				render={({ field }) => {
					return (
						<Autocomplete
							{...field}
							{...props}
							value={props?.options?.filter((v) => v?.id === field?.value)[0]}
							onChange={(e, n) => {
								if (n) {
									field.onChange(n[dataID])
								} else field.onChange(null)
							}}
							getOptionLabel={(option) => {
								if (option) {
									return option[dataLabel]
								}
								return ""
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={label}
								/>
							)}
						/>
					)
				}}
			/>
		</Grid2>
	)
}

export function CheckBoxFormGrid({
	id,
	label,
	control,
	grid,
	defaultValue = null,
	registerParams = {},
	error = {},
}) {
	return (
		<Grid2 {...grid}>
			<Controller
				name={id}
				control={control}
				defaultValue={defaultValue}
				rules={registerParams}
				render={({ field }) => (
					<React.Fragment>
						<FormControlLabel
							error={!!error[id]}
							control={
								<Checkbox
									checked={field.value}
									{...field}
								/>
							}
							label={label}
						/>
						<FormHelperText>
							{!!error[id] ? error[id]["message"] : ""}
						</FormHelperText>
					</React.Fragment>
				)}
			/>
		</Grid2>
	)
}

export function SliderFormGrid({
	id,
	label,
	signo = "",
	control,
	grid,
	defaultValue = null,
	registerParams = {},
	error = {},
}) {
	const [valor, setValor] = useState(0)
	function valuetext(value) {
		console.log(value)
		setValor(value)

		return `${value}%`
	}
	return (
		<Grid2
			{...grid}
			padding={2}
		>
			<Controller
				name={id}
				control={control}
				defaultValue={defaultValue}
				rules={registerParams}
				render={({ field }) => (
					<React.Fragment>
						<Typography
							id="input-slider"
							gutterBottom
						>
							{`${label} ${valor} ${signo}`}
						</Typography>
						<Grid2
							container
							spacing={2}
							alignItems="center"
						>
							<Grid2
								item
								xs
							>
								<Slider
									aria-label="Custom marks"
									getAriaValueText={valuetext}
									valueLabelDisplay="auto"
									step={1}
									marks
									min={1}
									max={100}
									{...field}
								/>
							</Grid2>
						</Grid2>

						<FormHelperText>
							{!!error[id] ? error[id]["message"] : ""}
						</FormHelperText>
					</React.Fragment>
				)}
			/>
		</Grid2>
	)
}

export function FileFormGrid({
	id,
	label,
	control,
	grid,
	defaultValue = null,
	registerParams = {},
	error = {},
}) {
	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	})

	return (
		<Grid2
			{...grid}
			padding={2}
		>
			<Controller
				name={id}
				control={control}
				defaultValue={defaultValue}
				rules={registerParams}
				render={({ field: { value, onChange, ...field } }) => (
					<React.Fragment>
						<Button
							component="label"
							variant="contained"
							startIcon={<CloudUploadIcon />}
							{...field}
							onChange={(event) => {
								console.log(event.target.files[0])
								onChange(event.target.files[0])
							}}
						>
							{label}
							<VisuallyHiddenInput type="file" />
						</Button>

						<FormHelperText>
							{!!error[id] ? error[id]["message"] : ""}
						</FormHelperText>
					</React.Fragment>
				)}
			/>
		</Grid2>
	)
}
