import { api_rh_get, api_rh_put } from "#api/API_RH"
import { InputAutocomplete } from "#common/Inputs2"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"
import CloseIcon from "@mui/icons-material/Close"
import {
	AppBar,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { PRIMARYCOLOR } from "../../Constants/Styles"
import {
	getColaboradores,
	getPerfiles,
	getSucursales,
} from "../../Services/Api"
import { reloadDataVacante, reloadTable } from "../../app/pageSlice"
import { useMemo } from "react"

const DialogNewColaborador = ({
	open,
	setOpen,
	id = null,
	candidato = null,
}) => {
	const token = useSelector((state) => state.user.token)
	const [listSuc, setListSuc] = useState([])
	const [listPerfiles, setListPerfiles] = useState([])
	const [listColab, setListColab] = useState([])
	const [colaborador, setColaborador] = useState({})
	const [selectedImage, setSelectedImage] = useState(null)
	const [labelCursos, setLabelCursos] = useState("Realizará Cursos")
	const [rol, setRol] = useState("Colaborador")
	const [endEmail, setEndEmail] = useState("")
	const [loading, setLoading] = useState(false)
	const loadingF = () => setLoading(false)

	const dispatch = useDispatch()
	useEffect(() => {
		function get(get_api, set) {
			get_api(token)
				.then((response) => {
					set(response)
				})
				.catch((error) => console.log(error))
		}

		const fields = [
			"nombre",
			"apellido_paterno",
			"apellido_materno",
			"perfil_puesto",
			"jefe_directo",
			"no_empleado",
			"realiza_cursos",
			"rol",
			"sucursal",
			"correo_personal",
			"email",
		]
		if (open) {
			get(getPerfiles, setListPerfiles)
			get(getSucursales, setListSuc)
			get(getColaboradores, setListColab)
		}

		if ((candidato || id) && open) {
			setLoading(true)
			const params = {
				no_empleado: `VC${candidato}`,
				fields,
			}
			if (candidato)
				api_rh_get(`colaboradores/colaborador`, {
					setData: setColaborador,
					loading: loadingF,
					axiosProps: { params },
				})
			else if (id)
				api_rh_get(`colaboradores/${id}`, {
					setData: setColaborador,
					loading: loadingF,
					axiosProps: { params },
				})
		}
	}, [candidato, id, open, token])

	const imageChange = (e) => {
		setSelectedImage(
			e.target.files && e.target.files.length > 0 ? e.target.files[0] : null
		)
	}

	const save = async () => {
		// verificar que los campos estén llenos
		let formData = new FormData()
		if (validar_campos(colaborador)) {
			for (const campo in colaborador) {
				if (campo === "email")
					if (!colaborador["email"] || colaborador["email"]?.length === 0)
						formData.append("email", null)
					else formData.append("email", colaborador["email"] + endEmail)
				else formData.append(campo, colaborador[campo])
			}
			formData.append("rol", rol)
			if (selectedImage) formData.append("url_photo", selectedImage)
		} else {
			toast.error("Todos los campos son obligatorios")
			return
		}
		let response = null
		if (colaborador?.id) {
			response = await api_rh_put(
				`colaboradores/${colaborador?.id}`,
				formData,
				{
					axios: {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					},
				}
			)
		}

		if (response) {
			dispatch(reloadDataVacante())
			dispatch(reloadTable())
			setOpen(false)
		}
	}

	const handleChangeRealizaCursos = (event) => {
		setLabelCursos(
			event.target.checked ? "Realizá Cursos" : "No realizá Cursos"
		)
		setColaborador({
			...colaborador,
			realiza_cursos: event.target.checked ? 1 : 0,
		})
	}

	useEffect(() => {
		let perf = listPerfiles.filter((perf) => perf.id === colaborador?.perfil)
		let mail = ""
		perf = perf[0]
		let not_finally = [3, 13]

		if (perf?.area_id === 2) mail = "@becoming"
		else if (!not_finally.includes(perf?.area_id)) mail = "@lubtrac.com"

		if (mail)
			if (colaborador?.sucursal === 35)
				// eslint-disable-next-line eqeqeq
				mail += ".gt"
			else mail += ".mx"

		setEndEmail(mail)
	}, [endEmail, colaborador?.sucursal, listPerfiles, colaborador?.perfil])

	const addField = (event) => {
		const campo = event.target.id
		setColaborador({ ...colaborador, [campo]: event.target.value })
	}
	useEffect(() => {
		setColaborador((prevColaborador) => {
			let email = prevColaborador?.email
			if (email != null) {
				let partes = email.split("@")
				if (partes.length) {
					return { ...prevColaborador, email: partes[0] }
				}
			}
			return prevColaborador
		})
	}, [colaborador?.email])

	if (!open) return
	if (loading)
		return (
			<Dialog
				maxWidth="lg"
				open={open}
				onClose={() => setOpen(false)}
			>
				<Box
					sx={{
						display: "flex",
						height: "50vh",
						width: "30vw",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CircularProgress />
				</Box>
			</Dialog>
		)

	return (
		<Dialog
			maxWidth="lg"
			open={open}
			onClose={() => {
				setOpen(false)
				setSelectedImage(null)
			}}
		>
			<AppBar sx={{ position: "relative", backgroundColor: PRIMARYCOLOR }}>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						onClick={() => setOpen(false)}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
					<Typography
						sx={{ ml: 2, flex: 1 }}
						variant="h6"
						component="div"
					>
						Colaborador
					</Typography>
				</Toolbar>
			</AppBar>
			<DialogContent>
				<Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
					<Grid
						container
						spacing={2}
						justify="center"
						alignItems="center"
					>
						<TextFieldGrid
							id="nombre"
							label="Nombre*"
							addField={addField}
							colaborador={colaborador}
							propsGrid={{ md: 4 }}
						/>
						<TextFieldGrid
							id="apellido_paterno"
							label="Apellido Paterno*"
							addField={addField}
							colaborador={colaborador}
							propsGrid={{ md: 4 }}
						/>
						<TextFieldGrid
							id="apellido_materno"
							label="Apellido Materno*"
							addField={addField}
							colaborador={colaborador}
							propsGrid={{ md: 4 }}
						/>
						<Grid
							container
							spacing={2}
							item
							md={6}
						>
							<TextFieldGrid
								id="no_empleado"
								label="No. Empleado*"
								addField={addField}
								colaborador={colaborador}
								propsGrid={{ md: 4 }}
							/>

							<Grid
								item
								md={8}
							>
								<FormControl fullWidth>
									<InputLabel id="demoReporta">Sucursal*</InputLabel>
									<Select
										labelId="selectReporta"
										id="selectReporta"
										label="Sucursal"
										value={colaborador["sucursal"] || null}
										onChange={(event) => {
											return setColaborador({
												...colaborador,
												sucursal: event.target.value,
											})
										}}
									>
										<MenuItem
											value={""}
											disabled
										>
											Selecciona un Sucursal
										</MenuItem>
										{listSuc.map((sucursal) => (
											<MenuItem
												key={"suc" + sucursal.id}
												value={sucursal.id}
												name
											>
												{sucursal.clave + " - " + sucursal.nombre}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid
								item
								md={12}
							>
								<InputAutocomplete
									id="perfil_puesto"
									label="Perfil de Puesto*"
									optionValue="id"
									optionLabel="nombre"
									options={listPerfiles}
									value={colaborador["perfil_puesto"] || null}
									setOption={(v) => {
										setColaborador({
											...colaborador,
											perfil_puesto: v,
										})
									}}
								/>
							</Grid>
							<Grid
								item
								md={12}
							>
								<InputAutocomplete
									id="Jefe_directo"
									label="Jefe Directo*"
									value={10}
									optionValue="id"
									optionLabel="nombre"
									setOption={(v) => {
										setColaborador({
											...colaborador,
											jefe_directo: v,
										})
									}}
									options={listColab.map((colaborador) => ({
										id: colaborador.id,
										nombre: `${colaborador.nombre} ${colaborador.apellido_paterno} ${colaborador.apellido_materno}`,
									}))}
								/>
							</Grid>
							<TextFieldGrid
								id="email"
								label="Correo Electrónico Lubtrac*"
								addField={addField}
								colaborador={colaborador}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">{endEmail}</InputAdornment>
									),
								}}
								propsGrid={{ md: 12 }}
							/>
							<TextFieldGrid
								id="correo_personal"
								label="Correo Personal*"
								addField={addField}
								colaborador={colaborador}
								propsGrid={{ md: 12 }}
							/>
							<Grid
								item
								md={7}
							>
								<FormControl fullWidth>
									<InputLabel id="demoReporta">Rol de plataforma*</InputLabel>
									<Select
										label="Rol de plataforma"
										value={rol}
										onChange={(event) => setRol(event.target.value)}
									>
										<MenuItem value={"Colaborador"}>Colaborador</MenuItem>
										<MenuItem value={"Gerente"}>Gerente</MenuItem>
										<MenuItem value={"RH"}>RH</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid
								item
								md={5}
							>
								<FormControlLabel
									control={
										<Switch
											id="realiza_cursos"
											checked={colaborador["realiza_cursos"]}
											onChange={handleChangeRealizaCursos}
										/>
									}
									label={labelCursos}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							md={6}
						>
							<Grid
								item
								md={12}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}
								>
									{selectedImage == null ? (
										<></>
									) : (
										<Box
											component={"img"}
											sx={{
												width: 200,
												height: 200,
												borderRadius: 1,
												border: 1,
												borderColor: "primary.main",
												p: 1,
												mt: 2,
											}}
											alt="Logo"
											src={URL.createObjectURL(selectedImage)}
										/>
									)}
									<Button
										sx={{ mt: 1 }}
										variant="contained"
										component="label"
									>
										<AddAPhotoIcon sx={{ marginRight: 1 }} /> Subir Fotografía
										<input
											type="file"
											hidden
											onChange={imageChange}
											accept="image/*"
										/>
									</Button>
								</Box>
							</Grid>
						</Grid>
						<Grid
							item
							md={4}
						></Grid>
						<Grid
							item
							md={4}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Button
									sx={{ mt: 3 }}
									color="success"
									variant="contained"
									component="label"
									onClick={() => save()}
								>
									Guardar Colaborador
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	)
}
export default DialogNewColaborador

function validar_campos(colaborador) {
	const campos_obligatorios = [
		"nombre",
		"apellido_paterno",
		"apellido_materno",
		"perfil_puesto",
		"jefe_directo",
		"no_empleado",
		"realiza_cursos",
		"rol",
		"sucursal",
	]
	for (const campo in campos_obligatorios) {
		const name = campos_obligatorios[campo]
		if (colaborador[name]?.length === 0) return false
	}

	return true
}

/**
 * @param {object} colaborador
 * @param {} addField
 * @param {Grid} propsGrid
 * @type {TextField}
 */
const TextFieldGrid = ({ colaborador, addField, propsGrid = {}, ...props }) => {
	return (
		<Grid
			item
			md={6}
			{...propsGrid}
		>
			<TextField
				fullWidth
				onChange={addField}
				value={colaborador[props?.id] || null}
				variant="outlined"
				{...props}
			/>
		</Grid>
	)
}
