import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

import FormBase from "../../Common/Forms"
import { DialogForm } from "./../../Common/Dialog"
import {
	apiPostCursosExternos,
	apiPutCursosExternos,
} from "../../../Services/Api_Colaborador"

const DATA = [
	{
		field: "input",
		id: "nombre",
		label: "Nombre Curso",
		registerParams: { required: true },
	},
	{
		field: "input",
		id: "institucion",
		label: "Institución",
		registerParams: { required: { value: true } },
	},
	{
		field: "date",
		id: "inicio",
		label: "Inicio",
		views: ["year"],
		type: "number",
		parseFormat: "YYYY",
		propsG: { xs: 6 },
		registerParams: { required: { value: true } },
	},
	{
		field: "date",
		id: "fin",
		label: "Fin",
		views: ["year"],
		type: "number",
		propsG: { xs: 6 },
		parseFormat: "YYYY",
		registerParams: {
			required: { value: true },
		},
	},
	{
		field: "file",
		id: "certificado",
		label: "Certificado",
		registerParams: {
			required: { value: true, message: "Se necesita un archivo" },
		},
	},
]

export default function FormDataCursosExternos({
	idColaborador,
	open,
	setOpen,
	edit,
	actualizar,
}) {
	const token = useSelector((state) => state.user.token)
	const form = useForm({ defaultValues: edit })
	const { handleSubmit, reset } = form
	const [button, setButton] = useState(false)
	useEffect(() => {
		if (!!edit) {
			reset(edit)
		} else reset({})
	}, [edit, reset, open])

	const submit = async (data) => {
		const formData = new FormData()
		setButton(true)
		formData.append("colaborador", idColaborador)
		if (typeof data.inicio === "object") {
			data.inicio = data.inicio["$y"]
		}
		if (typeof data.fin === "object") {
			data.fin = data.fin["$y"]
		}
		if (parseInt(data.inicio) > parseInt(data.fin))
			toast.error("La fecha inicio no puede ser mayor a la final")
		else {
			for (let field in data) formData.append(field, data[field])

			let response
			if (edit) response = await apiPutCursosExternos(token, formData)
			else response = await apiPostCursosExternos(token, formData)

			if (response.success) {
				actualizar()
				setOpen(false)
			}
		}
		setButton(false)
	}

	return (
		<React.Fragment>
			<DialogForm
				title={edit ? "Editar cursos externos" : "Registrar curso externo"}
				open={open}
				setOpen={setOpen}
				accept={handleSubmit(submit)}
				disabledButtons={button}
			>
				<FormBase
					fields={DATA}
					useForm={form}
				/>
			</DialogForm>
		</React.Fragment>
	)
}
