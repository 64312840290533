import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getNotify } from "../Services/Api"
import { toast } from "react-toastify"

const initialState = {
	notification: {},
	isLoading: false,
	error: null
}

export const actualizarNotify = createAsyncThunk("content/actualizarNotify", async (token) => {
	const {success,data} = await getNotify(token)
	if(success){
		return data}
	else{
		toast.error("Error en las notificaciones")
		return {}
	}
})
const simulateApiCall = () => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			const data = { "Curso por Aprobar": 4, "Mis Cursos": 0 }
			resolve(data)
		}, 2000) // Simulamos un retardo de 2 segundos antes de que la API responda
	})
}

export const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(actualizarNotify.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(actualizarNotify.fulfilled, (state, action) => {
			state.isLoading = false
			state.notification = action.payload
		})
		builder.addCase(actualizarNotify.rejected, (state, action) => {
			state.isLoading = false
			state.error = action.error.message
		})
	}
})
//export const { setNotifications } = notificationSlice.actions;
export default notificationSlice.reducer
