import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Button, Dialog, DialogContent, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { PRIMARYCOLOR } from '../../Constants/Styles';
import { getColaboradores, getPerfiles, getSucursales } from '../../Services/Api';
import { saveColaborador } from '../../Services/ApiColaboradores';
import { reloadDataVacante, reloadTable } from "../../app/pageSlice";
import { InputAutocomplete } from '#common/Inputs2';

const ModalNewColaborador = ({ open, setOpen, nuevoIngreso = null }) => {
    const token = useSelector(state => state.user.token);
    const [listSuc,setListSuc]              = useState([])
    const [listPerfiles,setListPerfiles]    = useState([])
    const [listColab,setListColab]          = useState([])
    const [nombre,setNombre]                = useState('')
    const [apellidoPaterno,setApellidoPaterno] = useState('')
    const [apellidoMaterno,setApellidoMaterno] = useState('')
    const [correo,setCorreo]                = useState('')
    const [sucursal,setSucursal]            = useState('')
    const [perfil,setPerfil]                = useState(null)
    const [jefe,setJefe]                    = useState(null)
    const [noEmpleado,setNoEmpleado]        = useState('')
    const [candidatoID,setCandidatoID]      = useState(null)
    const [selectedImage, setSelectedImage] = useState(undefined)
    const [realizaCursos,setRealizaCursos]  = useState(true)
    const [labelCursos,setLabelCursos]      = useState('Realizará Cursos')
    const [rol,setRol]                      = useState('Colaborador')
    const [endEmail,setEndEmail]            = useState('')
    const dispatch = useDispatch()

    useEffect(()=>{
        if(open === false)
            return
        if(nuevoIngreso !== null){
            setNombre(nuevoIngreso.nombre)
            setCorreo(nuevoIngreso.correo)
            setSucursal(nuevoIngreso.sucursal)
            setPerfil(nuevoIngreso.perfil)
            setJefe(nuevoIngreso.jefe_directo)
            setCandidatoID(nuevoIngreso.candidato)
        }
        getPerfiles(token).then((response)=>{
            setListPerfiles(response)
            getSucursales(token).then(response => {
                setListSuc(response)
                getColaboradores(token).then(response=>{
                    setListColab(response)
                })
            }).catch(error => console.log(error))
        }).catch(error => console.log(error))
    },[nuevoIngreso, open, token])

    const imageChange = (e) => {
        setSelectedImage(e.target.files && e.target.files.length > 0 ? e.target.files[0]: null);

    };

    const save = () => {
        // verificar que los campos estén llenos
        if(nombre === '' || apellidoPaterno === '' || apellidoMaterno === '' || correo === '' || sucursal === '' || perfil === '' || jefe === '' || noEmpleado === '' || rol === '' ){
            toast.error('Todos los campos son obligatorios')
            return
        }
        let formData = new FormData();
        formData.append("nombre", nombre);
        formData.append("apellido_paterno", apellidoPaterno);
        formData.append("apellido_materno", apellidoMaterno);
        formData.append("email", correo+endEmail);
        formData.append("sucursal", sucursal);
        formData.append("perfil_puesto", perfil);
        formData.append("jefe_directo", jefe);
        formData.append("no_empleado", noEmpleado);
        formData.append("rol", rol);
        formData.append("foto", selectedImage);
        formData.append("realiza_cursos", realizaCursos);
        if (candidatoID !== null)
            formData.append("candidato_id", candidatoID);
        formData.append("colaborador_id", 0);
        toast.promise(saveColaborador(token,formData),{
            pending: 'Guardando colaborador...',
            success: {
                render({data}){
                    dispatch(reloadDataVacante())
                    dispatch(reloadTable())
                    setOpen(false)
                    if(data.data.username)
                        return `Accesos enviados correctamente, el usuario es ${data.data.username} y la nueva contraseña es ${data.data.password}`
                    else
                        return 'Colaborador guardado correctamente, Ya contaba con accesos a la plataforma.'
                }
            },
            error:{
                render({ data }) {
                    if (!!data.response.data.message) {
                        console.error(data.response.data.message)
                        return `${data.response.data.message}`
                    } 
                    
                },
            }, 
            // {render() 'Hubo un error al guardar el colaborador'}
        },{autoClose:false,closeOnClick: false})
    }

     const handleChangeRealizaCursos = (event) => {
        setRealizaCursos(event.target.checked);
        setLabelCursos(event.target.checked ? 'Realizá Cursos' : 'No realizá Cursos')
    };
   
    useEffect(()=>{
        let perf=listPerfiles.filter(perf=>perf.id===perfil)
        let mail=""
        perf=perf[0]
        let not_finally=[3,13]
    
        if(perf?.area_id===2)
            mail="@becoming"
        else if(!not_finally.includes( perf?.area_id))
            mail="@lubtrac.com"
        
        if(mail)
            // eslint-disable-next-line eqeqeq
            if(sucursal===35)
                mail+=".gt"
            else
                mail+=".mx"
    
        setEndEmail(mail)
    },[endEmail, sucursal, listPerfiles, perfil])
    



    


    if(!open)
        return
    return(
        <Dialog  maxWidth="lg" open={open} onClose={() =>  setOpen(false)}>
            <AppBar sx={{ position: 'relative',backgroundColor:PRIMARYCOLOR }}>
                <Toolbar >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() =>  setOpen(false)}
                        aria-label="close" >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"> Nuevo Colaborador </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Box  sx={{ display: 'flex',width:'100%', justifyContent:"center",}}>
    
                <Grid container spacing={2} justify="center" alignItems="center">
                    <Grid item md={6}>
                        <TextField
                            id="Nombre"
                            label="Nombre*"
                            fullWidth
                            onChange={(event)=>setNombre(event.target.value)}
                            value={nombre}
                            variant="outlined" />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="Apellido Paterno"
                            label="Apellido Paterno*"
                            fullWidth
                            onChange={(event)=>setApellidoPaterno(event.target.value)}
                            value={apellidoPaterno}
                            variant="outlined" />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="Apellido Materno"
                            label="Apellido Materno*"
                            fullWidth
                            onChange={(event)=>setApellidoMaterno(event.target.value)}
                            value={apellidoMaterno}
                            variant="outlined" />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="Correo Electrónico Lubtrac"
                            label="Correo Electrónico Lubtrac*"
                            fullWidth
                            onChange={(event)=>setCorreo(event.target.value)}
                            value={correo}
                            InputProps={{
                                
                                endAdornment: <InputAdornment position="start">{endEmail}</InputAdornment>,
                                
                            }}
                            variant="outlined" />
                    </Grid>
                    <Grid container spacing={2} item md={6}>
                        <Grid item md={5}>
                            <TextField
                                id="No. Empleado"
                                label="No. Empleado*"
                                fullWidth
                                onChange={(event)=>setNoEmpleado(event.target.value)}
                                value={noEmpleado}
                                variant="outlined" />
                        </Grid>
                        <Grid item md={7}>
                            <FormControl fullWidth>
                                <InputLabel id="demoReporta">Sucursal*</InputLabel>
                                <Select
                                    labelId="selectReporta"
                                    id="selectReporta"
                                    label="Sucursal"
                                    value={sucursal}
                                    onChange={(event)=> setSucursal(event.target.value)} >
                                        <MenuItem value={''} disabled>Selecciona un Sucursal</MenuItem>
                                        {listSuc.map( (sucursal) => <MenuItem key={'suc'+sucursal.id} value={sucursal.id}>{sucursal.clave+' - '+sucursal.nombre}</MenuItem> )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <InputAutocomplete
                            id="perfil_puesto" 
                            label='Perfil de Puesto*'
                            optionValue="id" 
                            optionLabel="nombre"
                            options={listPerfiles}
                            value={perfil}
                            setOption={setPerfil}
                            />
                        </Grid>
                        <Grid item md={12}>
                        <InputAutocomplete
                                id="Jefe_directo" 
                                label='Jefe Directo*' 
                                value={jefe}
                                optionValue="id"
                                optionLabel="nombre"
                                setOption={setJefe}
                                options={listColab.map(colaborador=>
                                    ({
                                    id:colaborador.id,
                                    "nombre":`${colaborador.nombre} ${colaborador.apellido_paterno} ${colaborador.apellido_materno}`
                                    }))
                                }
                                />
                        </Grid>
                        <Grid item md={7}>
                            <FormControl fullWidth>
                                <InputLabel id="demoReporta">Rol de plataforma*</InputLabel>
                                <Select
                                    label="Rol de plataforma"
                                    value={rol}
                                    onChange={(event)=> setRol(event.target.value)} >
                                        <MenuItem  value={'Colaborador'}>Colaborador</MenuItem> 
                                        <MenuItem  value={'Gerente'}>Gerente</MenuItem> 
                                        <MenuItem  value={'RH'}>RH</MenuItem> 
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={5}>
                            <FormControlLabel control={<Switch checked={realizaCursos} onChange={handleChangeRealizaCursos }/>} label={labelCursos} />
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid item md={12}  >
                            <Box  sx={{  display: 'flex', flexDirection: 'column', alignItems:"center" }}> 
                                {selectedImage == null ? <></>:
                                    <Box 
                                    component={"img"}
                                    sx={{width:200,height:200, borderRadius:1, border:1, borderColor:'primary.main', p:1, mt:2}}
                                    alt="Logo"
                                    src={ URL.createObjectURL(selectedImage)} />
                                }
                                <Button
                                    sx={{mt:1}}
                                    variant="contained"
                                    component="label" >
                                    <AddAPhotoIcon sx={{marginRight:1}}/>  Subir Fotografía
                                    <input type="file" hidden onChange={imageChange} accept="image/*"/>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item md={4}  >
                    </Grid>
                    <Grid item md={4}>
                        <Box  sx={{  display: 'flex', flexDirection: 'column', alignItems:"center" }}> 
                            <Button
                                sx={{mt:3}}
                                color="success"
                                variant="contained"
                                component="label"
                                onClick={()=>save()} >
                                Guardar Colaborador
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            </DialogContent>
      </Dialog>
    )
}
export default ModalNewColaborador
                            