// import { InputSelect } from "#common/Inputs2"
import { InputSelect } from "#common/input/InputSelect"
import { Stack } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { TYPE_1, TYPE_2, TYPE_3 } from "../TextBaja"
import UseBajas from "../UseBajas"
export function ApproveTermination({ hidden = true, onChange }) {
	const { bajas } = UseBajas("/termination_types", { open: !hidden })

	const [selected, setSelected] = useState({
		type1: "",
		type2: "",
		type3: "",
	})

	const dependencyFilter = useCallback(
		(id) => bajas.filter((baja) => baja.termination_dependency_id === id),
		[bajas],
	)
	const type1 = useMemo(() => dependencyFilter(null), [dependencyFilter])

	const type2 = useMemo(
		() => dependencyFilter(selected.type1),
		[dependencyFilter, selected.type1],
	)

	const type3 = useMemo(
		() => dependencyFilter(selected.type2),
		[dependencyFilter, selected.type2],
	)

	const changeType_1 = (value) =>
		setSelected({
			...selected,
			type1: value,
			type2: "",
			type3: "",
		})

	const changeType_2 = (value) => {
		setSelected({ ...selected, type2: value, type3: "" })
	}

	const changeType_3 = (value) => setSelected({ ...selected, type3: value })

	useEffect(() => {
		if (!hidden) onChange(selected)
	}, [hidden, onChange, selected])

	if (hidden) return null
	return (
		<Stack direction={"row"}>
			<InputSelect
				label={TYPE_1}
				data={type1}
				value={selected.type1}
				setValue={changeType_1}
				dataId="id"
				dataLabel="description"
				menuItemProps={{
					sx: {
						whiteSpace: "normal",
					},
				}}
			/>
			<InputSelect
				label={TYPE_2}
				data={type2}
				value={selected.type2}
				setValue={changeType_2}
				dataId="id"
				dataLabel="description"
				formControlProps={{ width: "100%" }}
				disabled={!selected.type1}
				menuItemProps={{
					sx: {
						whiteSpace: "normal",
					},
				}}
			/>
			<InputSelect
				label={TYPE_3}
				data={type3}
				value={selected.type3}
				setValue={changeType_3}
				dataId="id"
				dataLabel="description"
				disabled={!selected.type2}
				menuItemProps={{
					sx: {
						whiteSpace: "normal",
					},
				}}
			/>
		</Stack>
	)
}
