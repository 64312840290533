import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux" //Token
import { PRIMARYCOLOR } from "../../../Constants/Styles"
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material"
import { ButtonIcon } from "../../Common/Buttons"
import EditIcon from "@mui/icons-material/Edit"
import AddReactionIcon from "@mui/icons-material/AddReaction"
import DeleteIcon from "@mui/icons-material/Delete"
import FormDataFamiliares from "../forms/FormDataFamiliares"
import {
	apiDeleteColaboradorFamiliar,
	apiGetColaboradorFamiliar,
} from "../../../Services/Api_Colaborador"
import Stack from "@mui/material/Stack"
const Familiares = ({ colaborador, edit = true }) => {
	const token = useSelector((state) => state.user.token)
	const [familiares, SetFamiliares] = useState([])
	const [formulario, setFormulario] = useState(false)
	const [formularioEdit, setFormularioEdit] = useState(false)
	const [actualizar, SetActualizar] = useState(true)
	const actualizarNow = () => SetActualizar(!actualizar)
	const camposTabla = [
		{
			id: "nombre",
			label: "Nombre",
		},
		{ id: "parentezco", label: "Parentesco" },
		{
			id: "fecha_nacimiento",
			label: "Fecha Nacimiento",
		},
		{
			id: "sexo",
			label: "Sexo",
			dataID: "value",
		},
		{
			id: "estado_civil",
			label: "Estado Civil",
		},

		{
			id: "escolaridad",
			label: "Escolaridad",
		},
		{
			id: "profesion",
			label: "Profesión",
		},
		{
			id: "vive",
			label: "¿Vive?",
		},
	]
	if (edit)
		camposTabla.push({
			id: "button",
			styled: { align: "center" },
			label: (
				<ButtonIcon
					title="Agregar familiar"
					sx={{
						color: "#eee",
					}}
					icon={<AddReactionIcon />}
					onClick={() => {
						setFormulario(true)

						setFormularioEdit(false)
					}}
				/>
			),
		})

	useEffect(() => {
		apiGetColaboradorFamiliar(token, SetFamiliares, {
			colaborador: colaborador.id,
		})
	}, [colaborador.id, token, actualizar])

	return (
		<Grid container>
			<Grid
				item
				xs={12}
			>
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								{camposTabla.map((campo) => {
									return (
										<TableCell
											{...campo.styled}
											sx={{
												backgroundColor: PRIMARYCOLOR,
												color: "#eee",
												fontWeight: "bold",
											}}
										>
											{campo.label}
										</TableCell>
									)
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{familiares.map((familiar, index) => {
								return (
									<TableRow key={index}>
										{camposTabla.map((campo, i) => {
											if (campo.id === "button")
												return (
													<TableCell
														key={familiar.id + `${i}`}
														{...campo.styled}
													>
														<Stack
															alignContent={"center"}
															justifyContent={"center"}
															direction="row"
															spacing={2}
														>
															<ButtonIcon
																title="Agregar familiar"
																icon={<EditIcon />}
																onClick={() => {
																	setFormulario(true)
																	setFormularioEdit(familiar)
																}}
															/>
															<ButtonIcon
																title="Eliminar familiar "
																icon={<DeleteIcon />}
																onClick={() => {
																	apiDeleteColaboradorFamiliar(token, {
																		id: familiar.id,
																	})
																	actualizarNow()
																}}
															/>
														</Stack>
													</TableCell>
												)

											if (campo.id === "vive")
												return (
													<TableCell>
														{familiar[campo.id] ? "Si" : "No"}
													</TableCell>
												)
											return <TableCell>{familiar[campo.id]}</TableCell>
										})}
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<FormDataFamiliares
				idColaborador={colaborador.id}
				open={formulario}
				setOpen={setFormulario}
				edit={formularioEdit}
				actualizar={actualizarNow}
			/>
		</Grid>
	)
}

export default Familiares
