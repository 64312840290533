import axios from "axios"
import { saveAs } from "file-saver"
import { get, post, put, deleteApi } from "./Api_Call"
const URLAPI = process.env.REACT_APP_SERVER_URL
const URL_API = process.env.REACT_APP_SERVER_URL
const URL = "rh/cursos/"
const URL_CURSOS = URLAPI + "rh/cursos/"
/**
 * @typedef {'cursos'|'plataforma'|'area'|'planAnual'|'departamento'|'colaborador'|'statistics'} llamada
 */
const Enlace = {
	cursos: `${URL_API}/rh/cursos`,
	plataforma: `${URL_API}/rh/cursos/plataforma`,
	link: `${URL_API}/rh/cursos/departamento/link`,
	planAnual: `${URL_API}rh/cursos/departamento/planAnual`,
	colaborador: `${URL_API}rh/cursos/colaborador`,
	statistics: `${URL_API}rh/cursos/colaborador/statistics`,
	departamento: `${URL_API}rh/cursos/departamento`,
	area: `${URL_API}rh/cursos/responsableArea`,
	//No pertenece a cursos
	sucursal: `${URL_API}core/sucursales`,
	areas1: `${URL_API}rh/areas`,
	//sucursal: `${URL_API}core/sucursales`,
}

/**
 * Obtiene datos de cursos desde la API.
 *
 * @param {string} call - Tipo de llamada a la API (por ejemplo, 'cursos', 'plataforma', etc.).
 * @param {string} token - Credenciales de autenticación para la API.
 * @param {function} set_db - Función para almacenar los datos obtenidos en el estado.
 * @param {object} [filtro={}] - Opciones de filtro para la API en forma de objeto.
 * @param {function} [skeleton=() => {}] - Función opcional para desactivar el esqueleto cambiando el estado a true.
 * @returns {Promise<object>} - Un objeto con datos de cursos.
 *
 * @throws {Error} - Se lanza un error si la llamada a la API no tiene éxito.
 *
 * @example
 * // Ejemplo de uso:
 * const call=['cursos','plataforma','area','planAnual','departamento','colaborador','statistics']
 *
 * try {
 *   const result = await getApiCursos(call[1], token, setData, filtro, skeleton);
 *   // result puede ser:
 *   // {
 *   //   data: [...], // Datos de los cursos
 *   //   message: "Datos de Cursos", // Mensaje de éxito
 *   //   success: true // Indica si la solicitud fue exitosa
 *   // }
 * } catch (error) {
 *   console.error('Error al obtener datos de la API:', error.message);
 *   // Manejar el error según sea necesario
 * }
 */
export async function getApiCursos(
	call,
	token,
	set_db = () => {},
	filtro = {},
	skeleton = () => {}
) {
	let response = await get(Enlace[call], token, set_db, filtro, skeleton)
	return response
}

/**
 * Crea un nuevo elemento con POST.
 *
 * @param {llamada} call - Tipo de llamada a la API (por ejemplo, 'cursos', 'plataforma', etc.).
 * @param {string} token - Credenciales de autenticación para la API.
 * @param {function} datos - Función para almacenar los datos modificados en el estado.
 * @param {object} [filtro={}] - Opciones de filtro para la API en forma de objeto.
 * @param {boolean} [actualizar=true] - Indica si se debe actualizar el estado después de la modificación (opcional).
 * @param {function} [setActualizar=() => {}] - Función para actualizar el estado después de la modificación (opcional).
 * @returns {Promise<void>} - Una promesa que se resuelve después de la modificación exitosa.
 *
 * @example
 * // Ejemplo de uso:
 * const call=['cursos','plataforma','area','planAnual','departamento','colaborador','statistics']
 *postApiCursos(call[1], token, setData, filtro, actualizar, setActualizar);
 */
export async function postApiCursos(
	call,
	token,
	datos,
	filtro = {},
	actualizar,
	setActualizar = () => {}
) {
	return await post(
		Enlace[call],
		token,
		datos,
		filtro,
		actualizar,
		setActualizar
	)
}

/**
 * Modifica los datos de la API y actualiza el estado correspondiente.
 *@typedef {'cursos'|'plataforma'|'area'|'planAnual'|'departamento'|'colaborador'|'statistics'} llamada
//   * @typedef {'usuario' | 'planAnual' | 'porAprobar'} filtro
 * @param {llamada} call - Tipo de llamada a la API (por ejemplo, 'cursos', 'plataforma', etc.).
 * @param {string} token - Credenciales de autenticación para la API.
 * @param {function} datos - Función para almacenar los datos modificados en el estado.
 * @param {object} [filtro={}] - Opciones de filtro para la API en forma de objeto (opcional).
 * @param {boolean} [actualizar=true] - Indica si se debe actualizar el estado después de la modificación (opcional).
 * @param {function} [setActualizar=() => {}] - Función para actualizar el estado después de la modificación (opcional).
 * @returns {Promise<void>} - Una promesa que se resuelve después de la modificación exitosa.
 *
 * @throws {Error} - Se lanza un error si la modificación en la API no tiene éxito.
 *
 * @example
 * // Ejemplo de uso:
 * const call=['cursos','plataforma','area','planAnual','departamento','colaborador','statistics']
 * const filtro = { id: 123 };
 *putApiCursos(call[1], token, setData, filtro, actualizar, setActualizar);
 */
export async function putApiCursos(
	call,
	token,
	datos,
	filtro = {},
	actualizar,
	setActualizar = () => {}
) {
	return await put(
		Enlace[call],
		token,
		datos,
		filtro,
		actualizar,
		setActualizar
	)
}

/**
 * Elimina  los datos de la API y actualiza el estado correspondiente.
 *
 * @param {string} call - Tipo de llamada a la API (por ejemplo, 'cursos', 'plataforma', etc.).
 * @param {string} token - Credenciales de autenticación para la API.
 * @param {object} [filtro={}] - Opciones de filtro para la API en forma de objeto (opcional).
 * @param {boolean} [actualizar=true] - Indica si se debe actualizar el estado después de la modificación (opcional).
 * @param {function} [setActualizar=() => {}] - Función para actualizar el estado después de la modificación (opcional).
 * @returns {Promise<void>} - Una promesa que se resuelve después de la modificación exitosa.
 *
 * @throws {Error} - Se lanza un error si la modificación en la API no tiene éxito.
 *
 * @example
 * // Ejemplo de uso:
 * const call=['cursos','plataforma','area','planAnual','departamento','colaborador','statistics']
 * const filtro = { id: 123 };
 *deleteApiCursos(call[1], token, setData, filtro, actualizar, setActualizar);
 */
export async function deleteApiCursos(
	call,
	token,
	filtro = {},
	actualizar,
	setActualizar = () => {}
) {
	return await deleteApi(Enlace[call], token, filtro, actualizar, setActualizar)
}

/**
 *
 * @param {*} token
 * @param {*} link
 * @returns
 */
export const getLinkPDF = async (token, link) => {
	const request = await axios.get(URL_API + `rh/cursos/departamento/link`, {
		params: { url: link },
		headers: { Authorization: "Token " + token },
	})
	return request.data.data
}

/**
 *
 * @param {*} token
 * @param {*} parametros-inicia con un ?
 * @returns
 */
export const putCursosColaborador = async (
	token,
	parametros = "",
	datos = {}
) => {
	const request = await axios.put(
		URLAPI + URL + `colaborador${parametros}`,
		datos,
		{
			headers: { Authorization: "Token " + token },
		}
	)

	return request.data
}

/**
 * Descarga una carpeta zip con los pdf por departamento
 *
 * @param {*} token
 * @param {{}} [params={	}]
 */
export const getDocs = async (token, params = {}, mes) => {
	try {
		let response = await axios.get(URLAPI + URL + `carpetaS3`, {
			headers: { Authorization: "Token " + token },
			params: params,
			responseType: "blob", // Use arraybuffer for binary data
		})
		let data = response.data
		saveAs(data, `cursos_${mes}.zip`)
	} catch (error) {
		console.error("Error fetching data:", error)
	}
}

/**
 * Elimina cursos de un colaborador en la API.
 *
 * @example
 * deleteCursosColaborador(token, { id: 1 }, actualizar, setActualizar)
 *
 * @param {string} token - Credenciales de autenticación para la API.
 * @param {object} [filtro={}] - Opciones de filtro para la eliminación (opcional).
 * @param {boolean} [actualizar=true] - Indica si se debe actualizar el estado después de una eliminación exitosa (opcional).
 * @param {function} [setActualizar=() => {}] - Función de actualización de estado después de una eliminación exitosa (opcional).
 */
export async function deleteCursosColaborador(
	token,
	filtro = {},
	actualizar,
	setActualizar = () => {}
) {
	await deleteApi(
		URL_CURSOS + "colaborador",
		token,
		filtro,
		actualizar,
		setActualizar
	)
}
