import React, { useEffect, useState } from 'react'
import { getOrganigrama } from '../Services/Api'
import { useSelector, useDispatch } from "react-redux";
import { changeTitle } from '../app/pageSlice';

const Organigrama = () => {
    const reload   = useSelector(state => state.page.reloadVacante);
    const token    = useSelector(state => state.user.token);
    const [arrayOrganigrama, setArrayOrganigrama] = useState([])
    const [organigrama, setOrganigrama] = useState([])
    const dispatch = useDispatch()
    // obtener el token del usuario 


    useEffect(()=>{
        dispatch(changeTitle('Organigrama'))
        getOrganigrama(token).then(response =>{
          console.log(construirArbol(response))

        }).catch().finally()
    },[])

    function construirArbol(data, parentId = null) {
      const arbol = [];

      for (const item of data) {
        if (item.reporta_a_id === parentId) {
          const hijos = construirArbol(data, item.id);
          if (hijos.length > 0) {
            item.hijos = hijos;
          }
          arbol.push(item);
        }
      }

      return arbol;
    }



    return <>
        <div>Organigrama</div>
        {arrayOrganigrama.map((organigrama, index) => {
          return (
            <div key={index}>
              <div>{organigrama.nombre}</div>
              <div>{organigrama.puesto}</div>
              <div>{organigrama.subordinados}</div>
            </div>
          )
        })}

      </>
    
}

export default Organigrama