import { api_rh_put } from "#api/API_RH"
import { ButtonFile } from "#common/Buttons"
import { COLOR_PRIMARY, COLOR_THIRD } from "#constants/Styles"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import { Box, Stack } from "@mui/material/"
import { UseDetalleColaboradorContext } from "../Solicitudes/SolicitudesContext"

export const ColaboradorImage = ({ colaborador, is_rh = true }) => {
	const { reload } = UseDetalleColaboradorContext()
	const { url_foto, id } = colaborador

	const uploadImage = async (e) => {
		let formData = new FormData()
		formData.append("id", id)
		formData.append("url_photo", e)
		const response = await api_rh_put(`/colaboradores/${id}`, formData, {
			message: { success: "Foto actualizada" },
		})
		if (response) {
			reload()
		}
	}
	const style = {
		height: "130px",
		width: "130px",
		borderRadius: "10px",
		border: "1px solid #ccc",
		padding: 0,
	}
	let icon = null
	if (is_rh)
		icon = (
			<CameraAltIcon
				sx={{
					fontSize: "35px",
					color: COLOR_PRIMARY,
					backgroundColor: COLOR_THIRD,
					borderRadius: 50,
					position: "absolute",
					bottom: "3px",
					right: "3px",
					padding: 1,
				}}
			/>
		)
	return (
		<Box
			width={"100%"}
			height={"100%"}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<ButtonFile
				variant="text"
				disabled={!is_rh}
				label={
					<Stack
						alignContent="center"
						alignItems="center"
					>
						<Box
							component={"img"}
							sx={style}
							alt="No se cuenta con fotografía"
							src={url_foto}
							onError={(e) => {
								e.target.onerror = null
								e.target.src =
									"https://imagenespublicas.s3.us-east-2.amazonaws.com/usuario.webp"
							}}
						/>
						{icon}
					</Stack>
				}
				startIcon={null}
				style={style}
				operation={uploadImage}
			/>
		</Box>
	)
}
