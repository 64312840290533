import { useState } from 'react';
import PropTypes from 'prop-types';
//
import { PERFILES } from '../../Constants/Strings';
import { HasPermission } from '#common/Funciones';

//Material Ui
import { Box, Grid, Tab, Tabs } from '@mui/material/';

//Componentes Menu Personales
import Car from './Detail/Car';
import Contactos from './Detail/Contactos';
import Escolares from './Detail/Escolares';
import Familiares from './Detail/Familiares';
import Personales from './Detail/Personales';
import Trayectoria from './Detail/Trayectoria';
import { Pdf } from './Detail/Documentos';



const InventarioRH = ({ dataColaborador }) => {
    const [tabActive, setTabActive] = useState(0);
    const handleChange = (event, newValue) => { setTabActive(newValue); };
    const { verificado, edit_data } = dataColaborador
    const is_rh = HasPermission(PERFILES.RH)
    let edit = false

    if (is_rh || (edit_data && verificado == null))
        edit = true

    return (
        <Grid container width={"100%"} sx={{ "backgroundColor": "#9D1515" }}>
            <Grid item xs={12} sx={{ backgroundColor: '#fff' }} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabActive}
                        onChange={handleChange}
                        aria-label="información Colaborador"
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                    >
                        <Tab label="Datos Personales" {...a11yProps(0)} />
                        <Tab label="Familiares" {...a11yProps(1)} />
                        <Tab label="Beneficiarios y Contactos" {...a11yProps(2)} />
                        <Tab label="Datos Escolares" {...a11yProps(3)} />
                        <Tab label="Trayectoria laboral" {...a11yProps(4)} />
                        <Tab label="vehículos" {...a11yProps(5)} />
                        {edit ? <Tab label="pdf" {...a11yProps(6)} /> : []}
                    </Tabs>
                </Box>
                <TabPanel value={tabActive} index={0} > <Personales dataColaborador={dataColaborador} edit={edit} /> </TabPanel>
                <TabPanel value={tabActive} index={1}> <Familiares colaborador={dataColaborador} edit={edit} /> </TabPanel>
                <TabPanel value={tabActive} index={2}> <Contactos colaborador={dataColaborador} edit={edit} /> </TabPanel>
                <TabPanel value={tabActive} index={3}> <Escolares colaborador={dataColaborador} edit={edit} /> </TabPanel>
                <TabPanel value={tabActive} index={4}> <Trayectoria colaborador={dataColaborador} edit={edit} /> </TabPanel>
                <TabPanel value={tabActive} index={5}> <Car colaborador={dataColaborador} edit={edit} /> </TabPanel>
                <TabPanel value={tabActive} index={6}> <Pdf colaborador={dataColaborador} /> </TabPanel>
            </Grid>
        </Grid>
    )
}
export default InventarioRH

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}