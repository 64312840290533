import React from "react"
import { useSelector } from "react-redux" //Token
import { useState, useEffect } from "react"
import { PERFILES } from "../../Constants/Strings"
import { ESTADO_CURSOS } from "./constants_capacitaciones"

//funciones
import { PDFlink } from "./componentes/TarjetaCurso/FTarjetaCurso"
import { MESES, COLOR_TARJETA } from "./constants_capacitaciones"

//Llamado api
import { getApiCursos, putApiCursos } from "../../Services/Api_cursos"
//componentes
import { TextGrid2 } from "../Common/Texto"
import TCCardContent from "./componentes/TarjetaCurso/TCCardContent"
import { ButtonIconFile, ButtonIcon } from "../Common/Buttons"

import "react-toastify/dist/ReactToastify.css"
//material-ui iconos
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import NotInterestedRoundedIcon from "@mui/icons-material/NotInterestedRounded"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded"
import VerifiedSharpIcon from "@mui/icons-material/VerifiedSharp"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"

//material-ui
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import Confirmation from "./componentes/TarjetaCurso/Confirm"
import { Stack } from "@mui/material"

/**
 * Componente TarjetaCurso
 *
 * Este componente muestra una tarjeta con los datos del curso del colaborador.
 * @typedef {'usuario' | 'planAnual' | 'porAprobar'} filtro
 * Definición de las opciones posibles para el prop 'tipo'.
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.db_user - Datos del usuario.
 * @param {Object} props.db_cursoArea - Datos del área del curso.
 * @param {Object} props.db_cursos - Datos del curso.
 * @param {boolean} props.statusChange - Estado de cambio.
 * @param {Function} props.setStatusChange - Función para establecer el estado de cambio.
 * @param {filtro} props.filtro - Opción de filtro para mostrar todos los datos o no (por defecto, "usuario").
 * @param {boolean} props.open - Indica si la tarjeta está abierta o cerrada (por defecto, cerrada).
 * @param {Function} props.setEdit - Función para establecer el modo de edición.
 * @returns {JSX.Element} - Devuelve un elemento JSX que representa la tarjeta del curso del colaborador.
 */
export default function TarjetaCurso({
	db_user = {},
	db_cursoArea,
	db_cursos,
	statusChange,
	setStatusChange = () => {},
	filtro = "usuario",
	open = false,
	setEdit = () => {},
}) {
	//Selector
	const token = useSelector((state) => state.user.token)
	const puesto = useSelector((state) => state.user.profiles[0].name)
	const [newStatus, setNewStatus] = useState(false)
	//useState
	const [statusCurso, setStatusCurso] = useState(db_user.status)
	const [urlPDF, setUrlPDF] = useState("")
	const [openConfirm, setOpenConfirm] = useState(false)

	const permisosU = puesto === PERFILES["ADMIN"] || puesto === PERFILES["RH"]
	const buttonPermit = puesto === PERFILES["ADMIN"] || puesto === PERFILES["RH"]

	const linkPDF = db_user.url_s3
	useEffect(() => {
		if (open) {
			getApiCursos("link", token, setUrlPDF, { url: linkPDF })
		}
	}, [linkPDF, open, statusCurso, token])

	useEffect(() => {
		setStatusCurso(db_user.status)
	}, [db_user])

	const handleFileUpload = async (event) => {
		const file = event.target.files[0]
		var formData = new FormData()
		formData.append("pdf", file)
		formData.append("curso", db_cursos.curso)
		let x = await putApiCursos("colaborador", token, formData, {
			PDF: "",
			id: db_user.id,
		})
		if (x["success"]) {
			setStatusCurso(ESTADO_CURSOS["REVISAR"])
		}
	}
	const handleDownload = async () => {
		let response = await getApiCursos("link", token, () => {}, { url: linkPDF })
		window.open(response.data, "_blank")
	}
	const showButton =
		statusCurso !== ESTADO_CURSOS["APROBADO"] &&
		statusCurso !== ESTADO_CURSOS["RECHAZADO"] &&
		buttonPermit &&
		!!linkPDF
	const isCancel = statusCurso !== ESTADO_CURSOS["CANCELADO"]
	const buttonColor = "#F9F9F9"

	let mes
	if (!!db_cursoArea) mes = db_cursoArea.mes
	else mes = db_user.mes
	return (
		<React.Fragment>
			<Card
				key={`${db_user.id}`}
				sx={{
					minWidth: "200px",
					height: "90%",
					m: 0.5,
					borderRadius: 5,
					boxShadow: 10,
				}}
			>
				<Grid
					container
					borderRadius={80}
				>
					<Grid
						xs={12}
						sx={{
							padding: "10px",
							backgroundColor: COLOR_TARJETA(statusCurso),
						}}
					>
						{!!db_cursoArea && (
							<TextGrid2
								label={MESES[mes].label}
								variant="h6"
								propsG={{ xs: 1 }}
								sx={{ fontWeight: "bold" }}
								color="text.secondary"
							/>
						)}
						{filtro !== "planAnual" && (
							<CardActions sx={{ height: 0, m: 0.1 }}>
								{/* ----------------------------------------------------------------------------------------------------- */}
								<Grid
									container
									justifyContent="space-between"
									sx={{ width: "100%" }}
								>
									<TextGrid2
										label={MESES[mes].label}
										variant="h6"
										propsG={{ xs: 1 }}
										sx={{ fontWeight: "bold", color: buttonColor }}
										color="text.secondary"
									/>

									<Grid xs={9}>
										<div>
											<Stack
												direction="row"
												justifyContent="flex-end"
												alignItems="center"
												spacing={1}
											>
												<ButtonIconFile
													title="Subir pdf"
													id={db_user.id}
													onChange={handleFileUpload}
													accept=".pdf"
													sx={{ color: buttonColor }}
													show={
														statusCurso !== ESTADO_CURSOS["APROBADO"] &&
														isCancel
													}
												/>
												<ButtonIcon
													title="Descargar pdf"
													sx={{ color: buttonColor }}
													xs={1}
													show={!!linkPDF && isCancel}
													icon={<CloudDownloadIcon />}
													onClick={() => handleDownload()}
												/>
												<ButtonIcon
													title="Mostrar pdf"
													sx={{ color: buttonColor }}
													xs={1}
													show={!!linkPDF && isCancel}
													icon={<PictureAsPdfIcon />}
													onClick={() =>
														PDFlink(token, linkPDF, setUrlPDF, db_cursos)
													}
												/>

												<ButtonIcon //Cancelado
													title="Cancelar curso"
													icon={<NotInterestedRoundedIcon />}
													xs={1}
													onClick={() => {
														setNewStatus(ESTADO_CURSOS["CANCELADO"])
														setOpenConfirm(true)
													}}
													sx={{ color: buttonColor }}
													show={
														buttonPermit &&
														statusCurso !== ESTADO_CURSOS["APROBADO"] &&
														isCancel
													}
												/>
												<ButtonIcon
													title="Aprobar cursos"
													onClick={() => {
														setNewStatus(ESTADO_CURSOS["APROBADO"])
														setOpenConfirm(true)
													}}
													sx={{ color: buttonColor }}
													xs={1}
													show={showButton && isCancel}
													icon={<VerifiedSharpIcon />}
												/>
												<ButtonIcon
													title="Rechazar curso"
													sx={{ color: buttonColor }}
													xs={1}
													show={
														showButton &&
														statusCurso !== ESTADO_CURSOS["RECHAZADO"] &&
														isCancel
													}
													onClick={() => {
														setNewStatus(ESTADO_CURSOS["RECHAZADO"])
														setOpenConfirm(true)
													}}
													icon={<CancelRoundedIcon />}
												/>
												<ButtonIcon
													title="Editar Tarjeta"
													xs={1}
													sx={{ color: buttonColor }}
													show={buttonPermit && filtro !== "porAprobar"}
													icon={<EditIcon />}
													onClick={() =>
														setEdit({
															id: db_user.id,
															mes: db_user.mes,
															area_id: db_user.area,
															curso_id: db_cursos.id,
														})
													}
												/>
												<ButtonIcon
													xs={1}
													icon={<DeleteIcon />}
													title="Eliminar Curso "
													sx={{ color: buttonColor }}
													show={permisosU}
													onClick={() => {
														setNewStatus(-1)
														setOpenConfirm(true)
													}}
												/>
											</Stack>
										</div>
									</Grid>
								</Grid>
							</CardActions>
						)}
					</Grid>
					<Grid xs={12}>
						<TCCardContent
							db_user={db_user}
							db_cursos={db_cursos}
							db_cursoArea={db_cursoArea}
							urlPDF={urlPDF}
							filtro={filtro}
							statusCurso={statusCurso}
						/>
					</Grid>
				</Grid>
			</Card>
			<Confirmation
				newStatus={newStatus}
				idCursoColaborador={db_user.id}
				confirm={openConfirm}
				setConfirm={setOpenConfirm}
				actualizar={statusChange}
				setActualizar={setStatusChange}
			/>
		</React.Fragment>
	)
}
