import React from 'react'
import { Grid, TextField, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const Interacciones = ({title,quienText,setQuienText,paraText, setParaText,listInteraccion, setListInteraccion}) => {
    
    const addInteraccion = (quien, para) =>{ 
        setListInteraccion([...listInteraccion,{'con_quien':quien,"para_que":para}])
        setQuienText("")
        setParaText("")
    }
    
    const removeInteraccion = indexList => setListInteraccion(listInteraccion.filter((item,index) => index !== indexList))
    
    return (
        <Grid container spacing={2} sx={{mb:3}}>
            <Grid item xs={12} sx={{mt:0,mb:1}}> <Box component="b" sx={{fontSize:20,color:"#1d3454" }}> {title} </Box> </Grid>
            <Grid item xs={4} >
                <TextField
                    id="nombre"
                    label="¿Con quien?"
                    fullWidth
                    onChange={(event)=>setQuienText(event.target.value)}
                    value={quienText}
                    variant="standard" />
            </Grid>
            <Grid item xs={6} sx={{pt:0}}>
                <TextField
                    sx={{pt:0}}
                    id="nombre"
                    label="¿Para que?"
                    fullWidth
                    onChange={(event)=>setParaText(event.target.value)}
                    value={paraText}
                    variant="standard" />
            </Grid>
            <Grid item md={2} >
                <IconButton aria-label="delete" sx={{mt:1}} onClick={() =>addInteraccion(quienText,paraText)}>
                    <AddCircleIcon color='success' fontSize='large'/>
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table  aria-label="simple table">
                        <TableHead>
                            { listInteraccion.length > 0 ?
                                <TableRow>
                                <TableCell sx={{backgroundColor:'#eee',color:'#1d3454',fontWeight:'bold'}}>¿Con quien?</TableCell>
                                <TableCell sx={{backgroundColor:'#eee',color:'#1d3454',fontWeight:'bold'}} >¿Para que?</TableCell>
                                <TableCell sx={{backgroundColor:'#eee',color:'#1d3454',fontWeight:'bold'}} ></TableCell>
                            </TableRow>: <></>}
                            
                        </TableHead>
                        <TableBody>
                            {listInteraccion.map( (inter,index) => {
                                return <TableRow key={index} index={title+index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell component="th" scope="row"> {inter.con_quien} </TableCell>
                                            <TableCell >{inter.para_que}</TableCell>
                                            <TableCell ><DeleteIcon color='error' onClick={() => removeInteraccion(index)}/></TableCell>
                                        </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default Interacciones