import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { useForm } from "react-hook-form"

import { OPTIONS_PARENTEZCO } from "../../../assets/OptionsSelects"
import FormBase from "../../Common/Forms"
import { DialogForm } from "./../../Common/Dialog"
import {
	apiPostColaboradorContacto,
	apiPutColaboradorContacto,
} from "../../../Services/Api_Colaborador"

const DATA = [
	{
		field: "input",
		id: "nombre",
		label: "Nombre",
		registerParams: { required: true },
	},
	{
		field: "select",
		id: "parentezco",
		label: "Parentesco",
		dataID: "value",
		data: [...OPTIONS_PARENTEZCO, { value: "Otro(a)", label: "Otro(a)" }],
		registerParams: { required: true },
	},
	{
		field: "input",
		id: "domicilio",
		label: "Domicilio (Calle N° Ext N° Int)",
		registerParams: { required: { value: true } },
	},
	{
		field: "input",
		id: "telefono",
		label: "Teléfono",
		type: "number",
		registerParams: {
			required: { value: true, message: "Se necesita un numero de teléfono" },
		},
	},
	{
		field: "input",
		id: "telefono2",
		label: "Teléfono 2",
		type: "number",
		registerParams: { required: false },
	},
]

export default function FormDataContactos({
	idColaborador,
	open,
	setOpen,
	edit,
	actualizar,
}) {
	const token = useSelector((state) => state.user.token)
	const form = useForm({ defaultValues: edit })
	const { handleSubmit, reset } = form
	const [button, setButton] = useState(false)
	useEffect(() => {
		reset({
			nombre: "",
			parentezco: "",
			domicilio: "",
			telefono: "",
			telefono2: "",
		})
		if (!!edit) {
			reset(edit)
		}
	}, [edit, reset, open])

	const submit = async (data) => {
		setButton(true)
		data.colaborador = idColaborador
		let response
		if (edit) response = await apiPutColaboradorContacto(token, data)
		else response = await apiPostColaboradorContacto(token, data)

		if (response.success) {
			actualizar()
			setOpen(false)
		}
		setButton(false)
	}

	return (
		<React.Fragment>
			<DialogForm
				title={
					edit ? "Editar contacto emergencia" : "Registrar contacto emergencia"
				}
				open={open}
				setOpen={setOpen}
				accept={handleSubmit(submit)}
				disabledButtons={button}
			>
				<FormBase
					fields={DATA}
					useForm={form}
				/>
			</DialogForm>
		</React.Fragment>
	)
}
