import React from "react"
import { useEffect } from "react"
import {
	Grid,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import Autocomplete from "@mui/material/Autocomplete"
import { render } from "@testing-library/react"
import {FormHelperText} from "@mui/material"
import { object } from "prop-types"

export const GridInputText = ({
	label,
	value,
	setValue,
	size = 3,
	typeInput = "text",
	upperCase = true
}) => (
	<Grid
		item
		xs={size}
	>
		<TextField
			id={label}
			label={label}
			fullWidth
			onChange={(event) => setValue(event.target.value)}
			value={value}
			type={typeInput}
			variant="outlined"
		/>
	</Grid>
)

export const GridInputSelect = ({
	label,
	value,
	setValue,
	size = 3,
	options,
	disabled = false
}) => (
	<Grid
		item
		xs={size}
	>
		<FormControl fullWidth>
			<InputLabel id="demo-simple-select-label">{label}</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				label={label}
				value={value}
				disabled={disabled}
				sx={{ minWidth: 200 }}
				onChange={(event, obj) => setValue(event.target.value, obj.props.name)}
			>
				{options.map((option, index) => (
					<MenuItem
						key={label + "" + index}
						value={option.value}
					>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	</Grid>
)

/**
 * Componente InputSelect con Grid2.
 *
 * Este componente proporciona una interfaz para seleccionar opciones en un menú desplegable.
 * @deprecated
 * @component
 * @param {Object} props - Las propiedades del componente.
 *
 * @param {string} [props.label="hola"] - La etiqueta del input. Valor predeterminado: "hola".
 * @param {Array} [props.data=[]] - Los datos a mostrar en el menú. Valor predeterminado: [].
 * @param {string} [props.dataLabel="label"] - Propiedad en los datos que se usará como etiqueta. Valor predeterminado: "label".
 * @param {string} [props.dataID="id"] - Propiedad en los datos que se usará como ID. Valor predeterminado: "id".
 * @param {boolean} [props.error=false] - Indicador de si hay un error. Valor predeterminado: false.
 * @param {boolean} [props.all=false] - Opción para mostrar "todos". Valor predeterminado: false.
 * @param {boolean} [props.disabled=false] - Opción para deshabilitar el inputSelect. Valor predeterminado: false.
 * @param {number} [props.xs=12] - Tamaño en pantallas extra-small. Valor predeterminado: 12.
 * @param {number} [props.md=4] - Tamaño en pantallas medium. Valor predeterminado: 4.
 * @param {number} [props.xl=2] - Tamaño en pantallas extra-large. Valor predeterminado: 2.
 * @param {number} [props.sizeAll=false] - Tamaño en pantallas para todos los tamaños.
 * @param {string} [props.option=""] - Opción actualmente seleccionada. Valor predeterminado: "".
 * @param {Function} [props.setOption=() => {}] - Función para cambiar la opción seleccionada. Valor predeterminado: () => {}.
 * @param {Function} [props.operation] - Operación a realizar cuando cambia el valor. Valor predeterminado: undefined.
 * @returns {JSX.Element} Un componente que muestra un menú desplegable de opciones.
 */

export const InputSelect = ({
	label = "hola",
	data = [],
	dataLabel = "label",
	dataID = "id",
	error = false,
	all = false,
	disabled = false,
	xs = 12,
	md = 8,
	xl = 6,
	sizeAll = false,
	option = "",
	setOption = () => {},
	operation = (event) => {
		setOption(event.target.value)
	}
}) => {
	if (data.length === 0) {
		data = []
	}
	if (sizeAll) {
		xs = sizeAll
		md = sizeAll
		xl = sizeAll
	}

	return (
		<Grid2
			xs={xs}
			md={md}
			xl={xl}
		>
			<FormControl
				fullWidth
				error={error}
			>
				<InputLabel id={`input${label}`}>{label}</InputLabel>
				<Select
					labelId={`input${label}`}
					id={`select${label}`}
					value={option}
					label={label}
					disabled={disabled}
					onChange={operation}
				>
					{all && (
						<MenuItem
							key={"default"}
							value={0}
						>
							{all}
						</MenuItem>
					)}
					{data.map((option, index) => (
						<MenuItem
							key={label + index}
							value={option[dataID]}
						>
							{option[dataLabel]}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Grid2>
	)
}
/**
 * @deprecated
 * @param {*} param0 
 * @returns 
 */
export const InputSelectGrid = ({
	id = "",
	value="",
	setValue=()=>{},
	label = "hola",
	data = [],
	dataLabel = "label",
	dataID = "id",
	error = false,
	all = false,
	disabled = false,
	sxg = {},
	onChange=(event, obj) => {
		console.log(event.target)
		setValue(event.target.value)},
	registerParams = { required: true },
	register = () => {},
	propsG,
	...props
}) => {
	const [valueI, setValueI] = React.useState(value);
	const handleChange = (event, obj) => {
		console.log(event.target)
		setValueI(event.target.value);
		onChange(event, obj)
	  };
	if (data.length === 0) {
		data = []
	}
	return (
		<Grid2
		{...propsG}
		>
		<FormControl
				fullWidth
				{...register(id, registerParams)}
				error={error}
			>
				<InputLabel id={`label${id}`}   >{label}</InputLabel>
				<Select
					labelId={`label${id}`}
					id={`select${label}`}
					value={valueI}
					label={label}
					disabled={disabled}
					onChange={handleChange}
					//{...register(id, registerParams)}
					//{...props}
				>
					{all && (
						<MenuItem
							key={"default"}
							value={0}
						>
							{all}
						</MenuItem>
					)}
					{data.map((option, index) => (
						<MenuItem
							key={label + index}
							value={option[dataID]}
						>
							{option[dataLabel]}
						</MenuItem>
					))} 
				</Select>
			</FormControl>
		</Grid2>
	)
}

/**
 * Componente Input Con Grid2
 *@deprecated
 * @param {Object} props - Las propiedades del componente.
 * @param {string} [props.label="soy tu input"] - La etiqueta del campo de entrada.
 * @param {string} [props.type="text"] - El tipo de entrada.
 * @param {string} [props.defaultValue=""] - Valor Predetermina.
 * @param {number} props.allSize - El ancho del botón en todos los tamaño de pantalla.
 * @param {number} [props.xs=6] - El tamaño del componente en dispositivos extra pequeños (xs).
 * @param {number} [props.md=4] - El tamaño del componente en dispositivos medianos (md).
 * @param {number} [props.xl=3] - El tamaño del componente en dispositivos extra grandes (xl).
 * @param {number} [props.max=10000] - El valor máximo permitido en el campo de entrada.
 * @param {number} [props.min=0] - El valor mínimo permitido en el campo de entrada.
 * @param {Function} [props.setOption=() => {}] - Función para establecer la opción.
 * @param {Function} [props.operation=(event) => { setOption(event.target.value) }] - Operación a realizar cuando cambia el valor del campo de entrada.
 * @param {string} [props.error] - Mensaje de error asociado al campo de entrada.
 * @param {string} [props.autoComplete="off"] - Atributo de autocompleted para el campo de entrada.
 * @returns {JSX.Element} Componente Input.
 */
export function InputGrid({
	label = "soy tu input",
	type = "text",
	defaultValue = "",
	allSize = false,
	xs = 6,
	md = 4,
	xl = 3,
	max = 10000,
	min = 0,
	setOption = () => {},
	operation = (event) => {
		setOption(event.target.value)
	},
	error,
	autoComplete = "off"
}) {
	if (allSize) {
		xs = allSize
		md = allSize
		xl = allSize
	}
	return (
		<Grid2
			xs={xs}
			md={md}
			xl={xl}
		>
			<TextField
				defaultValue={defaultValue}
				autoComplete={autoComplete}
				error={error}
				id="outlined-basic"
				type={type}
				label={label}
				variant="outlined"
				onChange={operation}
				fullWidth
				InputProps={{
					inputProps: {
						min: min,
						max: max
					}
				}}
			/>
		</Grid2>
	)
}



/**
 * Componente de Autocompletar en un grid.
 *@deprecated
 * @param {object} props - Propiedades del componente.
 * @param {string} [props.label="AutoComplete"] - Etiqueta del campo de entrada.
 * @param {number|null} [props.valueId=null] - ID del valor seleccionado.
 * @param {Array} [props.data=[]] - Datos para las opciones del autocompletar.
 * @param {string} [props.dataLabel="label"] - Nombre de la propiedad a mostrar como etiqueta en las opciones.
 * @param {number} [props.xs=12] - Tamaño en dispositivos extra pequeños (12 columnas por defecto).
 * @param {number} [props.md=8] - Tamaño en dispositivos medianos (8 columnas por defecto).
 * @param {number} [props.xl=6] - Tamaño en dispositivos extra grandes (6 columnas por defecto).
 * @param {function} [props.setOption=() => {}] - Función para establecer la opción seleccionada.
 * @param {function} [props.operation=(event, newValue) => { setOption(newValue["id"]); }] - Función personalizada para manejar el cambio de opción seleccionada default (event, newValue) => { setOption(newValue["id"]).
 */
export const AutocompleteGrid = ({
	label = "AutoComplete",
	valueId = null,
	data = [],
	dataLabel = "label",
	xs = 12,
	md = 8,
	xl = 6,
	setOption = () => {},
	operation = (event, newValue) => {
		setOption(newValue["id"])
	}
}) => {
	const [value, setValue] = React.useState(null)
	useEffect(() => {
		data.forEach((element) => {
			if (element.id === valueId) setValue(element)
		})
	}, [data, valueId])

	return (
		<Grid2
			xs={xs}
			md={md}
			xl={xl}
		>
			<Autocomplete
				value={value}
				disablePortal
				onChange={operation}
				id="combo-box"
				options={data}
				getOptionLabel={(option) => option[dataLabel]}
				renderInput={(params) => {
					return (
						<TextField
							{...params}
							label={label}
						/>
					)
				}}
			/>
		</Grid2>
	)
}
/**
 * @deprecated
 * @param {*} param0 
 * @returns 
 */
export const Grid2Autocomplete = ({
	label = "AutoComplete",
	valueId = null,
	data,
	dataLabel = "label",
	xs = 12,
	md = 8,
	xl = 6,
	setOption = () => {},
	operation = (event, newValue) => {
		setOption(newValue["id"])
	}
}) => {
	const [value, setValue] = React.useState(null)
	useEffect(() => {
		data.forEach((element) => {
			if (element.id === valueId) setValue(element)
		})
	}, [data, valueId])

	return (
		<Grid2
			xs={xs}
			md={md}
			xl={xl}
		>
			<Autocomplete
				value={value}
				disablePortal
				onChange={operation}
				id="combo-box"
				options={data}
				getOptionLabel={(option) => option[dataLabel]}
				renderInput={(params) => {
					return (
						<TextField
							{...params}
							label={label}
						/>
					)
				}}
			/>
		</Grid2>
	)
}
