import React from "react"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { SkeletonSquare } from "../Common/Skeleton"

//Funciones
import { getApiCursos } from "../../Services/Api_cursos"
import { hasPermission } from "../Common/Funciones"
//Componentes
import FormularioCursoColaborador from "./FormularioCursoColaborador"
import { ButtonGrid2 } from "../Common/Buttons"
import { InputSelect } from "../Common/Inputs"

//material-ui
import Typography from "@mui/material/Typography"
import TarjetaCurso from "./TarjetaCurso"
import Grid from "@mui/material/Unstable_Grid2"
import { PERFILES } from "../../Constants/Strings"
import CursosTablaColores from "./componentes/CursosTablaColores"

const VistaUsuario = ({ id }) => {
	const token = useSelector((state) => state.user.token)
	const puesto = useSelector((state) => state.user.profiles)
	const [cursosColaborador, setCursosColaborador] = useState([])
	const [skeleton, setSkeleton] = useState(false)
	const [act, setAct] = useState(false)
	const [nuevoCurso, setNuevoCurso] = useState(false)
	const [edit, setEdit] = useState(false)
	const [yearOption, setYearOption] = useState(new Date().getFullYear())
	const resultado = hasPermission(puesto, PERFILES["RH"])
	useEffect(() => {
		//setSkeleton(false)
		setSkeleton(false)
		getApiCursos(
			"colaborador",
			token,
			setCursosColaborador,
			{
				all: "",
				idColaborador: id,
				year: yearOption,
			},
			setSkeleton
		)
	}, [id, token, act, yearOption])

	useEffect(() => {
		if (edit !== false) setNuevoCurso(true)
	}, [edit])

	let cursosSkeleton = []
	for (let x = 0; x < 12; x++)
		cursosSkeleton.push(
			<Grid
				xs={12}
				md={6}
				xl={4}
				key={`skeleton${x}`}
			>
				<SkeletonSquare />
			</Grid>
		)
	function Funcionalidad() {
		return resultado ? (
			<Grid xs={8}>
				<div>
					<Grid
						padding={4}
						spacing={2}
						container
						alignItems={"center"}
						justifyContent={"center"}
					>
						<ButtonGrid2
							label="Nuevo Curso"
							onClick={() => {
								setNuevoCurso(true)
							}}
							propsG={{
								xs: 12,
								md: 6,
								xl: 4,
							}}
						/>

						<InputSelect
							data={Array.from(
								{
									length: new Date().getFullYear() + 1 - 2023 + 1,
								},
								(_, index) => ({
									año: 2023 + index,
								})
							)}
							dataLabel="año"
							dataID="año"
							xs={12}
							md={6}
							xl={3}
							//sizeAll={5}
							label="Año"
							option={yearOption}
							setOption={setYearOption}
						/>
					</Grid>
				</div>
			</Grid>
		) : (
			<React.Fragment></React.Fragment>
		)
	}
	return (
		<React.Fragment>
			<FormularioCursoColaborador
				setOpen={setNuevoCurso}
				open={nuevoCurso}
				idColaborador={id}
				actualizar={act}
				setActualizar={setAct}
				dataCurso={edit}
				setDataCurso={setEdit}
			/>

			{skeleton ? (
				cursosColaborador.length ? (
					<Grid
						container
						spacing={3}
						justifyContent={"center"}
					>
						<Grid xs={9}>
							<div>
								<Grid
									container
									justifyContent={"center"}
									spacing={0}
								>
									<CursosTablaColores />
									<Funcionalidad />
								</Grid>
							</div>
						</Grid>

						{cursosColaborador.map((data, index) => {
							return (
								<React.Fragment key={index}>
									<Grid
										xs={12}
										md={6}
										xl={4}
										key={`a${data.id}`}
									>
										<TarjetaCurso
											db_user={data}
											db_cursos={data["curso"]}
											statusChange={act}
											setStatusChange={setAct}
											setEdit={setEdit}
										/>
									</Grid>
								</React.Fragment>
							)
						})}
					</Grid>
				) : (
					<React.Fragment>
						<Grid
							container
							justifyContent={"center"}
							spacing={1}
						>
							<Funcionalidad />
						</Grid>
						<Typography
							xs={12}
							sx={{
								mb: 1.5,
								fontWeight: "bold",
								textAlign: "center",
								fontSize: 56,
							}}
						>
							No cuentas con plan de capacitación asignado
						</Typography>
					</React.Fragment>
				)
			) : (
				<Grid
					container
					spacing={1}
				>
					{cursosSkeleton.map((curso) => curso)}
				</Grid>
			)}
		</React.Fragment>
	)
}

export default VistaUsuario
