import React from "react"
import { useSelector } from "react-redux"
import MUIDataTable from "mui-datatables"
import { ASSET_TEXT } from "./AssetText"
import AssetPicture from "./AssetPicture"
import AssetTableDetail from "./AssetTableDetail"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { ButtonIcon } from "../Common/Buttons"
import AssetFormulario from "./AssetFormulario"
import AddModeratorIcon from "@mui/icons-material/AddModerator"
import AssetPictureUpload from "./AssetPictureUpload"
import { hasPermission } from "../Common/Funciones"
import { PERFILES } from "../../Constants/Strings"
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone"
import { apiPutAsset } from "../../Services/Api_inventario"
import { useSolicitudesContext } from "../Colaboradores/Solicitudes/SolicitudesContext"
import { DialogConfirm } from "../Common/ModalConfirmacion"

export default function AssetTable({ data, token }) {
	const [open, setOpen] = React.useState(false)
	const puestos = useSelector((state) => state.user.profiles)
	const [openForm, setOpenForm] = React.useState(false)
	const [actualizarFotos, setActualizarFotos] = React.useState(false)
	const updatePicture = () => setActualizarFotos(!actualizarFotos)
	const { actualizarA } = useSolicitudesContext()
	const columns = [
		{
			name: "id",
			label: "id",
			options: { filter: true, sort: true, display: false },
		},
		{
			name: "name",
			label: ASSET_TEXT.NAME,
			options: { filter: true, sort: true, display: true },
		},
		{
			name: "type_asset",
			label: ASSET_TEXT.TYPE,
			options: { filter: true, sort: true, display: true },
		},

		{
			name: "serial",
			label: ASSET_TEXT.SERIAL,
			options: { filter: true, sort: true, display: true },
		},

		{
			name: "active",
			label: ASSET_TEXT.ACTIVATE,
			options: {
				customBodyRender: (value) => {
					if (value) return ASSET_TEXT.ACTIVATE_TRUE
					return ASSET_TEXT.ACTIVATE_FALSE
				},
				filter: true,
				sort: true,
				display: true,
			},
		},
		{
			name: "phone_number",
			label: ASSET_TEXT.PHONE,
			options: { filter: true, sort: true, display: true },
		},

		{
			name: "delivery_date",
			label: ASSET_TEXT.DELIVERY_DATE,
			options: { filter: true, sort: true, display: true },
		},
		{
			name: "collection_date",
			label: ASSET_TEXT.COLLECTION_DATE,
			options: { filter: true, sort: true, display: true },
		},
		{
			name: "id",
			label: " ",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					let column = data.filter((column) => column["id"] === value)
					column = column[0]

					return (
						<React.Fragment>
							<ButtonIcon
								title={ASSET_TEXT.BUTTON_LOW}
								mensaje={ASSET_TEXT.DIALOG_LOW}
								disabled={!column.active}
								sx={{ color: "red" }}
								icon={<RemoveCircleTwoToneIcon />}
								onClick={() => {
									setOpen(value)
								}}
							/>
						</React.Fragment>
					)
				},
			},
		},
	]
	const expandColumns = [
		{
			name: "fresh_service_id",
			label: ASSET_TEXT.FRESH,
			options: { filter: true, sort: true, display: true },
		},

		{
			name: "model",
			label: ASSET_TEXT.MODEL,
			options: { filter: true, sort: true, display: true },
		},
		{
			name: "brand",
			label: ASSET_TEXT.BRAND,
			options: { filter: true, sort: true, display: true },
		},
		{
			name: "description",
			label: ASSET_TEXT.DESCRIPTION,
			options: { filter: true, sort: true, display: true },
		},
		{
			name: "url_responsive",
			label: "",
			options: { align: "right" },
		},
		{
			name: "url_policy",
			label: "",
			options: { align: "right" },
		},
	]
	const permission = hasPermission(puestos, PERFILES["SISTEMAS"])
	const options = {
		textLabels: {
			body: {
				noMatch: "No cuentas con equipo asignado ", // Cambia este mensaje
			},
		},
		filterType: "checkbox",
		filter: false,
		view: false,
		viewColumns: false,
		print: false,
		columns: false,
		download: false,
		search: false,
		display: false,
		Checkbox: false,
		expandableRowsHeader: false,
		responsive: "vertical",
		expandableRows: true,
		expandableRowsOnClick: true,
		selectableRows: "none",

		maxHeight: "100%",
		customToolbar: () => {
			if (permission)
				return (
					<ButtonIcon
						title="Agregar Equipo"
						icon={<AddModeratorIcon />}
						onClick={() => {
							setOpenForm(true)
						}}
					/>
				)
			return <React.Fragment></React.Fragment>
		},
		renderExpandableRow: (rowData, rowMeta) => {
			let id = { [columns[0].name]: rowData[0] }
			let column = data.filter((column) => column["id"] === id["id"])
			const colSpan = rowData.length + 1
			const array = [
				<tr key={`img${id["id"]}`}>
					<td
						colSpan={colSpan}
						style={{
							padding: 1,
						}}
					>
						<Grid
							container
							spacing={2}
							justifyContent={"flex-start"}
							alignItems={"center"}
							paddingLeft={3}
							paddingRight={3}
						>
							<Grid xs={12}>
								<AssetTableDetail
									columns={expandColumns}
									data={column}
									token={token}
									permission={permission}
								/>
							</Grid>
							{permission && (
								<Grid>
									<AssetPictureUpload
										id={id["id"]}
										actualizar={updatePicture}
									/>
								</Grid>
							)}
							<Grid>
								<AssetPicture
									token={token}
									idAsset={id["id"]}
									refresh={actualizarFotos}
									permission={permission}
								/>
							</Grid>
						</Grid>
					</td>
				</tr>,
			]
			return array
		},
	}

	return (
		<React.Fragment>
			<MUIDataTable
				title={ASSET_TEXT.TITLE}
				data={data}
				columns={columns}
				options={options}
			/>
			<AssetFormulario
				open={openForm}
				setOpen={setOpenForm}
			/>
			<DialogConfirm
				open={open}
				setOpen={setOpen}
				message={ASSET_TEXT.DIALOG_LOW}
				accept={async () => {
					setOpen(false)
					let response = await apiPutAsset(
						"ASSET_COLABORADOR",
						token,
						{},
						{ id: open, baja: "" }
					)
					if (response["success"]) actualizarA()
				}}
			/>
		</React.Fragment>
	)
}
