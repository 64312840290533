import { useEffect, useMemo, useState } from "react"
import { api_rh_get } from "#api/API_RH"

/**
 * Hook personalizado para obtener datos de colaboradores de un API especifico.
 *
 * @param {string} ruta - Ruta de la API a consultar.
 * @param {Object} params - Parámetros para la consulta.
 * @param {Object} params.params - Parámetros adicionales para la consulta.
 * @param {boolean} params.actualizar - Indica si se debe actualizar el estado después de la consulta.
 * @returns {{ colaboradores: Array; loading: boolean; }}
 */
export const UseColaboradores = (ruta, { params, actualizar = true }) => {
	const [colaboradores, setColaboradores] = useState([])
	const [loading, setLoading] = useState(true)
	const params_request = useMemo(() => ({ ...params }), [params])
	useEffect(() => {
		const controller = new AbortController()
		setLoading(true)

		api_rh_get(`colaboradores/${ruta}`, {
			params: params_request,
			setData: setColaboradores,
			loading: () => setLoading(false),
			axiosProps: { signal: controller.signal },
		})

		return () => {
			controller.abort()
		}
	}, [params_request, actualizar, ruta])
	return { colaboradores, loading }
}
