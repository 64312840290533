import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { PRIMARYCOLOR } from "../../../../Constants/Styles"

//Material UI
import { ButtonIcon } from "../../../Common/Buttons"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"

//icons
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { CardBar } from "../EscolaresTables/CardComponent"
import { apiDeleteTrabajo, apiGetTrabajo } from "../../../../Services/Api_Colaborador"
import FormTrabajos from "../../forms/FormTrabajos"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { DialogConfirm } from "../../../Common/Dialog"
import { Button } from "@mui/material"

export default function TrabajoCard({ colaborador, edit = true }) {
	const token = useSelector((state) => state.user.token)
	const TEXT_COLOR_TABLET = "#eeeeee"
	const [trabajos, setTrabajos] = useState([])
	const [open, setOpen] = useState(false)
	const [openConfirm, setOpenConfirm] = useState({ open: false, id: null })
	const [actualizar, setActualizar] = useState(true)
	const actualizarNow = () => setActualizar(!actualizar)

	useEffect(() => {
		apiGetTrabajo(token, setTrabajos, { colaborador: colaborador.id })
	}, [actualizar, colaborador.id, token])

	const handleNew = () => {
		setOpen({ open: true })
	}
	function handleEdit(curso) {
		setOpen({ open: true, edit: curso })
	}
	function handleDelete(id) {
		apiDeleteTrabajo(token, {
			id: id,
		}).then((response) => {
			actualizarNow()
			setOpenConfirm(false)
		})
	}
	return (
		<React.Fragment>
			<Grid2
				container
				spacing={1}
			>
				<Grid2 xs={12}>
					<CardBar
						title="Trabajos Anteriores"
						edit={edit}
						buttonAdd={handleNew}
						color={TEXT_COLOR_TABLET}
					/>
				</Grid2>
				{trabajos.map((trabajo) => {
					return (
						<Grid2 xs={4}>
							<CardComponen
								key={trabajo.id}
								trabajo={trabajo}
								edit={edit}
								buttonEdit={() => handleEdit(trabajo)}
								buttonDeleted={() =>
									setOpenConfirm({
										open: true,
										id: trabajo.id,
										company: trabajo.compania,
									})
								}
							/>
						</Grid2>
					)
				})}
			</Grid2>
			<FormTrabajos
				idColaborador={colaborador.id}
				open={open.open}
				edit={open.edit}
				setOpen={setOpen}
				actualizar={actualizarNow}
			/>
			<DialogConfirm
				setOpen={setOpenConfirm}
				open={openConfirm.open}
				message={`¿Eliminar Trabajo ${openConfirm.company} ?`}
				accept={() => handleDelete(openConfirm.id)}
			/>
		</React.Fragment>
	)
}

export function CardComponen({ trabajo, edit, buttonEdit, buttonDeleted }) {
	const { compania, giro, fecha_inicio, fecha_fin, puesto } = trabajo
	const [more, setMore] = useState(false)
	let { funciones } = trabajo
	const fontSize = 15
	const lengthText = 50
	if (funciones.length > lengthText) {
		funciones = [
			<React.Fragment>
				{more ? funciones : funciones.substr(0, lengthText)}
				<Button
					variant="text"
					size="small"
					onClick={() => {
						setMore(!more)
					}}
				>
					{more ? "...menos" : "...mas"}
				</Button>
			</React.Fragment>,
		]
	} else funciones = ` ${funciones}`
	return (
		<Card sx={{ flex: 1, minWidth: 250, borderRadius: 3, height: "100%" }}>
			<CardActions
				backgroundColor="red"
				sx={{ backgroundColor: PRIMARYCOLOR }}
			>
				<div
					style={{
						width: "100%",
						height: "50px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography
						sx={{ fontSize: 17, color: "#ffffff" }}
						variant="h7"
					>
						{compania}
					</Typography>
					{edit && (
						<Stack
							direction="row"
							justifyContent="flex-end"
						>
							<ButtonIcon
								title="Editar"
								icon={<EditIcon />}
								onClick={buttonEdit}
								size="small"
								sx={{ color: "#ffffff" }}
							/>
							<ButtonIcon
								title="Eliminar"
								icon={<DeleteIcon />}
								onClick={buttonDeleted}
								size="small"
								sx={{ color: "#ffffff" }}
							/>
						</Stack>
					)}
				</div>
			</CardActions>

			<CardContent>
				<Typography
					sx={{ fontSize: fontSize }}
					color="text.secondary"
					gutterBottom
				>
					<span style={{ fontWeight: "bold" }}>Duración :</span>
					{` ${fecha_inicio} a ${fecha_fin}`}
				</Typography>
				<Typography
					sx={{ fontSize: fontSize }}
					color="text.secondary"
					gutterBottom
				>
					<span style={{ fontWeight: "bold" }}>Giro :</span>
					{` ${giro}`}
				</Typography>
				<Typography
					sx={{ fontSize: fontSize }}
					color="text.secondary"
					gutterBottom
				>
					<span style={{ fontWeight: "bold" }}>Puesto :</span>
					{puesto}
				</Typography>
				<Typography
					sx={{ fontSize: fontSize }}
					color="text.secondary"
					gutterBottom
				>
					<span style={{ fontWeight: "bold" }}>Funciones :</span>
					{funciones}
				</Typography>
			</CardContent>
		</Card>
	)
}
