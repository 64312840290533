import { Fragment } from 'react'; 
import { ListItemButton, ListItemIcon, Divider, ListItemText, Badge, Tooltip } from '@mui/material/';
import { PERFILES } from '../../Constants/Strings';
import { useSelector, useDispatch } from "react-redux";
import { logout } from '../../app/userSlice';
import { Link } from "react-router-dom";
import { googleLogout } from '@react-oauth/google';
import { PRIMARYCOLOR } from '../../Constants/Styles';
import PeopleIcon from '@mui/icons-material/People';
import BadgeIcon from '@mui/icons-material/Badge';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PostAddIcon from '@mui/icons-material/PostAdd';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import SavingsIcon from '@mui/icons-material/Savings';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import MoneyIcon from '@mui/icons-material/Money';
import { hasPermission } from './Funciones';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const Item = ({perfiles, openDrawer, item}) => {

    let perfilesDenied=perfiles.filter(perfil=>perfil.name!==PERFILES.ADMIN)
    if(hasPermission(perfilesDenied,item.denied)) return <></>

    var  Permissions = item.permissions.length === 0 ? true : false
    if (!Permissions)
        Permissions = hasPermission(perfiles,item.permissions)//item.permissions.map(permission => perfiles.filter(userPerm => userPerm.name === permission || userPerm.name === PERFILES.ADMIN).length > 0).some((permiso) => permiso === true);
    if(!Permissions) return <></>
    var textDivider = openDrawer ? item.divider : ''
    var dividerMenu = item.divider !== '' ? <Divider textAlign="left">{textDivider}</Divider> : <></> 
    return (
        <>
            {dividerMenu}
            <Link key={item.title} to={item.to} target={item.target} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Tooltip title={item.title} placement="right" >
                    <ListItemButton>
                        <ListItemIcon>
                            <Badge badgeContent={item.badge} color="error" >
                                {item.icon}
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItemButton>
                </Tooltip>
            </Link>
        </>
    )
}

var arrayMenu = [
    {
        "to": "/perfil",
        "title": "Mi Perfil",
        "icon": <BadgeIcon sx={{ color: PRIMARYCOLOR }} />,
        'permissions': [],
    },{
        "to": "/colaboradores",
        "title": "Colaboradores",
        "icon": <PeopleIcon sx={{ color: PRIMARYCOLOR }} />,
        'permissions': ['RH',PERFILES.SISTEMAS]
    },{
        "to": "/vacantes",
        "title": "Requerimientos",
        'permissions': ['RH', 'Gerente','Jefatura'],
        "icon": <PersonAddIcon sx={{ color: PRIMARYCOLOR }} /> ,
    },{
        "to": "/perfiles",
        "title": "Perfiles de Puesto",
        "icon": <PermContactCalendarIcon sx={{ color: PRIMARYCOLOR }} />,
        'permissions': ['RH', 'Gerente']
    },{
        "to": "https://talentrac.lubtrac.com.mx/talentracLubtrac/login",
        "title": "Talentrac",
        "icon": <BeachAccessIcon sx={{ color: PRIMARYCOLOR }} />,
        "target": "_blank",
        'denied':"ColaboradorGuatemala",

        
    },{
        "to": "/cursos",
        "title": "Mis Cursos",
        "icon": <LibraryBooksIcon sx={{ color: PRIMARYCOLOR }} />,
        'textDivider': 'Capacitaciones'
    },{
        "to": "/cursos-lista",
        "title": "Catálogo Cursos",
        "icon": <PostAddIcon    sx={{ color: PRIMARYCOLOR }} />,
        'permissions': ['RH','Gerente' ]
    },{
        "to": "/curos-aprobar",
        "title": "Curso por Aprobar",
        "icon": <AssignmentTurnedInIcon   sx={{ color: PRIMARYCOLOR }} />,
        'permissions': ['RH']
    },{
        "to": "/curso-colaboradores",
        "title": "Matriz de Cursos",
        "icon": <RecentActorsIcon sx={{ color: PRIMARYCOLOR }} />,
        'permissions': ['RH',PERFILES.GERENTE]
    }, {
        "to": "/prestamos",
        "title": "Prestamos",
        "icon": <MoneyIcon sx={{ color: PRIMARYCOLOR }} />,
        'permissions': ['RH',PERFILES["GERENTE"], "Pagos"],
        'denied':"ColaboradorGuatemala",
        'textDivider': 'Solicitudes'
    }, {
        "to": "/caja-ahorro",
        "title": "Caja Ahorro",
        "icon": <SavingsIcon sx={{ color: PRIMARYCOLOR }} />,
        'denied':"ColaboradorGuatemala",
        'permissions': ['RH'],
        
    },{
        "to": "/uniformes",
        "title": "Uniformes",
        "icon": <CheckroomIcon sx={{ color: PRIMARYCOLOR }} />,
        'permissions': ['RH','Compras'],
        'denied':"ColaboradorGuatemala",
    },
   
    {
        "to": "/Documentacion",
        "title": "Documentos",
        "icon": <DescriptionIcon sx={{ color: PRIMARYCOLOR }} />,
        'permissions': [],
        'textDivider': 'Información Lubtrac'
    },
]

 const MenuListItems =()=> {
        const notification= useSelector(state => state.notification.notification);
        const perfiles = useSelector(state => state.user.profiles);
        const openDrawer = useSelector(state => state.page.openDrawer);
        const dispatch = useDispatch();

        const closeSession = () => {
             dispatch(logout())
            window.location.reload(true);
            googleLogout();
        }
        return (
            <Fragment>
                {arrayMenu.map(menu =>{
                    menu.badge = notification[menu.title] || 0
                    menu.permissions = menu.permissions || []
                    menu.target = menu.target || ''
                    menu.divider = menu.textDivider || ''
                    return <Item key={menu.title} item={menu} badge={notification[menu.title]}  perfiles={perfiles} openDrawer={openDrawer}/>
                })}
                <Divider textAlign="left"></Divider> 
                <ListItemButton onClick={() => closeSession()}>
                    <ListItemIcon>
                        <LogoutIcon sx={{ color: PRIMARYCOLOR }} />
                    </ListItemIcon>
                    <ListItemText primary={"Cerrar sesión"} />
                </ListItemButton>
            </Fragment>
        );
}
export default MenuListItems;