import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './app/store'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { COLOR_MODE, COLOR_PRIMARY, COLOR_PRIMARY_DARK, COLOR_PRIMARY_LIGHT, COLOR_PRIMARY_TEXT,  } from '#constants/Styles';
const theme = createTheme({
  palette: {
        primary: {
          main: COLOR_PRIMARY,
          light: COLOR_PRIMARY_LIGHT,
          dark: COLOR_PRIMARY_DARK,
          contrastText: COLOR_PRIMARY_TEXT
        },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1110,
      lg: 1200,
      xl: 1536,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
