import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import FormDataContactos from "../../forms/FormDataContactos"
import {
	apiDeleteColaboradorContacto,
	apiGetColaboradorContacto,
} from "../../../../Services/Api_Colaborador"
import { PRIMARYCOLOR } from "../../../../Constants/Styles"
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material"

//Icons
import Stack from "@mui/material/Stack"
import { ButtonIcon } from "../../../Common/Buttons"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"

export default function TableContactEmergency({ colaborador, edit }) {
	const token = useSelector((state) => state.user.token)
	const [contactos, SetContactos] = useState([])
	const [formulario, setFormulario] = useState(false)
	const [formularioEdit, setFormularioEdit] = useState(false)
	const [actualizar, SetActualizar] = useState(true)
	const actualizarNow = () => SetActualizar(!actualizar)

	let TABLE_CONTACT = [
		{
			id: "nombre",
			label: "Nombre",
		},
		{
			id: "domicilio",
			label: "Domicilio",
		},
		{
			id: "parentezco",
			label: "Parentesco",
		},
		{
			id: "telefono",
			label: "Teléfonos",
		},
	]

	if (edit) {
		let button = {
			id: "buttons",
			label: "",
			styled: { align: "right" },
		}
		TABLE_CONTACT.push(button)
	}

	useEffect(() => {
		apiGetColaboradorContacto(token, SetContactos, {
			colaborador: colaborador.id,
		}).then((e) => console.log(e))
	}, [colaborador.id, token, actualizar])

	return (
		<React.Fragment>
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell
								colSpan={4}
								sx={{
									backgroundColor: PRIMARYCOLOR,
									color: "#eee",
									fontWeight: "bold",
									fontSize: 16,
								}}
							>
								{" "}
								Contactos de emergencia
							</TableCell>
							{edit && (
								<TableCell
									align="right"
									sx={{
										backgroundColor: PRIMARYCOLOR,
										color: "#eee",
										fontWeight: "bold",
										fontSize: 16,
									}}
								>
									<ButtonIcon
										title="Agregar contactos de emergencia"
										icon={<AddCircleOutlineIcon />}
										sx={{ color: "#ffffff" }}
										onClick={() => {
											setFormulario(true)
											setFormularioEdit(false)
										}}
									/>
								</TableCell>
							)}
						</TableRow>
						{!!contactos.length && (
							<TableRow>
								{TABLE_CONTACT.map((col, index) => {
									return (
										<TableCell
											sx={{
												backgroundColor: PRIMARYCOLOR,
												color: "#eee",
												fontWeight: "bold",
											}}
										>
											{col.label}
										</TableCell>
									)
								})}
							</TableRow>
						)}
					</TableHead>
					<TableBody>
						{contactos.map((contacto, index) => {
							return (
								<TableRow key={index}>
									{TABLE_CONTACT.map((col, i) => {
										if (col.id === "buttons")
											return (
												<TableCell
													key={contacto.id + `${i}`}
													{...col.styled}
												>
													<Stack
														alignContent={"center"}
														justifyContent={col.styled?.align}
														direction="row"
														spacing={2}
													>
														<ButtonIcon
															title="Editar contactos de emergencia"
															icon={<EditIcon />}
															onClick={() => {
																setFormulario(true)
																setFormularioEdit(contacto)
															}}
														/>
														<ButtonIcon
															title="Eliminar contactos de emergencia"
															icon={<DeleteIcon />}
															onClick={() => {
																apiDeleteColaboradorContacto(token, {
																	id: contacto.id,
																})
																actualizarNow()
															}}
														/>
													</Stack>
												</TableCell>
											)
										if (col.id === "telefono")
											return (
												<TableCell>
													{contacto.telefono}
													{contacto.telefono2 ? `/ ${contacto.telefono2}` : ""}
												</TableCell>
											)
										return <TableCell>{contacto[col.id]}</TableCell>
									})}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<FormDataContactos
				idColaborador={colaborador.id}
				open={formulario}
				setOpen={setFormulario}
				edit={formularioEdit}
				actualizar={actualizarNow}
			/>
		</React.Fragment>
	)
}
