import axios from "axios"
import { toast } from "react-toastify"
import "./types"

const URL_API = process.env.REACT_APP_SERVER_URL
const token = localStorage.getItem("token")

const gestor_rh = axios.create({
	baseURL: URL_API + "rh/",
	headers: {
		Authorization: "Token " + token,
	},
})

/**
 * Realiza una solicitud GET a recursos humanos.
 * @param {String} url
 * @param {Object} options - Objeto de configuración.
 * @param {Function} options.success función que recibe como parámetro la data de la respuesta
 * @param {Function} options.successAll función que recibe como parámetro la respuesta completa
 * @param {Function} options.fail función que recibe como parámetro el error
 * @param {Function} options.setData retorna la data de la respuesta
 * @param {Function} options.loading ejecuta la función cuando reciba los datos
 * @param {Function} options.params query params para axios
 * @param {Function} options.signal abortController para axios
 * @param {AxiosRequestConfig} options.axiosProps propiedades de axios
 * @returns {Promise<object>} - La respuesta de la API.
 */
export const api_rh_get = async (url, options = {}) => {
	//propiedades de callback
	const { success = () => {} } = options
	const { successAll = () => {} } = options
	const { fail = () => {} } = options

	const { setData = () => {} } = options
	const { loading = () => {} } = options
	//propiedades de axios
	const { axiosProps = {} } = options
	const { params = {} } = options
	const { signal = null } = options
	try {
		const response = await gestor_rh.get(url, { params, signal, ...axiosProps })
		success(response?.data?.data)
		successAll(response)
		setData(response?.data?.data)
		loading()
		return response
	} catch (error) {
		if (axios.isCancel(error)) {
			console.info("La solicitud fue cancelada")
		} else {
			console.error(`url: "/${url}"`, error)
			toast.error(`Error al obtener datos `)
			fail(error)
		}
		return null
	}
}
/**
 * Realiza una solicitud POST a recursos humanos.
 * @param {string} url
 * @param {object} datos
 * @param {Object} settings - Objeto de configuración.
 * @param {AxiosRequestConfig} settings.axios
 * @param {NotificationMessage} settings.message
 * @param {Function} settings.params query params para axios
 *
 * @returns {Promise<object>} - La respuesta de la API.
 */
export const api_rh_post = async (url, datos, settings = {}) => {
	const { message = {} } = settings
	const { axios = {} } = settings
	const { params = {} } = settings
	try {
		const request = gestor_rh.post(url, datos, { params, ...axios })
		notificationPromise(request, message)
		const response = await request
		return response
	} catch (error) {
		console.log(error)
		return null
	}
}

/**
 * Realiza una solicitud PUT a recursos humanos.
 * @param {string} url
 * @param {object} datos
 * @param {Object} settings - Objeto de configuración.
 * @param {AxiosRequestConfig} settings.axios
 * @param {NotificationMessage} settings.message
 * @returns {Promise<object>} - La respuesta de la API.
 * @param {Function} settings.params query params para axios
 *
 */
export const api_rh_put = async (url, datos, settings = {}) => {
	const { message = {} } = settings
	const { axios = {} } = settings
	const { params = {} } = settings

	try {
		const request = gestor_rh.put(url, datos, { params, ...axios })
		notificationPromise(request, message)
		const response = await request
		return response
	} catch (error) {
		console.log(error)
		return null
	}
}

/**
 * Notificaciones para el usuario cuando envía edita o elimina
 * @param {Promise} request
 * @param {NotificationMessage} message
 */
export function notificationPromise(request, message = {}) {
	const { pending = "Enviando..." } = message
	const { success = false } = message
	const { error = false } = message

	toast.promise(request, {
		pending,
		success: {
			render({ data }) {
				if (success) return success
				return `${data.data.message}`
			},
		},
		error: {
			render({ data }) {
				if (error) return error
				if (data?.response?.data?.message) return data.response.data.message
				return `${data.message}`
			},
		},
	})
}
